import { Box, IconButton } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Admin from '../Dashboard'
import AddService from './AddService'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function EditService() {
    const {id} = useParams()
    const [editServiceData, setEditServiceData] = useState({})

    const fetchServiceById = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getCommonServiceById/${id}`)
            console.log(response)
            if(response.status === 200){
                setEditServiceData(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if(id){
            fetchServiceById(id)
        }
    }, [id])
    

  return (
        <Box>
        <AddService editServiceData={editServiceData} isEdit={true}/>
        </Box>
  )
}

export default EditService