import { Box, Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { RobotoTypo } from '../../../utils/Typographies'

function ApplyService({ 
    services = [],
    selectedServices,
    setSelectedServices,
    serviceTotalPrice,
    setServiceTotalPrice
}) {
  const { t } = useTranslation();
    const language = useSelector((state) => state.language.language)


   const handleCheckboxChange = (dat) => {
    setSelectedServices((prevSelected) => {
      const existingItem = prevSelected.find((data) => data._id === dat._id);
  
      if (existingItem) {
        
        if (existingItem.mandatory) {
          return prevSelected;
        }
        return prevSelected.filter((data) => data._id !== dat._id);
      } else {
  
        return [...prevSelected, dat];
      }
    });
  };




  const calculateServiceAmount = (selectedServ) => {
    return selectedServ.reduce((total, service) => {
      // const mandatoryServicesTotal = school.services
      //   .filter((service) => service.mandatory) // Only mandatory services
      //   .reduce((sum, service) => sum + Number(service.amount_en), 0); // Sum service amounts
  
      return total + parseFloat(service?.amount);
    }, 0);
  };

  useEffect(() => {
      if(services.length > 0){
        const mandatoryServices = services.filter((serve) => serve.mandatory === true)
        setSelectedServices(mandatoryServices)
      }
  }, [services])

  useEffect(() => {
      if(selectedServices){
        const total = parseFloat(calculateServiceAmount(selectedServices).toFixed(2))
        setServiceTotalPrice(total)
      }
  }, [selectedServices])




  return (
    <>
    <Stack
        sx={{
          padding: "18px 30px",
          backgroundColor: "#e1e1e1",
          mb: "30px",
          overflow : 'hidden' ,
        }}
      >
        <Box sx={{display : 'flex', alignItems : 'center', gap : 2.5}}>
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          {t("Select_Services_you_want_optional")}
        </RobotoTypo>
        </Box>
      </Stack>

      <Box>
      <Table
  style={{
   
    width: "100%",
    color: "#177D84",
   
    marginBottom: 4,
  }}
>
  {/* Table Header */}
  <TableHead>
    <TableRow>
      <TableCell></TableCell> {/* Checkbox Column */}
      <TableCell sx={{fontSize : '1.05rem', fontWeigtht : 500}}><b>{t("service_name")}</b></TableCell>
      <TableCell sx={{fontSize : '1.05rem', fontWeigtht : 500}}><b>{t("fees")}</b></TableCell>
      {/* <TableCell sx={{fontSize : '1.05rem', fontWeigtht : 500}}>Consultation Fee</TableCell> */}
      {/* <TableCell sx={{fontSize : '1.05rem', fontWeigtht : 500}}>Actions</TableCell> */}
    </TableRow>
  </TableHead>

  {/* Table Body */}
  <TableBody>
    {services.map((row) => (
      <TableRow
        key={row._id}
        sx={{
          borderBottom: "1px solid #e5e5e5",
        }}
      >
        {/* Checkbox Column */}
        <TableCell>
          <Checkbox
            checked={selectedServices.some((data) => data._id === row._id)}
            disabled={row.isReadable}
            sx={{
              "&.Mui-checked": {
                color: selectedServices.find((data) => data._id === row._id)?.mandatory ? "#A4D0D3" : "#19c2d8",
              },
            }}
            onChange={() => handleCheckboxChange(row)}
          />
        </TableCell>

        {/* School Name */}
        <TableCell sx={{ color: "black", fontWeight: 500 }}>
          {`${row?.multi_language_names?.[language]?.name}`}
        </TableCell>

        {/* Application Fee */}
        <TableCell sx={{ color: "black" }}>
          S$ {row?.amount}
        </TableCell>

        {/* <TableCell sx={{ color: "black" }}>
          S$ {row?.consultation_fee_amount}
        </TableCell> */}

        {/* Actions Column */}
        {/* <TableCell>
          <LaunchIcon
            sx={{ cursor: "pointer" }}
            onClick={() => window.open(`/school/${row._id}`, "_blank")}
            title="View School"
          />
        </TableCell> */}
      </TableRow>
    ))}
  </TableBody>
</Table>

<Box sx={{mt : 2, display : 'flex', justifyContent : 'flex-end', alignItems : 'center', mr : 10}}>
    <Typography sx={{fontSize : '1.05rem', fontWeight : 600}}>{t("total_amount")} : S$ {serviceTotalPrice}</Typography>
</Box>
      </Box>
    </>
  )
}

export default ApplyService