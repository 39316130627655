import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Select,
  Dialog,
  DialogTitle
} from "@mui/material";
import { ErrTypo } from "../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as EnquireMessageIcon } from "../../assets/sideOverlap/enquireMessageIcon.svg";
import { ReactComponent as ScrollArrow } from "../../assets/sideOverlap/scrollArrow.svg";
import { ReactComponent as ExtendPlusIcon } from "../../assets/sideOverlap/extendPlusIcon.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/sideOverlap/What.svg";
import { ReactComponent as WhatsappIconHover } from "../../assets/sideOverlap/WhatHover.svg";
import { ReactComponent as WeChatIcon } from "../../assets/sideOverlap/WeChat.svg";
import { ReactComponent as WeChatIconHover } from "../../assets/sideOverlap/WeChatHover.svg";
import { ReactComponent as LineIcon } from "../../assets/sideOverlap/Line.svg";
import { ReactComponent as LineIconHover } from "../../assets/sideOverlap/LineHover.svg";
import { ReactComponent as MailIcon } from "../../assets/sideOverlap/mail.svg";
import { ReactComponent as MailIconHover } from "../../assets/sideOverlap/mailHover.svg";
import { ReactComponent as TiktokIcon } from "../../assets/sideOverlap/TiktokIcon.svg";
import { ReactComponent as TiktokIconHover } from "../../assets/sideOverlap/TiktokHoverIcon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/sideOverlap/instagram.svg";
import { ReactComponent as InstagramIconHover } from "../../assets/sideOverlap/InstagramHover.svg";
import { ReactComponent as KakaotalkHover } from "../../assets/sideOverlap/kakaotalkHover.svg";
import { ReactComponent as Kakaotalk } from "../../assets/sideOverlap/kakaotalk.svg";
import { ReactComponent as ZaloIcon } from "../../assets/sideOverlap/zalo.svg";
import { ReactComponent as ZaloIconHover } from "../../assets/sideOverlap/zaloHover.svg";
import EmailIcon from "@mui/icons-material/Email";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { emailRegex } from "../../utils/common";
import PhoneInput from "react-phone-input-2";
import ToastAlert from "../../utils/Alert";
import CloseIcon from "@mui/icons-material/Close";
import "./enquirestyle.css";
import MailDialogBox from "../DialogComponents/MailDialogBox";
import { useNavigate, useLocation } from 'react-router-dom'
import SchoolIcon from '@mui/icons-material/School';

const useStyles = makeStyles((theme) => ({
  customDotList: {
    "& .MuiFab-primary": {
      height: "40px !important",
      width: "40px !important",

      backgroundColor: "#003B5C",
      "&:hover": {
        backgroundColor: "#177D84",
      },
    },
    "& .MuiFab-circular": {
      // marginRight: "-11px",
    },
  },
}));

export default function EnquireButton() {
  const { t } = useTranslation();
  const classes = useStyles();

  const navigate = useNavigate()
  const location = useLocation()

  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = React.useState(false);

  const [hoveredAction, setHoveredAction] = React.useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const [openD, setOpenD] = React.useState(false);

  const handleClickOpen = () => {
    setOpenD(true);
  };

  const handleClose = () => {
    setOpenD(false);
  };

  const handleActionMouseEnter = (actionName) => {
    setHoveredAction(actionName);
  };

  const handleActionMouseLeave = () => {
    setHoveredAction(null);
  };

  const scrollIcon = () => {
    if (window.scrollY > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const institute = [
    {
    id : 1,
    name : t("child_care_school"),
    navigateTo : '/schoolapplication',
    schoolTypes : ['kindergarten']
  },
   {
    id : 2,
    name : t("primary_secondary_scholl"),
    navigateTo : '/schoolapplication',
    schoolTypes : ['primarySchool', 'secondarySchool']
  },
   {
    id : 3,
    name :  t("international_schools"),
    navigateTo : '/schoolapplication',
    schoolTypes : ['internationalSchool']
  },
   {
    id : 4,
    name : t("university"),
    navigateTo : '/universityapplication',
    schoolTypes : ['university']
  },
  ]

  const actions = [
    {
      icon: <ZaloIcon />,
      hoverIcon: <ZaloIconHover />,
      name: "Zalo",
      onClick: () => {
        window.open("https://zalo.me/pc", "_blank");
      },
    },
    {
      icon: <Kakaotalk />,
      hoverIcon: <KakaotalkHover />,
      name: "KakaotalkHover",
      onClick: () => {
        window.open(
          "http://qr.kakao.com/talk/vBE2kL.l8GU7UJgixIH3oXrbM9I-",
          "_blank"
        );
      },
    },
    {
      icon: <WeChatIcon />,
      hoverIcon: <WeChatIconHover />,
      name: "WeChat",
      onClick: () => {
        window.open("https://u.wechat.com/kJpWlgJkVFRhCwG5rpAgOKs", "_blank");
      },
    },
    {
      icon: <WhatsappIcon />,
      hoverIcon: <WhatsappIconHover />,
      name: "WhatsApp",
      onClick: () => {
        window.open("https://wa.me/qr/T4B5Z7QJVR4BE1", "_blank");
      },
    },
    {
      icon: <LineIcon />,
      hoverIcon: <LineIconHover />,
      name: "Line",
      onClick: () => {
        window.open("https://line.me/ti/p/5HJmiIyCHu", "_blank");
      },
    },
    {
      icon: <MailIcon />,
      hoverIcon: <MailIconHover />,
      name: "Email",
      onClick: () => {
        setOpen(true);
      },
    },
  ];

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 400) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 400) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollIcon);
    return () => {
      window.removeEventListener("scroll", scrollIcon);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          fontSize: "20px",
          color: "green",
          position: "fixed",
          right: "30px",
          bottom: "30px",
          zIndex: "100000",
        }}
      >
        <Box sx={{ marginRight: "-14px" }}>
          <Box sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
            <SpeedDial
              className={classes.customDotList}
              ariaLabel="SpeedDial basic example"
              sx={{ position: "absolute", bottom: 16, right: 85 }}
              icon={
                <Button
                  variant="contained"
                  sx={{
                    alignSelf: "flex-end",
                    minWidth: "200px",
                    borderRadius: "50px",
                    textAlign: "center",
                    cursor: "auto",
                    backgroundColor: "#003B5C",
                    "&:hover": {
                      backgroundColor: "#003B5C",
                    },
                  }}
                  startIcon={<EnquireMessageIcon />}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      letterSpacing: "0.08em",
                      textTransform: "none",
                    }}
                  >
                    {t("EnquiryButton")}
                  </Typography>
                </Button>
              }
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  key={action.name}
                  icon={
                    <IconButton>
                      {hoveredAction === action.name
                        ? action.hoverIcon
                        : action.icon}
                    </IconButton>
                  }
                  // tooltipTitle={action.name}
                  onMouseEnter={() => handleActionMouseEnter(action.name)}
                  onMouseLeave={handleActionMouseLeave}
                  onClick={action.onClick}
                />
              ))}
            </SpeedDial>
          </Box>
        </Box>
        <Box>
      
        {(location.pathname === '/schoolapplication' || location.pathname === '/universityapplication' || /^\/school\/[^/]+\/SchoolApplicationForm$/.test(location.pathname) || /^\/school\/[^/]+\/universityApplication$/.test(location.pathname)) ? (
null
        ) : (
<Button 
        startIcon={<SchoolIcon sx={{fontSize : ''}}/>}
        sx={{
          minWidth: "200px",
          borderRadius: "50px",
          textTransform : 'none',
          backgroundColor : '#177D84',
          color : 'white',
          fontSize : '18px',
          ':hover' : {
            backgroundColor : '#146b71'
          }
        }} variant='contained' onClick={handleClickOpen}>{t('ApplyNow')}</Button> 

        )}
        
        {/* } */}
         
    
        
        </Box>
        <IconButton
          style={{
            display: isVisible ? "block" : "none",
            transition: "opacity 0.3s ease-in-out",
            opacity: isVisible ? 1 : 0,
          }}
          onClick={scrollToTop}
          sx={{
            alignSelf: "end",
            transition: "0.3s",
          }}
        >
          <ScrollArrow />
        </IconButton>

        <MailDialogBox open={open} setOpen={setOpen} />
        <Dialog
        open={openD}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{zIndex : 100001}}
        
      >
        <DialogTitle id="alert-dialog-title">
          {"Select application to apply"}
        </DialogTitle>
        
        <Box sx={{width : '400px', height : '200px', flexWrap : 'wrap',display : 'flex', gap : 2, ml : 2.5, mb : 2}}>
          {institute.map((ins) => (
            <Button key={ins.id} sx={{textTransform : 'none', width : '95%'}} variant="outlined" onClick={() => {
            navigate(ins.navigateTo, {state : {school_types : ins.schoolTypes}})
            handleClose()
            }}>{ins.name}</Button>
          ))}
          
         
        </Box>
      </Dialog>
      </Box>
    </>
  );
}
