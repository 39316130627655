import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Admin from "../Dashboard";
import DeleteSchoolModal from "../School/DeleteSchoolModal";
import { useNavigate } from "react-router-dom";

const DiscountList = () => {
  const [discountData, setDiscountData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteSchool, setDeleteSchool] = useState("");
  const [deleteSchoolId, setDeleteSchoolId] = useState("");
  const navigate = useNavigate();
  const getDiscount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/discounts`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        setDiscountData(response.data);
      } else if (response.status == 403) {
        // navigate("/admin/login");
      }
    } catch (error) {
      console.log("er ", error);
    }
  };

  useEffect(() => {
    getDiscount();
  }, []);
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/discounts/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        getDiscount();
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        // navigate("/admin/login");
      }
    }
  };
  const handleDeleteApplication = (school, schoolId) => {
    setDeleteSchool(school);
    setDeleteSchoolId(schoolId);
    setOpen(true);
  };
  const handleEdit = (id) => {
    navigate(`/admin/editDiscount/${id}`);
  };
  return (
    <Admin>
      <DeleteSchoolModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        school={deleteSchool}
        handleDelete={handleDelete}
        deleteSchoolId={deleteSchoolId}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 1,
        }}
      >
        <Button
          component="span"
          onClick={() => navigate("/admin/addDiscount")}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sno</TableCell>
              <TableCell>No Of Application</TableCell>
              <TableCell>Discount Percentage</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discountData?.length > 0
              ? discountData.map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row?.discount_details?.form_count}</TableCell>
                    <TableCell>
                      {row?.discount_details?.discount_percentage}
                    </TableCell>
                    {/* <TableCell>{row.en.title}</TableCell>
                    <TableCell>{row.en.description}</TableCell> */}

                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          handleDeleteApplication(
                            `${row?.discount_details?.form_count} Application`,
                            row._id
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Admin>
  );
};

export default DiscountList;
