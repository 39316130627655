import React, { useEffect, useState } from "react";
import LanguageHeader from "./LanguageHeader";
import LanguageBody from "./LanguageBody";

import { useSelector } from "react-redux";
import axios from "axios";
import LanguageCourseRecommendation from "./LanguageCourseRecommendation";
import Header from "../../Header";
import Share from "../../Share";
import Footer from "../../Footer";
import SideOverlap from "../../../pages/sideOverlap";

export default function LanguageCourse() {
  const [schoolList, setSchoolList] = useState([]);
  const [data, setData] = useState([]);
  const language = useSelector((state) => state.language.language);

  const getPrimarySchool = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getLanguageCourse`
      );
      console.log("getPrimarySchools response ", response);
      if (response.status == 200) {
        setSchoolList(response?.data);
        console.log("response?.data one", typeof response?.data);
        // getData(
        //   Array.isArray(response?.data) ? response?.data : [response.data]
        // );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPrimarySchool();
  }, []);

  // const getData = (data) => {
  //   console.log("lang data ", data);
  //   //  const languageData = data?.map((item) => {
  //   let languageData;
  //   if (language == "en") {
  //     languageData =
  //       data?.length != 0 &&
  //       data.map((item) => {
  //         return {
  //           id: item._id,
  //           data: item.en,
  //           isShow: item?.isShow,
  //         };
  //       });
  //   } else if (language == "ch") {
  //     languageData =
  //       data?.length != 0 &&
  //       data.map((item) => {
  //         return {
  //           id: item._id,
  //           data_en: item.en,
  //           data: item.ch,
  //           isShow: item?.isShow,
  //         };
  //       });
  //   } else if (language == "sch") {
  //     languageData =
  //       data?.length != 0 &&
  //       data.map((item) => {
  //         return {
  //           id: item._id,
  //           data_en: item.en,
  //           data: item?.sch || item?.en,
  //           isShow: item?.isShow,
  //         };
  //       });
  //   }
  //   // })
  //   setData(languageData);
  // };

  // useEffect(() => {
  //   if (schoolList?.length != 0) {
  //     console.log("daad ", schoolList);
  //     getData(schoolList);
  //   }
  // }, [language]);

  return (
    <>
      <Header />
      <LanguageHeader />
      <LanguageBody />
      {schoolList?.length > 0 && (
        <LanguageCourseRecommendation data={schoolList} language={language}/>
      )}
      <Share />
      <Footer />
      <SideOverlap />
    </>
  );
}
