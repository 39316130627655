import { Box, Button, Card, CardContent, CardMedia } from "@mui/material";
import React, { useState } from "react";
import { RobotoTypo } from "../../utils/Typographies";
import Hover1 from "../../assets/Home/News/hover1.png";
import Hover2 from "../../assets/Home/News/hover2.png";
import Hover3 from "../../assets/Home/News/hover3.png";
import Hover4 from "../../assets/Home/News/hover4.png";
import Hover5 from "../../assets/Home/News/hover5.png";
import InformationEngineer from "../../assets/Home/News/informationEngineer.png";
import Biology from "../../assets/Home/News/biology.png";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";

// import {ReactComponent as Hover1} from '../../assets/Home/News/hovers1.svg';
// import {ReactComponent as Hover2} from '../../assets/Home/News/hovers2.svg';
// import {ReactComponent as Hover3} from '../../assets/Home/News/hovers3.svg';
// import {ReactComponent as Hover4} from '../../assets/Home/News/hovers4.svg';
// import {ReactComponent as Hover5} from '../../assets/Home/News/hovers5.svg';

const Field = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fieldData = [
    { title: "Psychology", image: Hover1, uri: "psychology", category_id: 1 },
    {
      title: "Mass Media Communication & Logistic",
      image: Hover2,
      uri: "Mass Media Communication & Logistic",
      category_id: 2,
    },
    {
      title: "Information Engineer",
      image: InformationEngineer,
      uri: "information-engineer",
      category_id: 3,
    },
    { title: "Art Design", image: Hover4, uri: "art-design", category_id: 4 },
    {
      title: "Business & Marketing",
      image: Hover5,
      uri: "business-marketing",
      category_id: 5,
    },
    {
      title: "Medicine / Biology",
      image: Biology,
      uri: "medicine-biology",
      category_id: 6,
    },
  ];

  const imageStyle = {
    // display: 'none ' ,
    width: "80%",
    height: "80%",
    objectFit: "cover",
  };

  const boxStyle = {
    width: { md: "20%", lg: "17%" },
    display: "flex",
    padding: "1rem",
    height: { xs: "100px", sm: "140px" },
    position: "relative",
    width: { xs: "100px", sm: "140px" },
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #177D84",
    backgroundColor: "#177D84",
    borderRadius: "100px",
    position: "relative",
    // overflow: 'hidden',
    cursor: "pointer",
    transition: "background-color 0.3s",
    "& img": {
      display: "none ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      "& #news_typo": {
        display: "none",
      },
      "& img": {
        display: "block",
      },
    },
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        padding: "4% 0",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: {
            xs: "none",
            md: "none",
            lg: "block",
            xl: "block",
            xxl: "block",
          },
          width: { xs: "15%", sm: "15%", md: "89%", lg: "90%", xl: "90%" },
          borderTopLeftRadius: "50px",
          borderLeft: "8px solid white",
          borderTop: "8px solid white",
          borderColor: "#F5DFBA",
          position: "absolute",
          bottom: "0",
          right: "0",
          zIndex: "99",
        }}
      ></Box>
      <Box
        sx={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          zIndex: 10000,
        }}
      >
        <RobotoTypo
          sx={{
            color: "#003B5C",
            fontWeight: "700",
            fontSize: { xs: 24, md: 36 },
            textAlign: { xs: "center", md: "center", lg: "left" },
          }}
        >
          {t("Home.Field.title")}
        </RobotoTypo>
        <Box
          sx={{
            display: "flex",
            padding: "3%",
            width: "90%",
            alignSelf: "center",
            alignSelf: "flex-end",
            justifyContent: "space-around",
            gap: { xs: "50px", md: "50px", lg: "10px" },
            flexWrap: "wrap",
          }}
        >
          {fieldData?.map((item, index) => (
            // <Box key={index} sx={{width:{md:'20%',lg:'17%'}, display: 'flex', padding: '1rem', height: '170px', width: '170px', justifyContent: 'center', alignItems: 'center', border: '1px solid #177D84', backgroundColor: '#177D84', borderRadius: '100px' }}>
            //     <RobotoTypo sx={{ color: '#FFFFFF', lineHeight:'30px', letterSpacing:'0.03em',fontWeight: '700', fontSize: { xs: 16, md: 22, lg:22, xl:24 }, textAlign: 'center' }}>{item}</RobotoTypo>
            // </Box>
            <Box
              key={index}
              sx={boxStyle}
              onClick={() => {
                navigate(`/school/${item?.uri}/${item?.category_id}`);
              }}
            >
              <RobotoTypo
                id="news_typo"
                sx={{
                  color: "#FFFFFF",
                  lineHeight: "30px",
                  letterSpacing: "0.03em",
                  fontWeight: "700",
                  fontSize: { xs: 16, md: 20, lg: 20, xl: 20 },
                  textAlign: "center",
                }}
              >
                {t("Home.Field.field_names", { returnObjects: true })[index]}
              </RobotoTypo>
              <img src={item.image} style={imageStyle} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default Field;
