import React, { useEffect } from "react";
import Header from "../components/Header";
import ImageCarousel from "../components/LandingPage/ImageCarousel";
import Footer from "../components/Footer";
import Brands from "../components/LandingPage/Brands";
import ApplicationProcess from "../components/LandingPage/ApplicationProcess";
import Services from "../components/Services";
import News from "../components/LandingPage/News";
import Field from "../components/LandingPage/Field";
import Recommendation from "../components/LandingPage/Recomendation";
import Share from "../components/Share";
import SideOverlap from "./sideOverlap";
import VingaInfo from "../components/LandingPage/VingaInfo";
import FlowChart from "../components/LandingPage/FlowChart";
import { DocumentTitleChange } from "../utils/metaHeadTitle";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  useEffect(() => {
    DocumentTitleChange("新加坡留學管家");
  });
  
  return (
    <>
    {/* <Helmet>
    <meta name="description" content="DR Pacific Holdings Pte. Ltd." />
    <meta name="keywords" content="DR Tech, DR, DR Pacific, DR Pacific Holdings Pte Ltd" />
      <meta name="author" content="DR Pacific Holdings Pte Ltd" />
      <meta property="og:title" content="DR Pacific Holdings Pte Ltd | DR Pacific Holdings" />
      <meta property="og:description" content="We tailor thoughtful and customized services for customers intending to come to Singapore. Our services cover immigration, educational journey, study abroad programs, training courses, tutoring, Chinese-Foreign language course, student hostel, school-based tours, visas services, and landing support." />
      <meta property="og:url" content="https://dredugroup.com" />
      <meta property="og:type" content="website" />
    </Helmet> */}
      <Header />
      <ImageCarousel />
      <Brands />
      <ApplicationProcess />
      <VingaInfo />
      <Services />
      <FlowChart />
      <News />
      <Field />
      <Recommendation />
      <Share />
      <Footer />
      {window.location.pathname != "/admin/login" &&
        window.location.pathname != "/admin/admindashboard" && <SideOverlap />}
      {/*  </Header> */}
    </>
  );
};

export default Dashboard;
