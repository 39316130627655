import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import {
  Check,
  Settings as SettingsIcon,
  GroupAdd as GroupAddIcon,
  Reviews as ReviewsIcon,
  Person as PersonIcon,
  School as SchoolIcon,
} from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { useSelector } from "react-redux";
import ToastAlert from "../../utils/Alert";
import { RobotoTypo } from "../../utils/Typographies";
import UniversityApplicationForm1 from "../../components/ApplicationForm/University/UniversityApplicationForm1";
import UniversityApplicationForm2 from "../../components/ApplicationForm/University/UniversityApplicationForm2";
import UniversityApplicationForm3 from "../../components/ApplicationForm/University/UniversityApplicationForm3";
import ReviewUniversityApplication from "../../components/ApplicationForm/University/ReviewUniversityApplication";
import { emailRegex } from "../../utils/common";
import { useTranslation } from "react-i18next";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#177D84",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#177D84",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#177D84",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#177D84",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <PersonIcon />,
    2: <GroupAddIcon />,
    3: <SchoolIcon />,
    4: <ReviewsIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};



export default function EditUniversityApplication() {
  const { id } = useParams();
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [schoolName, setSchoolName] = useState("");
  const [payLaterLoading, setPayLaterLoading] = useState(false);
  const [payNowLoading, setPayNowLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [data, setData] = useState([]);
  const language = useSelector((state) => state.language.language);
  const steps = [t("personal_details"), t("family_details"), t("educational_details"), t("review"),];


  const getSchool = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getSchool/` + id
      );

      if (response.status == 200) {
        console.log("rs ", response.data);
        setData(JSON.parse(response?.data?.services));
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSchool();
  }, []);

  const userData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";
  const getStudentApplications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/student-application/${id}`
      );
      if (response?.status == "200") {
        const data = response?.data;

        if (data) {
          setPersonalDetails({
            student_name: data?.StudentDetails?.personalDetails?.student_name,
            student_title: data?.StudentDetails?.personalDetails?.student_title,
            dob: data?.StudentDetails?.personalDetails?.dob,
            nationality: data?.StudentDetails?.personalDetails?.nationality,
            email: data?.StudentDetails?.personalDetails?.email,
            documents_held:
              data?.StudentDetails?.personalDetails?.documents_held,
            passport_number:
              data?.StudentDetails?.personalDetails?.passport_number,
            have_you_studied:
              data?.StudentDetails?.personalDetails?.have_you_studied,
            selected_school: schoolName,
            academic_level:
              data?.StudentDetails?.personalDetails?.academic_level,
            course_nature: data?.StudentDetails?.personalDetails?.course_nature,
            courses_enrolled:
              data?.StudentDetails?.personalDetails?.courses_enrolled,
            course_start_date:
              data?.StudentDetails?.personalDetails?.course_start_date,
            mobile_number: data?.StudentDetails?.personalDetails?.mobile_number,
            birth_certificateNo:
              data?.StudentDetails?.personalDetails?.birth_certificateNo,
            marraige_status:
              data?.StudentDetails?.personalDetails?.marraige_status,
            home_address: data?.StudentDetails?.personalDetails?.home_address,
            singapore_address:
              data?.StudentDetails?.personalDetails?.singapore_address,
            emergency_contact: {
              name: data?.StudentDetails?.personalDetails?.emergency_contact
                ?.name,
              relation:
                data?.StudentDetails?.personalDetails?.emergency_contact
                  ?.relation,
              addressAndPostal:
                data?.StudentDetails?.personalDetails?.emergency_contact
                  ?.addressAndPostal,
              phoneNumber:
                data?.StudentDetails?.personalDetails?.emergency_contact
                  ?.phoneNumber,
            },
          });
          setFatherDetails({
            name: data?.StudentDetails?.familyDetails?.fatherDetails?.name,
            nationality:
              data?.StudentDetails?.familyDetails?.fatherDetails?.nationality,
            dob: data?.StudentDetails?.familyDetails?.fatherDetails?.dob,
            occupation:
              data?.StudentDetails?.familyDetails?.fatherDetails?.occupation,
            documents_held:
              data?.StudentDetails?.familyDetails?.fatherDetails
                ?.documents_held,
          });
          setMotherDetils({
            name: data?.StudentDetails?.familyDetails?.motherDetails?.name,
            nationality:
              data?.StudentDetails?.familyDetails?.motherDetails?.nationality,
            dob: data?.StudentDetails?.familyDetails?.motherDetails?.dob,
            occupation:
              data?.StudentDetails?.familyDetails?.motherDetails?.occupation,
            documents_held:
              data?.StudentDetails?.familyDetails?.motherDetails
                ?.documents_held,
          });
          setSibilingDetails({
            name: data?.StudentDetails?.familyDetails?.sibilingDetails?.name,
            relationship:
              data?.StudentDetails?.familyDetails?.sibilingDetails
                ?.relationship,
            dob: data?.StudentDetails?.familyDetails?.sibilingDetails?.dob,
            nationality:
              data?.StudentDetails?.familyDetails?.sibilingDetails?.nationality,
            occupation:
              data?.StudentDetails?.familyDetails?.sibilingDetails?.occupation,
            documents_held:
              data?.StudentDetails?.familyDetails?.sibilingDetails
                ?.documents_held,
          });
          setSpouseDetails({
            name: data?.StudentDetails?.familyDetails?.spouseDetils?.name,
            marraige_date:
              data?.StudentDetails?.familyDetails?.spouseDetils?.marraige_date,
            marraige_Regno:
              data?.StudentDetails?.familyDetails?.spouseDetils?.marraige_Regno,
            divorce_date:
              data?.StudentDetails?.familyDetails?.spouseDetils?.divorce_date,
            divorce_number:
              data?.StudentDetails?.familyDetails?.spouseDetils?.divorce_number,
          });
          setFundingDetails({
            applicant_income:
              data?.StudentDetails?.familyDetails?.fundingDetails
                ?.applicant_income,
            applicant_deposits:
              data?.StudentDetails?.familyDetails?.fundingDetails
                ?.applicant_deposits,
            father_income:
              data?.StudentDetails?.familyDetails?.fundingDetails
                ?.father_income,
            father_deposits:
              data?.StudentDetails?.familyDetails?.fundingDetails
                ?.father_deposits,
            mother_income:
              data?.StudentDetails?.familyDetails?.fundingDetails
                ?.mother_income,
            mother_deposits:
              data?.StudentDetails?.familyDetails?.fundingDetails
                ?.mother_deposits,

            spouse_income:
              data?.StudentDetails?.familyDetails?.fundingDetails
                ?.spouse_income,
            spouse_deposits:
              data?.StudentDetails?.familyDetails?.fundingDetails
                ?.spouse_deposits,
          });
          setEducationalDetails(data?.StudentDetails?.educationalDetails);
          setAcademicDetails(data?.StudentDetails?.academicDetails);
          setWorkExperience(data?.StudentDetails?.workExperince);
          setOtherDertails({
            gpa_score: data?.StudentDetails?.otherDetails?.gpa_score,
            provide_grade: data?.StudentDetails?.otherDetails?.provide_grade,
            ielts_test: data?.StudentDetails?.otherDetails?.ielts_test,
            score: data?.StudentDetails?.otherDetails?.score,
            singapore_address:
              data?.StudentDetails?.otherDetails?.singapore_address,
            residential_address:
              data?.StudentDetails?.otherDetails?.residential_address,
            checkin_time: data?.StudentDetails?.otherDetails?.checkin_time,
            departure_time: data?.StudentDetails?.otherDetails?.departure_time,
            refused_country:
              data?.StudentDetails?.otherDetails?.refused_country,
            convicted_crime:
              data?.StudentDetails?.otherDetails?.convicted_crime,
            banned_entry: data?.StudentDetails?.otherDetails?.banned_entry,
            other_passport: data?.StudentDetails?.otherDetails?.other_passport,
            reason_other_passport:
              data?.StudentDetails?.otherDetails?.reason_other_passport,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching student applications:", error);
    } finally {
      setLoading(false);
    }
  };
  const [personalDetails, setPersonalDetails] = useState({
    student_name: "",
    dob: "",
    nationality: "",
    email: "",
    documents_held: "",
    passport_number: "",
    have_you_studied: "",
    selected_school: schoolName,
    academic_level: "",
    course_nature: "",
    courses_enrolled: "",
    course_start_date: "",
    mobile_number: "",
    birth_certificateNo: "",
    student_title: "",
    marraige_status: "",
    home_address: "",
    singapore_address: "",
    emergency_contact: {
      name: "",
      relation: "",
      addressAndPostal: "",
      phoneNumber: "",
      student_country_code: "",
    },
  });

  const [fatherDetails, setFatherDetails] = useState({
    name: "",
    nationality: "",
    dob: "",
    occupation: "",
    documents_held: "",
  });
  const [motherDetails, setMotherDetils] = useState({
    name: "",
    nationality: "",
    dob: "",
    occupation: "",
    documents_held: "",
  });
  const [spouseDetils, setSpouseDetails] = useState({
    name: "",
    marraige_date: "",
    marraige_Regno: "",
    divorce_date: "",
    divorce_number: "",
  });
  const [sibilingDetails, setSibilingDetails] = useState({
    name: "",
    relationship: "",
    dob: "",
    nationality: "",
    occupation: "",
    documents_held: "",
  });
  const [fundingDetails, setFundingDetails] = useState({
    applicant_income: "",
    applicant_deposits: "",
    father_income: "",
    father_deposits: "",
    mother_income: "",
    mother_deposits: "",
    spouse_income: "",
    spouse_deposits: "",
  });

  const [educationalDetails, setEducationalDetails] = useState([
    {
      school: "",
      nation: "",
      highest_education: "",
      admission_date: "",
      completion_date: "",
      finished_condition: "",
      course_nature: "",
      language: "",
    },
  ]);
  const [academicDetails, setAcademicDetails] = useState([
    {
      graduation_school: "",
      major: "",
      admission_time: "",
      graduation_time: "",
      academic_certificateNo: "",
      highest_education: "",
    },
  ]);
  const [applicationCreated, setApplicationCreated] = useState(false);
  const [workExperince, setWorkExperience] = useState([
    {
      company_name: "",
      nation: "",
      position: "",
      entry_time: "",
      resignation_time: "",
    },
  ]);
  const [paymentResponse, setPaymentResponse] = useState();
  const [otherDetails, setOtherDertails] = useState({
    gpa_score: "",
    provide_grade: "",
    ielts_test: "",
    score: "",
    singapore_address: "",
    residential_address: "",
    checkin_time: "",
    departure_time: "",
    refused_country: "",
    convicted_crime: "",
    banned_entry: "",
    other_passport: "",
    reason_other_passport: "",
  });
  const [toastStatus, setToastStatus] = useState({
    color: "",
    status: false,
    message: "",
    icon: "",
  });

  const [isFormValid, setIsFormValid] = useState({
    formOne: false,
    formTwo: false,
    formThree: false,
    formFour: false,
  });
  const [form1Errors, setForm1Errors] = useState({
    student_name: "",
    dob: "",
    nationality: "",
    email: "",
    selected_school: "",
    have_you_studied: "",
    academic_level: "",
    course_nature: "",
    mobile_number: "",
    birth_certificateNo: "",
    student_title: "",
  });
  const [form2Errors, setForm2Errors] = useState({
    fatherDetails: { name: "", nationality: "", occupation: "" },
    motherDetails: { name: "", nationality: "", occupation: "" },
    fundingDetails: { father_income: "", mother_income: "" },
  });
  const ApplicationFee = useSelector((state) => state.language.applicationFee);
  const [form3Errors1, setForm3Errors1] = useState([]);
  const [form3Errors2, setForm3Errors2] = useState({
    gpa_score: "",
    provide_grade: "",
    ielts_test: "",
    score: "",
  });
  const [isAppliedSuccess, setIsAppliedSuccess] = useState(false);
  const loggedinStudentData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : null;
  console.log("personalDetails ", personalDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const validationForm1 = () => {
    let isErrors = [];
    if (personalDetails.student_name == "") {
      setForm1Errors((prev) => ({
        ...prev,
        student_name: "Please Enter Name",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, student_name: "" }));
      isErrors.push(false);
    }
    if (personalDetails.dob == "") {
      setForm1Errors((prev) => ({
        ...prev,
        dob: "Please Choose Date of Birth",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, dob: "" }));
      isErrors.push(false);
    }
    if (personalDetails.nationality == "") {
      setForm1Errors((prev) => ({
        ...prev,
        nationality: "Please Enter Nationality",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, nationality: "" }));
      isErrors.push(false);
    }
    if (personalDetails.email == "") {
      setForm1Errors((prev) => ({ ...prev, email: "Please Enter Email" }));
      isErrors.push(true);
    } else if (emailRegex.test(personalDetails.email) == false) {
      setForm1Errors((prev) => ({
        ...prev,
        email: "Please Enter Valid Email",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, email: "" }));
      isErrors.push(false);
    }
    if (personalDetails.passport_number == "") {
      setForm1Errors((prev) => ({
        ...prev,
        passport_number: "Please Enter Passport Number",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, passport_number: "" }));
      isErrors.push(false);
    }

    if (personalDetails.have_you_studied == "") {
      setForm1Errors((prev) => ({
        ...prev,
        have_you_studied: "Please fill this field",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, have_you_studied: "" }));
      isErrors.push(false);
    }
    if (personalDetails.academic_level == "") {
      setForm1Errors((prev) => ({
        ...prev,
        academic_level: "Please fill this field",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, academic_level: "" }));
      isErrors.push(false);
    }
    if (personalDetails.course_nature == "") {
      setForm1Errors((prev) => ({
        ...prev,
        course_nature: "Please fill this field",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, course_nature: "" }));
      isErrors.push(false);
    }
    if (
      personalDetails.mobile_number == "" ||
      personalDetails.mobile_number == personalDetails.student_country_code
    ) {
      setForm1Errors((prev) => ({
        ...prev,
        mobile_number: "Please Enter Mobile Number",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, mobile_number: "" }));
      isErrors.push(false);
    }
    if (personalDetails.birth_certificateNo == "") {
      setForm1Errors((prev) => ({
        ...prev,
        birth_certificateNo: "Please fill this field",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, birth_certificateNo: "" }));
      isErrors.push(false);
    }
    if (personalDetails.student_title == "") {
      setForm1Errors((prev) => ({
        ...prev,
        student_title: "Please fill this field",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, student_title: "" }));
      isErrors.push(false);
    }
    if (personalDetails.home_address == "") {
      setForm1Errors((prev) => ({
        ...prev,
        home_address: "Please fill this field",
      }));
      isErrors.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, home_address: "" }));
      isErrors.push(false);
    }
    const isError = isErrors.includes(true);
    console.log("true ", isError);
    return isError;
  };

  const validationForm2 = () => {
    let isErrors = [];
    if (fatherDetails.name == "") {
      setForm2Errors((prev) => ({
        ...prev,
        fatherDetails: { ...prev.fatherDetails, name: "Please Enter Name" },
      }));
      isErrors.push(true);
    } else {
      setForm2Errors((prev) => ({
        ...prev,
        fatherDetails: { ...prev.fatherDetails, name: "" },
      }));
      isErrors.push(false);
    }
    if (fatherDetails.nationality == "") {
      setForm2Errors((prev) => ({
        ...prev,
        fatherDetails: {
          ...prev.fatherDetails,
          nationality: "Please Enter Nationality",
        },
      }));
      isErrors.push(true);
    } else {
      setForm2Errors((prev) => ({
        ...prev,
        fatherDetails: { ...prev.fatherDetails, nationality: "" },
      }));
      isErrors.push(false);
    }
    if (fatherDetails.occupation == "") {
      setForm2Errors((prev) => ({
        ...prev,
        fatherDetails: {
          ...prev.fatherDetails,
          occupation: "Please Enter Occupation",
        },
      }));
      isErrors.push(true);
    } else {
      setForm2Errors((prev) => ({
        ...prev,
        fatherDetails: { ...prev.fatherDetails, occupation: "" },
      }));
      isErrors.push(false);
    }

    if (motherDetails.name == "") {
      setForm2Errors((prev) => ({
        ...prev,
        motherDetails: { ...prev.motherDetails, name: "Please Enter Name" },
      }));
      isErrors.push(true);
    } else {
      setForm2Errors((prev) => ({
        ...prev,
        motherDetails: { ...prev.motherDetails, name: "" },
      }));
      isErrors.push(false);
    }
    if (motherDetails.nationality == "") {
      setForm2Errors((prev) => ({
        ...prev,
        motherDetails: {
          ...prev.motherDetails,
          nationality: "Please Enter Nationality",
        },
      }));
      isErrors.push(true);
    } else {
      setForm2Errors((prev) => ({
        ...prev,
        motherDetails: { ...prev.motherDetails, nationality: "" },
      }));
      isErrors.push(false);
    }
    if (motherDetails.occupation == "") {
      setForm2Errors((prev) => ({
        ...prev,
        motherDetails: {
          ...prev.motherDetails,
          occupation: "Please Enter Occupation",
        },
      }));
      isErrors.push(true);
    } else {
      setForm2Errors((prev) => ({
        ...prev,
        motherDetails: { ...prev.motherDetails, occupation: "" },
      }));
      isErrors.push(false);
    }

    if (fundingDetails.father_income == "") {
      setForm2Errors((prev) => ({
        ...prev,
        fundingDetails: {
          ...prev.fundingDetails,
          father_income: "Please Enter Father Income",
        },
      }));
      isErrors.push(true);
    } else {
      setForm2Errors((prev) => ({ ...prev, father_income: "" }));
      isErrors.push(false);
    }
    if (fundingDetails.mother_income == "") {
      setForm2Errors((prev) => ({
        ...prev,
        fundingDetails: {
          ...prev.fundingDetails,
          mother_income: "Please Enter Mother Income",
        },
      }));
      isErrors.push(true);
    } else {
      setForm2Errors((prev) => ({
        ...prev,
        fundingDetails: { ...prev.fundingDetails, mother_income: "" },
      }));
      isErrors.push(false);
    }

    const isError = isErrors.includes(true);
    console.log("true ", isError);
    return isError;
  };

  const validationForm3 = () => {
    let errors = [];
    let error2 = [];
    educationalDetails.forEach((detail, index) => {
      let error = {};
      if (!detail.school) error.school = "School is required";
      if (!detail.nation) error.nation = "Nation is required";
      if (!detail.highest_education)
        error.highest_education = "Highest education is required";
      if (!detail.admission_date)
        error.admission_date = "Admission date is required";
      if (!detail.completion_date)
        error.completion_date = "Completion date is required";
      if (!detail.finished_condition)
        error.finished_condition = "Finished condition is required";
      if (!detail.course_nature)
        error.course_nature = "Course nature is required";
      if (!detail.language) error.language = "Language is required";
      errors[index] = error;
    });
    setForm3Errors1(errors);
    if (otherDetails.gpa_score == "") {
      setForm3Errors2((prev) => ({ ...prev, gpa_score: "Please Choose One" }));
      error2.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, gpa_score: "" }));
      error2.push(false);
    }
    if (otherDetails.gpa_score == "Yes" && otherDetails.provide_grade == "") {
      setForm3Errors2((prev) => ({
        ...prev,
        provide_grade: "Please Choose One",
      }));
      error2.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, provide_grade: "" }));
      error2.push(false);
    }
    if (otherDetails.ielts_test == "Yes" && otherDetails.score == "") {
      setForm3Errors2((prev) => ({ ...prev, score: "Please Enter Score" }));
      error2.push(true);
    } else {
      setForm1Errors((prev) => ({ ...prev, score: "" }));
      error2.push(false);
    }

    const err1 = errors.every((err) => Object.keys(err).length === 0);
    const err2 = error2.includes(true);
    console.log("error2 ", err1, err2);
    return err1 && !err2;
  };

  const SubmitData = async () => {
    setPayNowLoading(true);
    const StudentDetails = {
      user_id: loggedinStudentData?._id,
      institute_id: id,
      type_id: "university",

      personalDetails: {
        student_name: personalDetails?.student_name,
        dob: personalDetails?.dob,
        student_title: personalDetails?.student_title,
        nationality: personalDetails?.nationality,
        email: personalDetails?.email,
        documents_held: personalDetails?.documents_held,
        passport_number: personalDetails?.passport_number,
        have_you_studied: personalDetails?.have_you_studied,
        selected_school: schoolName,
        academic_level: personalDetails?.academic_level,
        course_nature: personalDetails?.course_nature,
        courses_enrolled: personalDetails?.courses_enrolled,
        course_start_date: personalDetails?.course_start_date,

        mobile_number: personalDetails?.mobile_number,
        birth_certificateNo: personalDetails?.birth_certificateNo,
        marraige_status: personalDetails?.marraige_status,
        home_address: personalDetails?.home_address,
        singapore_address: personalDetails?.singapore_address,
        emergency_contact: {
          name: personalDetails?.emergency_contact?.name,
          relation: personalDetails?.emergency_contact?.relation,
          addressAndPostal:
            personalDetails?.emergency_contact?.addressAndPostal,
          phoneNumber: personalDetails?.emergency_contact?.phoneNumber,
        },
      },
      familyDetails: {
        fatherDetails: {
          name: fatherDetails?.name,
          nationality: fatherDetails?.nationality,
          dob: fatherDetails?.dob,
          occupation: fatherDetails?.occupation,
          documents_held: fatherDetails?.documents_held,
        },
        motherDetails: {
          name: motherDetails?.name,
          nationality: motherDetails?.nationality,
          dob: motherDetails?.dob,
          occupation: motherDetails?.occupation,
          documents_held: motherDetails?.documents_held,
        },
        spouseDetils: {
          name: spouseDetils?.name,
          marraige_date: spouseDetils?.marraige_date,
          marraige_Regno: spouseDetils?.marraige_Regno,
          divorce_date: spouseDetils?.divorce_date,
          divorce_number: spouseDetils?.divorce_number,
        },
        sibilingDetails: {
          name: sibilingDetails?.name,
          natirelationshiponality: sibilingDetails?.relationship,
          dob: sibilingDetails?.dob,
          nationality: sibilingDetails?.nationality,
          occupation: sibilingDetails?.occupation,
          documents_held: sibilingDetails?.documents_held,
        },
        fundingDetails: {
          applicant_income: fundingDetails?.applicant_income,
          applicant_deposits: fundingDetails?.applicant_deposits,
          father_income: fundingDetails?.father_income,
          father_deposits: fundingDetails?.father_deposits,
          mother_income: fundingDetails?.mother_income,
          mother_deposits: fundingDetails?.mother_deposits,
          spouse_deposits: fundingDetails?.spouse_deposits,
          spouse_income: fundingDetails?.spouse_income,
        },
      },
      educationalDetails: educationalDetails,
      academicDetails: academicDetails,
      workExperince: workExperince,
      otherDetails: {
        gpa_score: otherDetails.gpa_score,
        provide_grade: otherDetails.provide_grade,
        ielts_test: otherDetails.ielts_test,
        score: otherDetails.score,
        singapore_address: otherDetails.singapore_address,
        residential_address: otherDetails.residential_address,
        checkin_time: otherDetails.checkin_time,
        departure_time: otherDetails.departure_time,
        refused_country: otherDetails.refused_country,
        convicted_crime: otherDetails.convicted_crime,
        banned_entry: otherDetails.banned_entry,
        other_passport: otherDetails.other_passport,
        reason_other_passport: otherDetails.reason_other_passport,
      },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/update-application/${id}`,
        StudentDetails
      );
      console.log("university", response);
      // setToastStatus({
      //   color: "green",
      //   status: true,
      //   message: response.data,
      //   icon: "tick",
      // });
      if (response.status === 200) {
        navigate("/student/applications");
      }

      setPaymentResponse(response.data);

      setIsAppliedSuccess(true);
      setPayNowLoading(false);
      // navigate("/");
      // setTimeout(() => {
      //   setToastStatus({ color: "", status: false });
      // }, 3000);
    } catch (error) {
      console.log(error);
      setIsAppliedSuccess(false);
      setPayNowLoading(false);
      // setToastStatus({
      //   color: "red",
      //   status: true,
      //   message: "something wrong",
      //   icon: "close",
      // });
      // setTimeout(() => {
      //   setToastStatus({ color: "", status: false });
      // }, 3000);
    } finally {
      setPayNowLoading(false);
    }
  };

  const CreateApplication = async () => {
    const StudentDetails = {
      user_id: loggedinStudentData?._id,
      institute_id: id,
      type_id: "university",
      // application_fee: ApplicationFee,
      personalDetails: {
        student_name: personalDetails?.student_name,
        dob: personalDetails?.dob,
        student_title: personalDetails?.student_title,
        nationality: personalDetails?.nationality,
        email: personalDetails?.email,
        documents_held: personalDetails?.documents_held,
        passport_number: personalDetails?.passport_number,
        have_you_studied: personalDetails?.have_you_studied,
        selected_school: schoolName,
        academic_level: personalDetails?.academic_level,
        course_nature: personalDetails?.course_nature,
        courses_enrolled: personalDetails?.courses_enrolled,
        course_start_date: personalDetails?.course_start_date,

        mobile_number: personalDetails?.mobile_number,
        birth_certificateNo: personalDetails?.birth_certificateNo,
        marraige_status: personalDetails?.marraige_status,
        home_address: personalDetails?.home_address,
        singapore_address: personalDetails?.singapore_address,
        emergency_contact: {
          name: personalDetails?.emergency_contact?.name,
          relation: personalDetails?.emergency_contact?.relation,
          addressAndPostal:
            personalDetails?.emergency_contact?.addressAndPostal,
          phoneNumber: personalDetails?.emergency_contact?.phoneNumber,
        },
      },
      familyDetails: {
        fatherDetails: {
          name: fatherDetails?.name,
          nationality: fatherDetails?.nationality,
          dob: fatherDetails?.dob,
          occupation: fatherDetails?.occupation,
          documents_held: fatherDetails?.documents_held,
        },
        motherDetails: {
          name: motherDetails?.name,
          nationality: motherDetails?.nationality,
          dob: motherDetails?.dob,
          occupation: motherDetails?.occupation,
          documents_held: motherDetails?.documents_held,
        },
        spouseDetils: {
          name: spouseDetils?.name,
          marraige_date: spouseDetils?.marraige_date,
          marraige_Regno: spouseDetils?.marraige_Regno,
          divorce_date: spouseDetils?.divorce_date,
          divorce_number: spouseDetils?.divorce_number,
        },
        sibilingDetails: {
          name: sibilingDetails?.name,
          natirelationshiponality: sibilingDetails?.relationship,
          dob: sibilingDetails?.dob,
          nationality: sibilingDetails?.nationality,
          occupation: sibilingDetails?.occupation,
          documents_held: sibilingDetails?.documents_held,
        },
        fundingDetails: {
          applicant_income: fundingDetails?.applicant_income,
          applicant_deposits: fundingDetails?.applicant_deposits,
          father_income: fundingDetails?.father_income,
          father_deposits: fundingDetails?.father_deposits,
          mother_income: fundingDetails?.mother_income,
          mother_deposits: fundingDetails?.mother_deposits,
          spouse_deposits: fundingDetails?.spouse_deposits,
          spouse_income: fundingDetails?.spouse_income,
        },
      },
      educationalDetails: educationalDetails,
      academicDetails: academicDetails,
      workExperince: workExperince,
      otherDetails: {
        gpa_score: otherDetails.gpa_score,
        provide_grade: otherDetails.provide_grade,
        ielts_test: otherDetails.ielts_test,
        score: otherDetails.score,
        singapore_address: otherDetails.singapore_address,
        residential_address: otherDetails.residential_address,
        checkin_time: otherDetails.checkin_time,
        departure_time: otherDetails.departure_time,
        refused_country: otherDetails.refused_country,
        convicted_crime: otherDetails.convicted_crime,
        banned_entry: otherDetails.banned_entry,
        other_passport: otherDetails.other_passport,
        reason_other_passport: otherDetails.reason_other_passport,
      },
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/update-application/${id}`,
        StudentDetails
      );
      if (response.status === 201) {
      }
    } catch (error) {
      console.log(error);
      setApplicationCreated(false);
    }
  };

  const PayLater = async (paymentData, total) => {
    setPayLaterLoading(true);
    const StudentDetails = {
      user_id: loggedinStudentData?._id,
      institute_id: id,
      type_id: "university",

      personalDetails: {
        student_name: personalDetails?.student_name,
        dob: personalDetails?.dob,
        student_title: personalDetails?.student_title,
        nationality: personalDetails?.nationality,
        email: personalDetails?.email,
        documents_held: personalDetails?.documents_held,
        passport_number: personalDetails?.passport_number,
        have_you_studied: personalDetails?.have_you_studied,
        selected_school: schoolName,
        academic_level: personalDetails?.academic_level,
        course_nature: personalDetails?.course_nature,
        courses_enrolled: personalDetails?.courses_enrolled,
        course_start_date: personalDetails?.course_start_date,

        mobile_number: personalDetails?.mobile_number,
        birth_certificateNo: personalDetails?.birth_certificateNo,
        marraige_status: personalDetails?.marraige_status,
        home_address: personalDetails?.home_address,
        singapore_address: personalDetails?.singapore_address,
        emergency_contact: {
          name: personalDetails?.emergency_contact?.name,
          relation: personalDetails?.emergency_contact?.relation,
          addressAndPostal:
            personalDetails?.emergency_contact?.addressAndPostal,
          phoneNumber: personalDetails?.emergency_contact?.phoneNumber,
        },
      },
      familyDetails: {
        fatherDetails: {
          name: fatherDetails?.name,
          nationality: fatherDetails?.nationality,
          dob: fatherDetails?.dob,
          occupation: fatherDetails?.occupation,
          documents_held: fatherDetails?.documents_held,
        },
        motherDetails: {
          name: motherDetails?.name,
          nationality: motherDetails?.nationality,
          dob: motherDetails?.dob,
          occupation: motherDetails?.occupation,
          documents_held: motherDetails?.documents_held,
        },
        spouseDetils: {
          name: spouseDetils?.name,
          marraige_date: spouseDetils?.marraige_date,
          marraige_Regno: spouseDetils?.marraige_Regno,
          divorce_date: spouseDetils?.divorce_date,
          divorce_number: spouseDetils?.divorce_number,
        },
        sibilingDetails: {
          name: sibilingDetails?.name,
          natirelationshiponality: sibilingDetails?.relationship,
          dob: sibilingDetails?.dob,
          nationality: sibilingDetails?.nationality,
          occupation: sibilingDetails?.occupation,
          documents_held: sibilingDetails?.documents_held,
        },
        fundingDetails: {
          applicant_income: fundingDetails?.applicant_income,
          applicant_deposits: fundingDetails?.applicant_deposits,
          father_income: fundingDetails?.father_income,
          father_deposits: fundingDetails?.father_deposits,
          mother_income: fundingDetails?.mother_income,
          mother_deposits: fundingDetails?.mother_deposits,
          spouse_deposits: fundingDetails?.spouse_deposits,
          spouse_income: fundingDetails?.spouse_income,
        },
      },
      educationalDetails: educationalDetails,
      academicDetails: academicDetails,
      workExperince: workExperince,
      otherDetails: {
        gpa_score: otherDetails.gpa_score,
        provide_grade: otherDetails.provide_grade,
        ielts_test: otherDetails.ielts_test,
        score: otherDetails.score,
        singapore_address: otherDetails.singapore_address,
        residential_address: otherDetails.residential_address,
        checkin_time: otherDetails.checkin_time,
        departure_time: otherDetails.departure_time,
        refused_country: otherDetails.refused_country,
        convicted_crime: otherDetails.convicted_crime,
        banned_entry: otherDetails.banned_entry,
        other_passport: otherDetails.other_passport,
        reason_other_passport: otherDetails.reason_other_passport,
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-application/${id}`,
        StudentDetails
      );
      console.log("university", response);
      // setToastStatus({
      //   color: "green",
      //   status: true,
      //   message: response.data,
      //   icon: "tick",
      // });

      setIsAppliedSuccess(true);
      navigate("/");
      setPayLaterLoading(false);
      window.location.reload();
      // setTimeout(() => {
      //   setToastStatus({ color: "", status: false });
      // }, 3000);
    } catch (error) {
      console.log(error);
      setIsAppliedSuccess(false);
      setPayLaterLoading(false);
      setToastStatus({
        color: "red",
        status: true,
        message: "something wrong",
        icon: "close",
      });
      // setTimeout(() => {
      //   setToastStatus({ color: "", status: false });
      // }, 3000);
    } finally {
      setPayLaterLoading(false);
    }
  };

  const getSchooName = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getSchoolName/${id}`
      );
      if (response.status == 200) {
        setSchoolName(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getSchooName();
      getStudentApplications();
    };

    fetchData();
  }, []);

  const handleStepClick = (step) => {
    setActiveStep(step);
  };
  console.log("sibiling", sibilingDetails);

  const isStepDisabled = (index) => {
    if (index == 1 && !isFormValid.formOne) {
      return true;
    } else if (index == 2 && !isFormValid.formTwo) {
      return true;
    } else if (index == 3 && !isFormValid.formThree) {
      return true;
    }
    return false;
  };

  const handleStepClickInternal = (index) => {
    if (isStepDisabled(index)) {
      return;
    }
    handleStepClick(index);
  };

  // const getSchool = async() => {
  //   try {
  //       const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getSchool/`+schoolId);

  //         if(response.status == 200) {
  //           console.log('rs ',response.data)
  //           setBannerData(response.data)
  //           getData(response.data)
  //         }
  //   } catch(error) {

  //   }
  // }

  // useEffect(() => {
  //   getSchool()
  // },[])

  // const getData = (data) => {
  //   console.log('lang data ',data)
  //     //  const languageData = data?.map((item) => {
  //       let languageData
  //       if(language == 'en') {
  //          languageData = data.en
  //       } else {
  //          languageData = data.ch
  //       }
  //     // })
  //     setData(languageData)
  // }

  // useEffect(() => {

  //   if(bannerData != [] && bannerData?.length !=0) {
  //     console.log('bannerData ',bannerData)
  //     getData(bannerData)
  //   }
  // },[language])

  console.log("personalDetails ", personalDetails);
  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh" // Full viewport height
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <ToastAlert
        message={toastStatus?.message}
        status={toastStatus?.status}
        color={toastStatus?.color}
        icon={toastStatus?.icon}
        closeFunc={() => setToastStatus({ color: "", status: false })}
      />
      <RobotoTypo
        sx={{
          my: 3,
          textAlign: "center",
          fontWeight: "500",
          color: "#003B5C",
          fontSize: "24px",
        }}
      >
        {t("applicant_information")}
      </RobotoTypo>

      {activeStep === 0 && (
        <UniversityApplicationForm1
          setIsFormValid={setIsFormValid}
          form1Errors={form1Errors}
          setForm1Errors={setForm1Errors}
          validationForm1={validationForm1}
          personalDetails={personalDetails}
          setPersonalDetails={setPersonalDetails}
          setFormPage={setActiveStep}
          schoolName={schoolName}
          isStudentEditable={true}
        />
      )}
      {activeStep === 1 && (
        <UniversityApplicationForm2
          setIsFormValid={setIsFormValid}
          form2Errors={form2Errors}
          setForm2Errors={setForm2Errors}
          validationForm2={validationForm2}
          fatherDetails={fatherDetails}
          setFatherDetails={setFatherDetails}
          motherDetails={motherDetails}
          setMotherDetils={setMotherDetils}
          spouseDetils={spouseDetils}
          setSpouseDetails={setSpouseDetails}
          sibilingDetails={sibilingDetails}
          setSibilingDetails={setSibilingDetails}
          fundingDetails={fundingDetails}
          setFundingDetails={setFundingDetails}
          setFormPage={setActiveStep}
          isStudentEditable={true}
        />
      )}
      {activeStep === 2 && (
        <UniversityApplicationForm3
          setIsFormValid={setIsFormValid}
          form3Errors1={form3Errors1}
          setForm3Errors1={setForm3Errors1}
          form3Errors2={form3Errors2}
          setForm3Errors2={setForm3Errors2}
          validationForm3={validationForm3}
          educationalDetails={educationalDetails}
          setEducationalDetails={setEducationalDetails}
          academicDetails={academicDetails}
          setAcademicDetails={setAcademicDetails}
          workExperince={workExperince}
          setWorkExperience={setWorkExperience}
          otherDetails={otherDetails}
          setOtherDertails={setOtherDertails}
          setFormPage={setActiveStep}
          isStudentEditable={true}
        />
      )}
      {activeStep === 3 && (
        <ReviewUniversityApplication
          schoolName={schoolName}
          personalDetails={personalDetails}
          fatherDetails={fatherDetails}
          motherDetails={motherDetails}
          spouseDetils={spouseDetils}
          sibilingDetails={sibilingDetails}
          fundingDetails={fundingDetails}
          educationalDetails={educationalDetails}
          academicDetails={academicDetails}
          workExperince={workExperince}
          otherDetails={otherDetails}
          setFormPage={setActiveStep}
          SubmitData={SubmitData}
          paymentResponse={paymentResponse}
          payLater={PayLater}
          payLaterLoading={payLaterLoading}
          payNowLoading={payNowLoading}
          CreateApplication={CreateApplication}
          ApplicationCreated={applicationCreated}
          data={data}
          isStudentEditable={true}
        />
      )}

      {/* {!isAppliedSuccess ? (<>
            <RobotoTypo sx={{ my: 3, textAlign: 'center', fontWeight: '500', color: '#003B5C', fontSize: '24px' }}>
                Applicant Information
            </RobotoTypo>
            <Stack sx={{ backgroundColor: "#F7F7F7", margin: { md: '0 8%', xs: '0 2%' }, padding: '3%', borderTopLeftRadius: '20px', borderTopRightRadius:'20px' }} spacing={4}>
                <Stepper sx={{cursor:'pointer'}} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label}  onClick={() => handleStepClickInternal(index)} >
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Stack>
            {activeStep === 0 && <UniversityApplicationForm1 setIsFormValid={setIsFormValid} form1Errors={form1Errors} setForm1Errors={setForm1Errors} validationForm1={validationForm1} personalDetails={personalDetails} setPersonalDetails={setPersonalDetails} setFormPage={setActiveStep} />}
            {activeStep === 1 && <UniversityApplicationForm2 setIsFormValid={setIsFormValid} form2Errors={form2Errors} setForm2Errors={setForm2Errors} validationForm2={validationForm2} fatherDetails={fatherDetails} setFatherDetails={setFatherDetails} motherDetails={motherDetails} setMotherDetils={setMotherDetils} spouseDetils={spouseDetils} setSpouseDetails={setSpouseDetails}  sibilingDetails={sibilingDetails} setSibilingDetails={setSibilingDetails} fundingDetails={fundingDetails} setFundingDetails={setFundingDetails} setFormPage={setActiveStep} />}
            {activeStep === 2 && <UniversityApplicationForm3 setIsFormValid={setIsFormValid} form3Errors1={form3Errors1} setForm3Errors1={setForm3Errors1} form3Errors2={form3Errors2} setForm3Errors2={setForm3Errors2} validationForm3={validationForm3} educationalDetails={educationalDetails} setEducationalDetails={setEducationalDetails} academicDetails={academicDetails} setAcademicDetails={setAcademicDetails} workExperince={workExperince} setWorkExperience={setWorkExperience} otherDetails={otherDetails} setOtherDertails={setOtherDertails} setFormPage={setActiveStep} />}
            {activeStep === 3 && <ReviewUniversityApplication schoolName={schoolName}  personalDetails={personalDetails}  fatherDetails={fatherDetails}  motherDetails={motherDetails} spouseDetils={spouseDetils}  sibilingDetails={sibilingDetails} fundingDetails={fundingDetails} educationalDetails={educationalDetails} academicDetails={academicDetails}  workExperince={workExperince}  otherDetails={otherDetails} setFormPage={setActiveStep} SubmitData={SubmitData} />}
        </>):(
            <SuccessApplied schoolId={id} universityPersonalDetails={personalDetails} pageName='university'/>            
        )} */}
    </>
  );
}
