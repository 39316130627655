import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  Grid,
  Stack,
  Divider,
  TextareaAutosize,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { RobotoTypo, ErrTypo } from "../../../utils/Typographies";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ReactComponent as Hover1 } from "../../../assets/ContactUs/DateIcon.svg";
import { useTranslation } from "react-i18next";
import { Grade } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";

export default function SchoolApplicationForm2({
  setFormPage,
  familyDetails,
  setFamilyDetails,
  setIsFormValid,
  form2Errors,
  setForm2Errors,
  validationForm2,
}) {
  const { fatherDetails, motherDetails, SiblingsInfo, grandmotherInfo } =
    familyDetails;

  const { t } = useTranslation();

  const handleChange = (e, key, index) => {
    const { name, value } = e.target;
    console.log("key ", name);
    if (key == "SiblingsInfo") {
      setFamilyDetails((prev) => {
        const data = prev[key].map((itm, ind) => {
          if (index == ind) {
            return { ...itm, [name]: value };
          }
          return itm;
        });
        return { ...prev, [key]: data };
      });
    } else {
      setFamilyDetails((prev) => ({
        ...prev,
        [key]: { ...prev[key], [name]: value },
      }));
      if (key == "fatherDetails") {
        setForm2Errors((prev) => ({
          ...prev,
          fatherDetails: { ...prev.fatherDetails, [name]: "" },
        }));
      } else if (key == "motherDetails") {
        setForm2Errors((prev) => ({
          ...prev,
          motherDetails: { ...prev.motherDetails, [name]: "" },
        }));
      }
    }
  };

  const handledatepicker = (e, key, index) => {
    // Ensure e is a valid Date object
    const newDate = e instanceof Date && !isNaN(e) ? e : null;

    setFamilyDetails((prev) => {
      const data = prev[key].map((itm, ind) => {
        if (index === ind) {
          return { ...itm, dob: newDate }; // Set the new valid date
        }
        return itm;
      });
      return { ...prev, [key]: data };
    });
  };

  console.log("dob", familyDetails.SiblingsInfo[0].dob);
  const handleContinue = () => {
    // const isValid = validationForm2()
    // if(!isValid) {
    setIsFormValid((prev) => ({ ...prev, formTwo: true, formThree: true }));
    setFormPage(3);
    // }
  };

  const handleFatherMobile = (value, country, e) => {
    setFamilyDetails((prev) => ({
      ...prev,
      ["fatherDetails"]: {
        ...prev["fatherDetails"],
        contact: value,
        country_code: `+${country.dialCode}`,
        phone: value.slice(country.dialCode.length),
      },
    }));
    setForm2Errors((prev) => ({
      ...prev,
      fatherDetails: { ...prev.fatherDetails, phone: "" },
    }));
  };

  const handleMotherMobile = (value, country, e) => {
    setFamilyDetails((prev) => ({
      ...prev,
      ["motherDetails"]: {
        ...prev["motherDetails"],
        contact: value,
        country_code: `+${country.dialCode}`,
        phone: value.slice(country.dialCode.length),
      },
    }));
    setForm2Errors((prev) => ({
      ...prev,
      motherDetails: { ...prev.motherDetails, phone: "" },
    }));
  };

  useEffect(() => {
    console.log(familyDetails);
  }, [familyDetails]);
  return (
    <>
      <Box xs={12} padding="18px 30px" sx={{ backgroundColor: "#e1e1e1" }}>
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          {t("ApplicationForm.father_info_title", { returnObjects: true })}
        </RobotoTypo>
      </Box>
      <Divider
        sx={{
          borderTop: "1px solid #F3F4F6",
          width: "100%",
        }}
      />
      <Grid
        container
        rowGap={3}
        columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }}
        padding="3%"
      >
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.name", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="name"
            value={fatherDetails?.name}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
          {form2Errors?.fatherDetails?.name != "" && (
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>
              {" "}
              <ErrTypo> {form2Errors?.fatherDetails?.name} </ErrTypo>{" "}
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Average_monthly_income", {
              returnObjects: true,
            })}
          </RobotoTypo>
          <TextField
            name="avg_month_sal"
            value={fatherDetails?.avg_month_sal}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
          {form2Errors?.fatherDetails?.avg_month_sal != "" && (
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>
              {" "}
              <ErrTypo>
                {" "}
                {form2Errors?.fatherDetails?.avg_month_sal}{" "}
              </ErrTypo>{" "}
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Current_deposit", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="current_deposit"
            value={fatherDetails?.current_deposit}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
          {form2Errors?.fatherDetails?.current_deposit != "" && (
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>
              {" "}
              <ErrTypo>
                {" "}
                {form2Errors?.fatherDetails?.current_deposit}{" "}
              </ErrTypo>{" "}
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Phone", { returnObjects: true })}
          </RobotoTypo>
          {/* <TextField name="phone" value={fatherDetails?.phone} onChange={(e) => { handleChange(e, "fatherDetails") }} /> */}
          <PhoneInput
            value={fatherDetails?.contact}
            onChange={(value, country, e) =>
              handleFatherMobile(value, country, e)
            }
            // inputProps={{ name: "phone", maxLength: 20 }}
            country={"sg"}
            buttonStyle={{ border: "none" }}
            inputStyle={{
              height: "54px",
              width: "100%",
              marginTop: "10px",
              borderRadius: "5px",
              backgroundColor: "white",
              boxShadow: "2px 2px 4px 0px #00000040 inset",
              "& fieldset": { border: "none" },
              "& .MuiInputBase-input": {
                padding: "14px 14px",
              },
              // "&>.react-tel-input .flag-dropdown ": {
              //     backgroundColor: 'white',
              // }
            }}
            countryCodeEditable={false}
          />
          {form2Errors?.fatherDetails?.phone != "" && (
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>
              {" "}
              <ErrTypo> {form2Errors?.fatherDetails?.phone} </ErrTypo>{" "}
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Email", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="email"
            value={fatherDetails?.email}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
        </Grid>
        <Grid md={12}>
          <RobotoTypo
            mb={1}
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Highest_education_level", {
              returnObjects: true,
            })}
          </RobotoTypo>
          <TextField
            sx={{
              "& .MuiInputBase-root": { padding: "0px" },
              minHeight: "88px",
            }}
            multiline
            name="high_edu_level"
            value={fatherDetails?.high_edu_level}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Occupation_type", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="occupation_type"
            value={fatherDetails?.occupation_type}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Position", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="position"
            value={fatherDetails?.position}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Company_Name", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="company_name"
            value={fatherDetails?.company_name}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Company_Phone", { returnObjects: true })}
          </RobotoTypo>
          {/* <TextField name="company_phone" value={fatherDetails?.company_phone} onChange={(e) => { handleChange(e, "fatherDetails") }} /> */}
          <PhoneInput
            value={fatherDetails?.company_contact}
            onChange={(value, country, e) =>
              setFamilyDetails((prev) => ({
                ...prev,
                ["fatherDetails"]: {
                  ...prev["fatherDetails"],
                  company_contact: value,
                  company_country_code: `+ ${country.dialCode}`,
                  company_phone: value.slice(country.dialCode.length),
                },
              }))
            }
            // inputProps={{ name: "phone", maxLength: 20 }}
            country={"sg"}
            buttonStyle={{ border: "none" }}
            inputStyle={{
              height: "54px",
              width: "100%",
              marginTop: "10px",
              borderRadius: "5px",
              backgroundColor: "white",
              boxShadow: "2px 2px 4px 0px #00000040 inset",
              "& fieldset": { border: "none" },
              "& .MuiInputBase-input": {
                padding: "14px 14px",
              },
              // "&>.react-tel-input .flag-dropdown ": {
              //     backgroundColor: 'white',
              // }
            }}
            countryCodeEditable={false}
          />
        </Grid>
        <Grid md={12}>
          <RobotoTypo
            mb={1}
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Company_Address", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            sx={{
              "& .MuiInputBase-root": { padding: "0px" },
              minHeight: "88px",
            }}
            multiline
            name="company_address"
            value={fatherDetails?.company_address}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          />
        </Grid>
        <Grid
          md={12}
          columnGap={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <RobotoTypo
            my={1}
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Is_company_UnitedStates", {
              returnObjects: true,
            })}
          </RobotoTypo>
          <RadioGroup
            row
            name="Is_company_US"
            value={fatherDetails?.Is_company_US}
            onChange={(e) => {
              handleChange(e, "fatherDetails");
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={t("ApplicationForm.yes", { returnObjects: true })}
              labelPlacement="start"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={t("ApplicationForm.no", { returnObjects: true })}
              labelPlacement="start"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="10px"
        px="16px"
        py={2}
        sx={{
          backgroundColor: "#e1e1e1",
          borderBottom: "1px solid #E5E7EB",
          borderTop: "1px solid #E5E7EB",
        }}
      >
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          {t("ApplicationForm.mother_info_title", { returnObjects: true })}
        </RobotoTypo>
      </Stack>
      <Grid
        container
        rowGap={3}
        columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }}
        padding="3%"
      >
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.name", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="name"
            value={motherDetails?.name}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
          {form2Errors?.motherDetails?.name != "" && (
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>
              {" "}
              <ErrTypo> {form2Errors?.motherDetails?.name} </ErrTypo>{" "}
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Average_monthly_income", {
              returnObjects: true,
            })}{" "}
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>*</Box>
          </RobotoTypo>
          <TextField
            name="avg_month_sal"
            value={motherDetails?.avg_month_sal}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
          {form2Errors?.motherDetails?.avg_month_sal != "" && (
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>
              {" "}
              <ErrTypo>
                {" "}
                {form2Errors?.motherDetails?.avg_month_sal}{" "}
              </ErrTypo>{" "}
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Current_deposit", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="current_deposit"
            value={motherDetails?.current_deposit}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
          {form2Errors?.motherDetails?.current_deposit != "" && (
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>
              {" "}
              <ErrTypo>
                {" "}
                {form2Errors?.motherDetails?.current_deposit}{" "}
              </ErrTypo>{" "}
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Phone", { returnObjects: true })}
          </RobotoTypo>
          {/* <TextField name="phone" value={motherDetails?.phone} onChange={(e) => { handleChange(e, "motherDetails") }} /> */}
          <PhoneInput
            value={motherDetails?.contact}
            onChange={(value, country, e) =>
              handleMotherMobile(value, country, e)
            }
            // inputProps={{ name: "phone", maxLength: 20 }}
            country={"sg"}
            buttonStyle={{ border: "none" }}
            inputStyle={{
              height: "54px",
              width: "100%",
              marginTop: "10px",
              borderRadius: "5px",
              backgroundColor: "white",
              boxShadow: "2px 2px 4px 0px #00000040 inset",
              "& fieldset": { border: "none" },
              "& .MuiInputBase-input": {
                padding: "14px 14px",
              },
              // "&>.react-tel-input .flag-dropdown ": {
              //     backgroundColor: 'white',
              // }
            }}
            countryCodeEditable={false}
          />
          {form2Errors?.motherDetails?.phone != "" && (
            <Box sx={{ color: "#FF202E", display: "inline-block" }}>
              {" "}
              <ErrTypo> {form2Errors?.motherDetails?.phone} </ErrTypo>{" "}
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Email", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="email"
            value={motherDetails?.email}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
        </Grid>
        <Grid xs={12}>
          <RobotoTypo
            mb={1}
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Highest_education_level", {
              returnObjects: true,
            })}
          </RobotoTypo>
          <TextField
            sx={{
              "& .MuiInputBase-root": { padding: "0px" },
              minHeight: "88px",
            }}
            name="high_edu_level"
            value={motherDetails?.high_edu_level}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Occupation_type", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="occupation_type"
            value={motherDetails?.occupation_type}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Position", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="position"
            value={motherDetails?.position}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Company_Name", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="company_name"
            value={motherDetails?.company_name}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Company_Phone", { returnObjects: true })}
          </RobotoTypo>
          {/* <TextField name="company_phone" value={motherDetails?.company_phone} onChange={(e) => { handleChange(e, "motherDetails") }} /> */}
          <PhoneInput
            value={motherDetails?.company_contact}
            onChange={(value, country, e) =>
              setFamilyDetails((prev) => ({
                ...prev,
                ["motherDetails"]: {
                  ...prev["motherDetails"],
                  company_contact: value,
                  company_country_code: `+ ${country.dialCode}`,
                  company_phone: value.slice(country.dialCode.length),
                },
              }))
            }
            // inputProps={{ name: "phone", maxLength: 20 }}
            country={"sg"}
            buttonStyle={{ border: "none" }}
            inputStyle={{
              height: "54px",
              width: "100%",
              marginTop: "10px",
              borderRadius: "5px",
              backgroundColor: "white",
              boxShadow: "2px 2px 4px 0px #00000040 inset",
              "& fieldset": { border: "none" },
              "& .MuiInputBase-input": {
                padding: "14px 14px",
              },
              // "&>.react-tel-input .flag-dropdown ": {
              //     backgroundColor: 'white',
              // }
            }}
            countryCodeEditable={false}
          />
        </Grid>
        <Grid xs={12}>
          <RobotoTypo
            mb={1}
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Company_Address", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            sx={{
              "& .MuiInputBase-root": { padding: "0px" },
              minHeight: "88px",
            }}
            multiline
            name="company_address"
            value={motherDetails?.company_address}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          />
        </Grid>
        <Grid
          md={12}
          columnGap={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <RobotoTypo
            my={1}
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Is_company_UnitedStates", {
              returnObjects: true,
            })}
          </RobotoTypo>
          <RadioGroup
            row
            name="Is_company_US"
            value={motherDetails?.Is_company_US}
            onChange={(e) => {
              handleChange(e, "motherDetails");
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={t("ApplicationForm.yes", { returnObjects: true })}
              labelPlacement="start"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={t("ApplicationForm.no", { returnObjects: true })}
              labelPlacement="start"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <Stack
        justifyContent="center"
        px="16px"
        py={2}
        sx={{
          backgroundColor: "#e1e1e1",
          borderBottom: "1px solid #E5E7EB",
          borderTop: "1px solid #E5E7EB",
        }}
      >
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          {t("ApplicationForm.Applicant_Siblings_Information", {
            returnObjects: true,
          })}
        </RobotoTypo>
      </Stack>
      <Grid
        container
        rowGap={3}
        columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }}
        padding="3%"
      >
        {SiblingsInfo?.map((itm, index) => (
          <>
            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t("ApplicationForm.name", { returnObjects: true })}
              </RobotoTypo>
              <TextField
                name="name"
                value={itm?.name}
                onChange={(e) => {
                  handleChange(e, "SiblingsInfo", index);
                }}
              />
            </Grid>
            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t("ApplicationForm.passport_name", { returnObjects: true })}
              </RobotoTypo>
              <TextField
                name="passport_name"
                value={itm?.passport_name}
                onChange={(e) => {
                  handleChange(e, "SiblingsInfo", index);
                }}
              />
            </Grid>
            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t("ApplicationForm.Relationship", { returnObjects: true })}
              </RobotoTypo>
              <TextField
                name="relationship"
                value={itm?.relationship}
                onChange={(e) => {
                  handleChange(e, "SiblingsInfo", index);
                }}
              />
            </Grid>
            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t("ApplicationForm.dob", { returnObjects: true })}
              </RobotoTypo>
              {/* <TextField name="dob" value={SiblingsInfo?.dob} onChange={(e) => { handleChange(e, "SiblingsInfo", index) }} /> */}
              <DatePicker
                value={itm?.dob || null} // Ensure fallback to null
                name="dob"
                onChange={(e) => {
                  handledatepicker(e, "SiblingsInfo", index);
                }}
              />
            </Grid>
            <Grid xs={12} md={5}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {t("ApplicationForm.Nationality", { returnObjects: true })}
              </RobotoTypo>
              <TextField
                name="nationality"
                value={itm?.nationality}
                onChange={(e) => {
                  handleChange(e, "SiblingsInfo", index);
                }}
              />
            </Grid>
            <br />
          </>
        ))}
      </Grid>
      <Stack
        justifyContent="center"
        px="16px"
        py={2}
        sx={{
          backgroundColor: "#e1e1e1",
          borderBottom: "1px solid #E5E7EB",
          borderTop: "1px solid #E5E7EB",
        }}
      >
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          {t("ApplicationForm.Applicant_Grandmother_Information", {
            returnObjects: true,
          })}
        </RobotoTypo>
      </Stack>
      <Grid
        container
        rowGap={3}
        columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }}
        padding="3%"
      >
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.name", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="name"
            value={grandmotherInfo?.name}
            onChange={(e) => {
              handleChange(e, "grandmotherInfo");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.passport_name", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="passport_name"
            value={grandmotherInfo?.passport_name}
            onChange={(e) => {
              handleChange(e, "grandmotherInfo");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Relationship", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="relationship"
            value={grandmotherInfo?.relationship}
            onChange={(e) => {
              handleChange(e, "grandmotherInfo");
            }}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.dob", { returnObjects: true })}
          </RobotoTypo>
          {/* <TextField name="dob" value={grandmotherInfo?.dob} onChange={(e) => { handleChange(e, "grandmotherInfo") }} /> */}
          <DatePicker
            value={grandmotherInfo?.dob || null}
            name="dob"
            onChange={(e) =>
              setFamilyDetails((prev) => ({
                ...prev,
                ["grandmotherInfo"]: { ...prev["grandmotherInfo"], ["dob"]: e },
              }))
            }
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RobotoTypo
            sx={{
              fontWeight: "500",
              color: "#003B5C",
              fontSize: "18px",
              textAlign: "left",
            }}
          >
            {t("ApplicationForm.Nationality", { returnObjects: true })}
          </RobotoTypo>
          <TextField
            name="nationality"
            value={grandmotherInfo?.nationality}
            onChange={(e) => {
              handleChange(e, "grandmotherInfo");
            }}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="2% 3%"
        sx={{ borderTop: "1px solid #E5E7EB" }}
      >
        <Button
          onClick={() => {
            setFormPage(1);
          }}
          variant="Standard"
        >
          {t("back")}
        </Button>
        <Button onClick={handleContinue} variant="Standard">
          {t("continue")}
        </Button>
      </Box>
    </>
  );
}
