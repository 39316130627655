import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { RobotoTypo } from "../../utils/Typographies";
import { Box, Stack } from "@mui/material";
import { StyledTooltip } from "../../components/LandingPage/News";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NewsInformation = () => {
  const [newsList, setNewsList] = useState([]);
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const language = useSelector((state) => state.language.language);
  const [data, setData] = useState([]);
  const getNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getNews`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          if (response?.data != "") {
            setData(response.data);
            getData(response.data);
          } else {
            setData([]);
          }
        } else if (
          typeof response?.data === "object" &&
          response?.data !== null
        ) {
          if (response?.data != "" && response?.data != null) {
            setData(response.data);
            getData(response.data);
          } else {
            setData([]);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getNews();
  }, []);

  const getData = (data) => {
    console.log("news data ", data);
    const languageData = data?.map((item) => {
      if (language == "en") {
        return {
          data: item.en,
          id: item._id,
          isShow: item?.isShow,
        };
      } else if (language == "ch") {
        return {
          data: item.ch,
          id: item._id,
          isShow: item?.isShow,
        };
      }
      else if (language == "sch") {
        return {
          data: item?.sch || item.en,
          id: item._id,
          isShow: item?.isShow,
        };
      }
    });
    console.log("languageData ", languageData);
    setNewsList(languageData);
  };

  useEffect(() => {
    if (data != [] && data?.length != 0) {
      console.log("dashboardddata ", data);
      getData(data);
    }
  }, [language]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-CA"); // 'en-CA' format is yyyy-mm-dd
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent={"center"}
        sx={{ background: "white", height: "100%", width: "100%" }}
      >
        <Box
          sx={{ width: { xs: "100%", md: "50%" }, px: 10 }}
          display="flex"
          gap={8}
          flexDirection={"column"}
        >
          {newsList?.map(
            (item, index) =>
              item?.isShow && (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "15px",
                    borderRadius: "50px",
                    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)",
                    padding: "15px",
                    flexDirection: "column",
                    borderRadius: "24px",
                    backgroundColor: "#fbf3e4",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      alignItems: { xs: "flex-start", md: "flex-end" },
                      flexDirection: { xs: "column", md: "row" },
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <RobotoTypo
                      sx={{
                        color: "#969696",
                        letterSpacing: "0.08em",
                        fontWeight: "700",
                        fontSize: { xs: 16, md: 16, lg: 18 },
                      }}
                    >
                      {formatDate(item?.data?.postedDate).replace(/-/g, ".")}
                    </RobotoTypo>
                    <Box sx={{ width: { xs: "100%", sm: "180px" } }}>
                      <StyledTooltip
                        title={item?.data?.tagName}
                        arrow
                        placement="top"
                      >
                        <RobotoTypo
                          sx={{
                            color: "#177D84",
                            letterSpacing: "0.03em",
                            fontWeight: "700",
                            fontSize: { xs: 10, md: 12, lg: 15 },
                            border: "1px solid #177D84",
                            borderRadius: "50px",
                            padding: "4px 16px",

                            textAlign: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                          }}
                        >
                          {item?.data?.tagName}{" "}
                        </RobotoTypo>
                      </StyledTooltip>
                    </Box>
                  </Box>
                  <StyledTooltip
                    title={item?.data?.title}
                    arrow
                    placement="top"
                  >
                    <RobotoTypo
                      sx={{
                        color: "#003B5C",
                        letterSpacing: "0.08em",
                        fontWeight: "700",
                        fontSize: { xs: 18, sm: 18, md: 20, lg: 24 },
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.data?.title}{" "}
                    </RobotoTypo>
                  </StyledTooltip>
                  <Box sx={{ alignSelf: "flex-end" }}>
                    <RobotoTypo
                      onClick={() =>
                        Navigate(`/newsIndividualList/${item?.id}`)
                      }
                      sx={{
                        cursor: "pointer",
                        borderRadius: "50px",
                        padding: "5px 15px",
                        backgroundColor: "#FFC424",
                        display: "inline-block",
                        color: "#FFFFFF",
                        letterSpacing: "0.05em",
                        lineHeight: "20px",
                        fontWeight: "700",
                        fontSize: { xs: 13, md: 14 },
                      }}
                    >
                      {t("Home.News.More_Button")}
                    </RobotoTypo>
                  </Box>
                </Box>
              )
          )}
        </Box>
      </Box>
    </>
  );
};

export default NewsInformation;
