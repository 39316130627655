import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";

import { ReactComponent as WhatsappIcon } from "../../assets/FooterShare/whatsapp.svg";
import { ReactComponent as InstagramIcon } from "../../assets/FooterShare/instagram.svg";
import { ReactComponent as Xiaohongshu } from "../../assets/FooterShare/xiaohongshu.svg";
import { ReactComponent as LineIcon } from "../../assets/FooterShare/line.svg";
import { ReactComponent as MailIcon } from "../../assets/FooterShare/mail.svg";
import { ReactComponent as WeChatIcon } from "../../assets/FooterShare/wechat.svg";
import { ReactComponent as Tiktok } from "../../assets/FooterShare/Tiktok.svg";
import { ReactComponent as FaceBookIcon } from "../../assets/FooterShare/facebook.svg";
import { useTranslation } from "react-i18next";
import MailDialogBox from "../DialogComponents/MailDialogBox";
import { RobotoTypo } from "../../utils/Typographies";

export default function FooterShare() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <MailDialogBox open={open} setOpen={setOpen} />
      <Box
        sx={{
          marginTop: 1.4,
          marginBottom: 1.4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <IconButton
            onClick={() => {
              window.open(
                "https://www.facebook.com/SingaporeCustodianServices",
                "_blank"
              );
            }}
          >
            <FaceBookIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open("https://www.facebook.com/DRedugroup", "_blank");
            }}
          >
            <FaceBookIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(
                "https://www.instagram.com/drmedia_singapore/",
                "_blank"
              );
            }}
          >
            <InstagramIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              window.open(
                "https://www.tiktok.com/@singaporedr2023?_t=8lLSmptEkuP&_r=1",
                "_blank"
              );
            }}
          >
            <Tiktok />
          </IconButton>

          <IconButton
            onClick={() => {
              window.open(
                "https://www.xiaohongshu.com/user/profile/6202f9f0000000001000f392",
                "_blank"
              );
            }}
          >
            <Xiaohongshu />
          </IconButton>
          {/* <IconButton
            onClick={() => {
              window.open(
                "https://u.wechat.com/kJpWlgJkVFRhCwG5rpAgOKs",
                "_blank"
              );
            }}
          >
            <WeChatIcon />
          </IconButton> */}
        </Box>
      </Box>
    </>
  );
}
