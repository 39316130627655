import React from "react";
import { Box } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import HighSchoolImage from "../../../../assets/StudyingAboard/highSchool.png";

export default function HighSchoolHeader() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        // height: '100%',
        width: "100%",
        overflow: "hidden",
        background: "#B1D0E0",
      }}
    >
      <Box
        sx={{
          width: "100%",
          background: "#F5DFBA",
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "column",
            lg: "row",
            xl: "row",
            xxl: "row",
          },
          justifyContent: "end",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            borderBottomRightRadius: { md: "0", lg: "50px" },
            padding: { xs: "0", md: "0", lg: "60px" },
            zIndex: { xs: 0, md: 0, lg: 1 },
            height: { xs: "100px", md: "100px", lg: "60px", xl: "60" },
            // width: { xs: '100%', md: '100%', lg: '15%', xl: '15%' },
            position: {
              xs: "relative",
              md: "relative",
              lg: "absolute",
              xl: "absolute",
            },
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {t("StudyingAboard.highSchool.header", { returnObjects: true }).map(
            (item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  fontSize: "16px",
                }}
              >
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#003B5C",
                    fontSize: {
                      xs: "22px",
                      sm: "25px",
                      md: "28px",
                      lg: "30px",
                    },
                    textAlign: "left",
                  }}
                >
                  {item.item1}
                </RobotoTypo>
                <TimesNewTypo
                  sx={{
                    fontWeight: "700",
                    color: "#177D84",
                    fontSize: { xs: "20px", md: "24px", lg: "26px" },
                    textAlign: "left",
                  }}
                >
                  {item.item2}
                </TimesNewTypo>
              </Box>
            )
          )}
        </Box>

        <Box
          sx={{
            width: "100%", // Full width for all screen sizes
            maxWidth: { lg: "85%", xl: "85%", xxl: "85%" }, // Apply 85% width only for larger screens
            position: "relative",
            zIndex: 0,
            borderBottomLeftRadius: {
              xs: "0px",
              md: "0px",
              lg: "0px",
              xl: "50px",
              xxl: "50px",
            },
            height: { xs: "auto", md: "calc(80vh - 128px)" }, // Auto height for mobile, calculated height for others
            marginBottom: { xs: "16px", lg: "30px" }, // Add spacing at the bottom for mobile
            overflow: "hidden", // Prevent image overflow
          }}
        >
          <img
            src={HighSchoolImage}
            alt="Studing Aboard"
            style={{
              zIndex : '-1',
              objectFit: "cover",
              height: "100%",
              borderBottomLeftRadius: {
                xs: "0",
                md: "0",
                lg: "0",
                xl: "50px",
                xxl: "50px",
              },
              width: "100%",
              position: {
                xs: "relative",
                md: "relative",
                lg: "absolute",
                xl: "absolute",
                xxl: "absoulte",
              },
              right: "0",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: "200px",
          display: {
            xs: "none",
            md: "none",
            lg: "block",
            xl: "block",
            xxl: "block",
          },
          width: { xs: "15%", sm: "15%", md: "12%", lg: "10%", xl: "10%" },
          borderTopRightRadius: "50px",
          borderRight: "8px solid white",
          borderTop: "8px solid white",
          borderColor: "#B1D0E0",
          position: "absolute",
          bottom: "0px",
          zIndex: "99",
        }}
      ></Box>
    </Box>
  );
}
