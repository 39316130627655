import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Divider,
  Select,
  IconButton,
  TextField,
  TableBody,
  TableCell,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  ListItemText,
  Checkbox,
  Stack,
} from "@mui/material";
import { Clear, Delete } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { getId } from "../../../utils/common";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../../../App.css";

const toolbarOptions = [
  ["bold", "italic", "underline", "link"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ color: [] }, { background: [] }],
];
const module = { toolbar: toolbarOptions };

const ckEditorConfig = {
  toolbar: [
    "bold",
    "italic",
    "underline",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "undo",
    "redo",
    "|",
    "table", // Include the table button in the toolbar
  ],
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"], // Additional table features in the toolbar
  },
};

export default function School() {
  const [schoolBanner, setSchoolBanner] = useState([]);
  const [schoolLogo, setSchoolLogo] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [multiValue, setMultiValue] = useState([]);
  const [feeError, setFeeError] = useState(false);
  const [schoolTypes, setSchoolTypes] = useState([]);
  console.log("multiValue ", multiValue);
  const [data, setData] = useState({
    schoolName_en: "",
    schoolName_sch: "",
    description_en: "",
    benefits_en: "",
    benefits_sch: "",
    schoolName_ch: "",
    description_ch: "",
    description_sch: "",
    youtube_url: "",
    benefits_ch: "",
    lat: "",
    lon: "",
    category_id: [],
    school_types: [],
    service_fee_name: "",
    service_fee_amount: "",
    Application_fee_name: "",
    Application_fee_amount: "",
    Trans_nota_service: "",
    Registration_fee: "",
    Preparatory_course_48hrs: "",
    Preparatory_course_24hrs: "",
    Internati_bank_remittance_charge: "",
    other_fee: "",
    other_name: "",
    application_title_en: "Application Fees",
    application_title_ch: "申請費",
    application_title_sch: "申請費",
  });
  const [dataError, setDataError] = useState({
    schoolName_en: "",
    schoolName_sch: "",
    description_en: "",
    benefits_en: "",
    benefits_sch: "",
    schoolName_ch: "",
    description_ch: "",
    description_sch: "",
    youtube_url: "",
    benefits_ch: "",
  });
  const [schoolFile, setSchoolFile] = useState([]);
  const [schoolLogoFile, setSchoolLogoFile] = useState([]);
  const [schoolImageUrl, setSchoolImageUrl] = useState("");
  const [schoolLogoUrl, setSchoolLogoUrl] = useState("");
  const [schoolPictures, setSchoolPictures] = useState([]);
  const [schoolPictureFiles, setSchoolPictureFiles] = useState([]);
  const [schoolPituresUrl, setSchoolPicturesUrl] = useState([]);
  const [fileError, setFileError] = useState("");
  const [isShow, setIsShow] = useState(true);
  let { schoolId, edit } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let schoolType = location?.pathname.replace("/admin/", "");
  schoolType = schoolType.split("/");
  const [fields, setFields] = useState([
    {
      title_en: "",
      title_ch: "",
      title_sch: "",
      description_sch: "",
      description_en: "",
      description_ch: "",
    },
  ]);
  const [partnerUniversities, setPartnerUniversities] = useState([
    {
      title_en: "",
      title_ch: "",
      title_sch: "",
      description_sch: "",
      description_en: "",
      description_ch: "",
      logo: [],
      logo_file: [],
      logo_url: "",
    },
  ]);
  console.log("partnerSimply", partnerUniversities);
  const [admissionInfo, setAdmissionInfo] = useState([
    {
      admissionTitle_en: "",
      admissionTitle_ch: "",
      admissionTitle_sch: "",
      admissionDescription_sch: "",
      admissionDescription_en: "",
      admissionDescription_ch: "",
    },
  ]);
  const [entryRequirement, setEntryRequirement] = useState([
    {
      requirementTitle_en: "",
      requirementTitle_ch: "",
      requirementDescription_en: "",
      requirementDescription_ch: "",
      requirementTitle_sch: "",
      requirementDescription_sch: "",
    },
  ]);
  const [mandatoryApplicationFee, setMandatoryApplicationFee] = useState("");
  const [mandatoryConsultancyFee, setMandatoryConsultancyFee] = useState("");
  const [application_fees, setApplication_fees] = useState([
    {
      title_en: "",
      title_ch: "",
      title_sch: "",

      description_sch: "",
      amount_en: "",
      amount_ch: "",
      mandatory: false,
      url: "",
    },
  ]);
  const [rows, setRows] = useState([[]]);
  const [rich, setRich] = useState({
    fees_info_en: "",
    fees_info_ch: "",
    fees_info_sch: "",
  });

  const handleChangeFeesEn = (event, editor) => {
    const data = editor.getData();
    setRich((prev) => {
      return { ...prev, fees_info_en: data };
    });
  };
  const handleChangeFeesSch = (event, editor) => {
    const data = editor.getData();
    setRich((prev) => {
      return { ...prev, fees_info_sch: data };
    });
  };

  const handleChangeFeesCh = (event, editor) => {
    const data = editor.getData();
    setRich((prev) => {
      return { ...prev, fees_info_ch: data };
    });
  };

  const addRow = () => {
    setRows([...rows, Array(rows[0].length).fill("")]);
  };

  const deleteRow = (index) => {
    if (rows?.length != 1) setRows(rows?.filter((row, i) => i !== index));
  };

  const addColumn = () => {
    setRows(rows?.map((row) => [...row, ""]));
  };

  const deleteColumn = (index) => {
    if (rows?.length != 1)
      setRows(rows?.map((row) => row.filter((cell, i) => i !== index)));
  };

  const handleAddField = () => {
    setFields([
      ...fields,
      { title_en: "", title_ch: "", description_en: "", description_ch: "" },
    ]);
  };

  const handleAdmissionInfoAddField = () => {
    setAdmissionInfo([
      ...admissionInfo,
      {
        admissionTitle_en: "",
        admissionTitle_ch: "",
        admissionDescription_en: "",
        admissionDescription_ch: "",
      },
    ]);
  };

  const handleRequirementAddField = () => {
    setEntryRequirement([
      ...entryRequirement,
      {
        requirementTitle_en: "",
        requirementTitle_ch: "",
        requirementDescription_en: "",
        requirementDescription_ch: "",
        requirementTitle_sch: "",
        requirementDescription_sch: "",
      },
    ]);
  };

  const handleAddPartnerUniversitiesField = () => {
    setPartnerUniversities([
      ...partnerUniversities,
      {
        title_en: "",
        title_ch: "",
        title_sch: "",
        description_en: "",
        description_sch: "",
        description_ch: "",
        logo: [],
        logo_url: "",
        logo_file: [],
      },
    ]);
  };

  const handleDeleteField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };
  const handleDeleteApplicationFee = (index) => {
    const newFields = [...application_fees];

    newFields.splice(index, 1);
    setApplication_fees(newFields);
  };

  const handleDeleteAdmissionInfo = (index) => {
    const newInfo = [...admissionInfo];
    newInfo.splice(index, 1);
    setAdmissionInfo(newInfo);
  };

  const handleDeletePartnerUniversities = (index) => {
    const newInfo = [...partnerUniversities];
    newInfo.splice(index, 1);
    setPartnerUniversities(newInfo);
  };

  const handleDeleteEntryRequirement = (index) => {
    const newRequirement = [...entryRequirement];
    newRequirement.splice(index, 1);
    setEntryRequirement(newRequirement);
  };

  const handleChange = (index, field, value) => {
    const newFields = [...fields];
    newFields[index][field] = value;
    setFields(newFields);
  };

  const handleAdmissionInfoChange = (index, info, value) => {
    const newinfo = [...admissionInfo];
    newinfo[index][info] = value;
    setAdmissionInfo(newinfo);
  };

  const handleEntryRequirementChange = (index, requirement, value) => {
    const newRequirement = [...entryRequirement];
    newRequirement[index][requirement] = value;
    setEntryRequirement(newRequirement);
  };

  const handleSchoolBanner = (e) => {
    const files = Array.from(e.target.files);

    const imagesArray = files.map((file) => URL.createObjectURL(file));
    setSchoolBanner((prevImages) => [...prevImages, ...imagesArray]);
    setSchoolBanner(imagesArray);
    const modifiedFiles = files.map((file) => {
      const newName = file.name + "$" + "banner";
      const modifiedBlob = new Blob([file], { type: file.type });
      return new File([modifiedBlob], newName, { type: file.type });
    });
    setSchoolFile(modifiedFiles);
    setSchoolImageUrl("");
  };

  const handleRemoveImage = (index) => {
    setSchoolBanner((prevImages) => prevImages.filter((_, i) => i !== index));
    setSchoolFile([]);
  };
  console.log("entry requirement ", entryRequirement);
  const handleSchoolLogo = (e) => {
    const files = Array.from(e.target.files);
    console.log("files ", files);
    const imagesArray = files.map((file) => URL.createObjectURL(file));
    setSchoolLogo((prevImages) => [...prevImages, ...imagesArray]);
    setSchoolLogo(imagesArray);
    // setSchoolLogoFile(e.target.files[0])
    const modifiedFiles = files.map((file) => {
      const newName = file.name + "$" + "logo";
      const modifiedBlob = new Blob([file], { type: file.type });
      return new File([modifiedBlob], newName, { type: file.type });
    });
    setSchoolLogoFile(modifiedFiles);
    setSchoolLogoUrl("");
  };

  const handleRemoveLogo = (index) => {
    setSchoolLogo((prevImages) => prevImages.filter((_, i) => i !== index));
    setSchoolLogoFile([]);
  };

  const handleRemovePartnerUniversityLogo = (universityIndex, logoIndex) => {
    setPartnerUniversities((prev) => {
      const newPartnerUniversities = [...prev];
      const updatedLogos = newPartnerUniversities[universityIndex].logo.filter(
        (_, i) => i !== logoIndex
      );
      const updatedLogoFiles = newPartnerUniversities[
        universityIndex
      ].logo_file.filter((_, i) => i !== logoIndex);
      console.log("updatedLogos ", updatedLogos);
      console.log("updatedLogoFiles ", updatedLogoFiles);
      newPartnerUniversities[universityIndex] = {
        ...newPartnerUniversities[universityIndex],
        logo: updatedLogos,
        logo_file: updatedLogoFiles,
      };
      return newPartnerUniversities;
    });
  };

  const handleRemovePartnerUniversityLogoUrl = (universityIndex, logoIndex) => {
    setPartnerUniversities((prev) => {
      const newPartnerUniversities = [...prev];
      newPartnerUniversities[universityIndex] = {
        ...newPartnerUniversities[universityIndex],
        logo_url: "",
      };
      return newPartnerUniversities;
    });
  };

  const handleSchoolPictures = (e) => {
    let files = Array.from(e.target.files);
    //  files.forEach((item,index) => {
    //     item.name = item.name+'$'+'school';
    //  })
    const modifiedFiles = files.map((file) => {
      const newName = file.name + "$" + "school";
      const modifiedBlob = new Blob([file], { type: file.type });
      return new File([modifiedBlob], newName, { type: file.type });
    });
    setSchoolPictureFiles((prevFiles) => [...prevFiles, ...modifiedFiles]);

    const imagesArray = files.map((file) => {
      return {
        name: file.name, // Add file name
        url: URL.createObjectURL(file), // Blob URL
      };
    });

    setSchoolPictures((prevImages) => [...prevImages, ...imagesArray]);
  };

  const handleRemoveSchoolPictures = (name, index) => {
    setSchoolPictures((prevImages) => prevImages.filter((_, i) => i !== index));
    setSchoolPictureFiles((prevFiles) =>
      prevFiles.filter((file, i) => i !== index)
    );
  };

  const handleRemoveSchoolPicturesUrl = (imgs) => {
    setSchoolPicturesUrl((prev) => prev.filter((item) => item != imgs));
  };

  const handlePartnerUniversityLogo = (e, index) => {
    console.log("broken ", e);
    const files = Array.from(e.target.files);
    const imagesArray = files.map((file) => URL.createObjectURL(file));

    // Create a copy of the partnerUniversities array
    setPartnerUniversities((prev) => {
      const newPartnerUniversities = [...prev];
      // Update the logo field at the specific index
      newPartnerUniversities[index] = {
        ...newPartnerUniversities[index],
        logo: imagesArray,
      };
      console.log("newPartnerUniversities ", newPartnerUniversities);
      return newPartnerUniversities;
    });

    // Create modified files with new names
    const modifiedFiles = files.map((file) => {
      const newName = file.name + "$" + "logo";
      const modifiedBlob = new Blob([file], { type: file.type });
      return new File([modifiedBlob], newName, { type: file.type });
    });

    // Update the logo_file and logo_url fields
    setPartnerUniversities((prev) => {
      const newPartnerUniversities = [...prev];
      newPartnerUniversities[index] = {
        ...newPartnerUniversities[index],
        logo_file: modifiedFiles,
        logo_url: "",
      };
      return newPartnerUniversities;
    });

    // Reset the input field value
    e.target.value = null;
  };

  console.log("partnerUniversity ", partnerUniversities);
  console.log("setFeesInfo", rich);
  const validate = () => {
    let error = false;
    // if (data.title_en == "") {
    //   setDataError((prev) => ({ ...prev, title_en: "Title is required" }));
    //   error = true;
    // } else {
    //   setDataError((prev) => ({ ...prev, title_en: "" }));
    //   error = false;
    // }
    // if (data.description_en == "") {
    //   setDataError((prev) => ({
    //     ...prev,
    //     description_en: "Description is required",
    //   }));
    //   error = true;
    // } else {
    //   setDataError((prev) => ({ ...prev, description_en: "" }));
    //   error = false;
    // }
    // if (data.title_ch == "") {
    //   setDataError((prev) => ({ ...prev, title_ch: "Title is required" }));
    //   error = true;
    // } else {
    //   setDataError((prev) => ({ ...prev, title_ch: "" }));
    //   error = false;
    // }
    // if (data.description_ch == "") {
    //   setDataError((prev) => ({
    //     ...prev,
    //     description_ch: "Description is required",
    //   }));
    //   error = true;
    // } else {
    //   setDataError((prev) => ({ ...prev, description_ch: "" }));
    //   error = false;
    // }
    // if (data.url == "") {
    //   setDataError((prev) => ({ ...prev, url: "Url is required" }));
    //   error = true;
    // } else {
    //   setDataError((prev) => ({ ...prev, url: "" }));
    //   error = false;
    // }

    // if (schoolImageUrl == "" && schoolFile?.length == 0) {
    //   setFileError("Please upload image");
    //   error = true;
    // } else {
    //   setFileError("");
    //   error = false;
    // }
    let errors = [];
    if (
      mandatoryApplicationFee === ""
    ) {
      setFeeError(true);
      error = true;
    } else {
      setFeeError(false);
      error = false;
    }
    // Loop through each fee to check validation rules
    application_fees.forEach((fee, index) => {
      const feeErrors = {}; // Initialize errors for each fee

      // Check for mandatory fee validation
      if (fee.mandatory) {
        // Title fields cannot be empty
        if (fee.title_en.trim() === "") {
          feeErrors.title_en = "Title is required";
          error = true;
        }
        if (fee.title_ch.trim() === "") {
          feeErrors.title_ch = "Title is required";
          error = true;
        }
        if (fee.title_sch.trim() === "") {
          feeErrors.title_sch = "Title is required";
          error = true;
        }

        // Amount should not be empty or zero
        if (fee.amount_en.trim() === "") {
          feeErrors.amount_en = "Amount cannot be empty";
          error = true;
        }

        // URL cannot be empty
      } else {
        // If the fee is not mandatory, validation is still required for all fields
        if (fee.title_en.trim() === "") {
          feeErrors.title_en = "Title is required";
          error = true;
        }
        if (fee.title_sch.trim() === "") {
          feeErrors.title_sch = "Title is required";
          error = true;
        }
        if (fee.title_ch.trim() === "") {
          feeErrors.title_ch = "Title is required";
          error = true;
        }

        // If the fee is not mandatory, amount can be zero or empty
        if (fee.amount_en.trim() === "") {
          feeErrors.amount_en = "Amount is required"; // You can change this message based on your logic
          error = true;
        }
      }

      // If any errors exist, add them to the formErrors object
      if (Object.keys(feeErrors).length > 0) {
        errors[index] = feeErrors;
      }
    });

    // Update the formErrors state with the accumulated errors
    setFormErrors((prev) => ({
      ...prev,
      fees: errors, // Update only the errors for fees
    }));

    // For debugging purposes, logging the current form errors
    console.log(errors);

    console.log(formErrors);

    if (schoolTypes.length === 0) {
      setDataError((prev) => ({
        ...prev,
        school_types: "Please select at least one school type",
      }));
      return (error = true);
    }
    return error;
  };
  console.log("schoolTypes", schoolTypes);
  const handleSubmit = () => {
    const validation = validate();
    console.log("validation", validation);
    if (!validation) {
      const embeddURL = data.youtube_url;
      const form = new FormData();
      if (schoolImageUrl == "") {
        schoolFile.forEach((file) => {
          form.append("school_banner", file);
        });
      } else {
        form.append("school_banner", schoolImageUrl);
      }
      if (schoolLogoUrl == "") {
        schoolLogoFile.forEach((file) => {
          form.append("school_logo", file);
        });
      } else {
        form.append("school_logo", schoolLogoUrl);
      }
      if (schoolPictureFiles?.length != 0) {
        schoolPictureFiles.forEach((file) => {
          form.append("school_pictures", file);
        });
      }
      if (multiValue?.length != 0) {
        let a = [];
        multiValue.forEach((item) => a.push(item));
        console.log("type ", typeof a);
        form.append("category_id", JSON.stringify(a));
      }
      if (schoolTypes?.length != 0) {
        let a = [];
        schoolTypes.forEach((item) => a.push(item));
        console.log("type ", typeof a, a);

        form.append("school_types", JSON.stringify(schoolTypes));
      }
      if (schoolPituresUrl?.length != 0 && schoolPituresUrl) {
        console.log("schoolPituresUrl ", schoolPituresUrl);
        form.append("school_pictures", JSON.stringify(schoolPituresUrl));
      }
      if (fields.length != 0) {
        form.append("courses", JSON.stringify(fields));
      }
      if (admissionInfo?.length != 0) {
        form.append("admission_info", JSON.stringify(admissionInfo));
      }
      if (entryRequirement?.length != 0) {
        form.append("entry_requirement", JSON.stringify(entryRequirement));
      }
      if (application_fees?.length != 0) {
        form.append("services", JSON.stringify(application_fees));
      }
      if (partnerUniversities?.length != 0) {
        form.append(
          "partnerUniversitiesLength",
          JSON.stringify(partnerUniversities)
        );
      }

      const dummyData = Array.from(
        { length: partnerUniversities.length },
        (_, index) => index + 1
      );
      console.log("typeof ", partnerUniversities.length);
      dummyData?.map((ite) => {
        console.log("ite ", ite);
      });
      // Append partner universities logos and other fields
      partnerUniversities.forEach((university, index) => {
        university.logo_file.forEach((file) => {
          form.append(`partnerUniversities[${index}][logo_file]`, file);
          console.log("filesssss ", file);
        });
        console.log("ddgdd ", university.logo_file);
        // form.append(`partnerUniversities[${index}][logo_file]`, university.logo_file);
        form.append(
          `partnerUniversities[${index}][title_en]`,
          university.title_en
        );
        form.append(
          `partnerUniversities[${index}][title_sch]`,
          university.title_sch
        );
        form.append(
          `partnerUniversities[${index}][title_ch]`,
          university.title_ch
        );
        form.append(
          `partnerUniversities[${index}][description_en]`,
          university.description_en
        );
        form.append(
          `partnerUniversities[${index}][description_ch]`,
          university.description_ch
        );
        form.append(
          `partnerUniversities[${index}][description_sch]`,
          university.description_sch
        );
        form.append(
          `partnerUniversities[${index}][logo_url]`,
          university.logo_url
        );
      });

      form.append("en_schoolName", data.schoolName_en);
      form.append("en_description", data.description_en);
      form.append("sch_schoolName", data.schoolName_sch);
      form.append("sch_description", data.description_sch);
      form.append("ch_schoolName", data.schoolName_ch);

      form.append("ch_description", data.description_ch);
      form.append("en_benefits", data.benefits_en);
      form.append("ch_benefits", data.benefits_ch);
      form.append("sch_benefits", data.benefits_sch);

      form.append("youtube_url", embeddURL);
      form.append("school_type", schoolType[0]);
      form.append("en_feesInfo", rich.fees_info_en);
      form.append("ch_feesInfo", rich.fees_info_ch);
      form.append("sch_feesInfo", rich.fees_info_sch);
      form.append("latitude", data.lat);
      form.append("longtitude", data.lon);

      form.append("service_fee_name", data.service_fee_name);
      form.append("service_fee_amount", data.service_fee_amount);
      form.append("Application_fee_name", data.Application_fee_name);
      form.append("Application_fee_amount", data.Application_fee_amount);
      form.append("Trans_nota_service", data.Trans_nota_service);
      form.append("Registration_fee", data.Registration_fee);
      form.append("Preparatory_course_48hrs", data.Preparatory_course_48hrs);
      form.append("Preparatory_course_24hrs", data.Preparatory_course_24hrs);
      form.append(
        "Internati_bank_remittance_charge",
        data.Internati_bank_remittance_charge
      );
      form.append("other_name", data.other_name);
      form.append("other_fee", data.other_fee);
      form.append("en_applicationName", data.application_title_en);
      form.append("ch_applicationName", data.application_title_ch);
      form.append("sch_applicationName", data.application_title_sch);
      form.append("isShow", isShow);
      form.append("application_fee_amount", mandatoryApplicationFee);
      form.append("consultation_fee_amount", mandatoryConsultancyFee)

      if (schoolId) {
        updateSchool(form);
      } else {
        addSchool(form);
      }
    }
  };
  console.log("datas", data);
  const addSchool = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addSchool`,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        // navigate('/admin/primarySchool')
        navigate(`/admin/${schoolType[0]}`);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  const mapDataToState = (data, dataCh, dataSch) => {
    console.log("witihn map ", data, dataCh);
    return data.map((university, index) => ({
      title_en: university.title,
      title_ch: dataCh[index].title,
      title_sch: dataSch[index].title,
      description_en: university.description,
      description_ch: dataCh[index].description,
      description_sch: dataSch[index].description,
      logo: [],
      logo_file: [],
      logo_url: university.logo_url,
    }));
  };

  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSchoolTypes(typeof value === "string" ? value.split(",") : value);
    if (schoolTypes.length > 0) {
      setDataError((prev) => ({
        ...prev,
        school_types: "", // Reset the error message for school_types
      }));
    }
  };
  const updateSchool = async (reqData) => {
    console.log(reqData)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/updateSchoolById/` + schoolId,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        console.log("school updated ", schoolType[0]);
        navigate(`/admin/${schoolType[0]}`);
      }
    } catch (error) {
      if (error.response.status == 403) {
        navigate("/admin/login");
      }
    }
  };

  const handleInputChange = (e, reqName) => {
    if (
      reqName == "benefits_en" ||
      reqName == "benefits_ch" ||
      reqName == "benefits_sch"
    ) {
      setData((prev) => ({ ...prev, [reqName]: e }));
      setDataError((prev) => ({ ...prev, [reqName]: "" }));
      return;
    }
    const { name, value } = e.target;
    if (
      name == "service_fee_amount" ||
      name == "Application_fee_amount" ||
      name == "Trans_nota_service" ||
      name == "Registration_fee" ||
      name == "Preparatory_course_48hrs" ||
      name == "Preparatory_course_24hrs" ||
      name == "Internati_bank_remittance_charge" ||
      name == "other_fee"
    ) {
      if (/^\d*$/.test(value)) {
        setData((prev) => ({ ...prev, [name]: value }));
        setDataError((prev) => ({ ...prev, [name]: "" }));
      }
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
      setDataError((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleUniversityChange = (index, reqName, e) => {
    const newFields = [...partnerUniversities];
    newFields[index][reqName] = e;
    setPartnerUniversities(newFields);
  };

  useEffect(() => {
    if (schoolId) {
      getSchoolById(schoolId);
    }
  }, []);
  const getSchoolById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getSchool/` + id
      );
      if (response.status == 200) {
        const schoolData = response?.data;
        console.log(schoolData?.consultation_fee_amount)
        setRich({
          fees_info_en: schoolData?.en?.fees_info,
          fees_info_ch: schoolData.ch.fees_info,
          fees_info_sch: schoolData.sch?.fees_info ?? "",
        });

        setData({
          schoolName_en: schoolData.en.school_name,
          schoolName_sch: schoolData?.sch?.school_name ?? "",
          description_en: schoolData.en.description,
          description_sch: schoolData?.sch?.description ?? "",
          benefits_en: schoolData.en.benefits,
          benefits_sch: schoolData?.sch?.benefits ?? "",

          schoolName_ch: schoolData.ch.school_name,
          description_ch: schoolData.ch.description,
          youtube_url: schoolData.en.url,
          benefits_ch: schoolData.ch.benefits,
          lat: schoolData.en.lat,
          lon: schoolData.en.lon,
          category_id: schoolData.en.category_id,
          service_fee_amount: schoolData.en.service_fee_amount,
          Application_fee_amount: schoolData.en.Application_fee_amount,
          Trans_nota_service: schoolData.en.Trans_nota_service,
          Registration_fee: schoolData.en.Registration_fee,
          Preparatory_course_48hrs: schoolData.en.Preparatory_course_48hrs,
          Preparatory_course_24hrs: schoolData.en.Preparatory_course_24hrs,
          Internati_bank_remittance_charge:
            schoolData.en.Internati_bank_remittance_charge,
          other_fee: schoolData.en.other_fee,
          application_title_en: schoolData.en.application_title,
          application_title_ch: schoolData.ch.application_title,
          application_title_sch: schoolData.sch?.application_title ?? "",
        });
        setMandatoryApplicationFee(schoolData?.application_fee_amount ?? "");
        setMandatoryConsultancyFee(schoolData?.consultation_fee_amount ?? "");
        setSchoolImageUrl(schoolData.en.school_banner);
        setSchoolLogoUrl(schoolData.en.school_logo);
        setIsShow(schoolData?.isShow);
        setMultiValue(
          Array.isArray(schoolData.en.category_id)
            ? schoolData.en.category_id
            : []
        );
        setSchoolTypes(
          Array.isArray(schoolData?.en?.school_types)
            ? schoolData.en.school_types
            : []
        );
        setSchoolPicturesUrl(schoolData.en.school_pitures);
        setFields(
          schoolData.en.courses_info_adminpanel
            ? schoolData.en.courses_info_adminpanel
            : []
        );
        setAdmissionInfo(
          schoolData.en.admission_info_adminpanel
            ? schoolData.en.admission_info_adminpanel
            : []
        );
        setEntryRequirement(
          schoolData.en.entry_requirement_adminpanel
            ? schoolData.en.entry_requirement_adminpanel
            : []
        );

        console.log("partnerUniversities ", schoolData.en.partnerUniversity);
        console.log("schoolss ", response?.data);
        if (schoolData?.en?.partnerUniversity?.length > 0) {
          console.log("partnerUniversity: ", schoolData.en.partnerUniversity);

          const initialState = schoolData.en.partnerUniversity.map(
            (university, index) => ({
              title_en: university?.title || "",
              title_ch: schoolData.ch?.partnerUniversity?.[index]?.title || "",
              title_sch:
                schoolData?.sch?.partnerUniversity?.[index]?.title || "",
              description_en: university?.description || "",
              description_ch:
                schoolData.ch?.partnerUniversity?.[index]?.description || "",
              description_sch:
                schoolData?.sch?.partnerUniversity?.[index]?.description || "",
              logo: [],
              logo_file: [],
              logo_url: university?.logo_url || "",
            })
          );

          console.log("Mapped initialState: ", initialState);
          setPartnerUniversities(initialState);
        }
        setApplication_fees(
          schoolData?.services
            ? Array.isArray(JSON.parse(schoolData.services))
              ? JSON.parse(schoolData.services).map((item) => ({
                  title_en: item?.title_en ?? "",
                  title_ch: item?.title_ch ?? "",
                  amount_en: item?.amount_en ?? "",
                  amount_ch: item?.amount_ch ?? "",
                  title_sch: item?.title_sch ?? "",
                  amount_sch: item?.amount_sch ?? "",
                  mandatory: item?.mandatory ?? false,
                  url: item?.url ?? "",
                }))
              : []
            : [
                {
                  title_en: "",
                  title_ch: "",
                  amount_en: "",
                  amount_ch: "",
                  title_sch: "",
                  amount_sch: "",
                  mandatory: false,
                  url: "",
                },
              ]
        );

        // setRows(schoolData.en.entry_requirement_adminpanel ? schoolData.en.entry_requirement_adminpanel : [])
      }
    } catch (error) {}
  };
  console.log("Apllication fees ", application_fees);

  console.log("rows ", rows);
  const schoolOptions = [
    { value: "primarySchool", label: "Primary School" },
    { value: "secondarySchool", label: "Secondary School" },
    { value: "internationalSchool", label: "International School" },
    { value: "university", label: "University" },
    { value: "languageCourse", label: "Language Course" },
    { value: "kindergarten", label: "ChildCare & Kindergarten" },
  ];
  const handleApplicationFee = (index, field, e) => {
    const value = e.target.value;
    const updatedFees = [...application_fees];
    updatedFees[index][field] = value;
    setApplication_fees(updatedFees);
  };
  const handleApplicationFeeChange = (e) => {
    const value = e.target.value;
    setMandatoryApplicationFee(value);

    // Validate fee
    // if (value === "") {
    //   setFeeError(true);
    // } else {
    //   setFeeError(false);
    // }
  };

  const handleConsultancyFeeChange = (e) => {
    const value = e.target.value;
    setMandatoryConsultancyFee(value);

    // Validate fee
    // if (Number(value) <= 0 || value === "") {
    //   setFeeError(true);
    // } else {
    //   setFeeError(false);
    // }
  };


  const toggleMandatory = (index) => {
    setApplication_fees((prevFees) => {
      const updatedFees = [...prevFees];
      const updatedFee = updatedFees[index];

      updatedFee.mandatory = !updatedFee.mandatory;

      return updatedFees;
    });
  };
  console.log("rich", rich);
  return (
    <Admin>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                mt: 2,
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 2,
              }}
            >
              School Header
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              School Name (English)
            </RobotoTypo>
            <TextField
              name="schoolName_en"
              value={data.schoolName_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.schoolName_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                Title
              </RobotoTypo>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                mt: 8,
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              School Name (Chinese)
            </RobotoTypo>
            <TextField
              name="schoolName_ch"
              value={data.schoolName_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.schoolName_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                Title
              </RobotoTypo>
            )}
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            School Name (Simplified Chinese)
          </RobotoTypo>
          <TextField
            name="schoolName_sch"
            value={data.schoolName_sch}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={handleInputChange}
          />
          {dataError.schoolName_sch != "" && (
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 14, sm: 18 },
                color: "red",
                fontWeight: 500,
                my: 1,
              }}
            >
              Title
            </RobotoTypo>
          )}
        </Box>
        <Box sx={{ mb: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Upload School picture
          </RobotoTypo>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="image-input"
            onChange={handleSchoolBanner}
          />
          <label htmlFor="image-input">
            <Button
              component="span"
              variant="contained"
              sx={{
                mb: 1,
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
            >
              Select Image
            </Button>
            {fileError != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  mt: 1,
                }}
              >
                {fileError}
              </RobotoTypo>
            )}
          </label>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "14px",
              color: "rgb(23, 125, 132)",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Image dimensions should be or below 1024 x 768 pixels.
          </RobotoTypo>
          <Grid container spacing={2}>
            {schoolImageUrl ? (
              <Grid item xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_FILE_URL}${schoolImageUrl}`}
                    alt={`Preview ${schoolImageUrl}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => setSchoolImageUrl("")}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ) : (
              schoolBanner?.map((image, index) => (
                <Grid item key={index} xs={6} md={4}>
                  <Box style={{ position: "relative" }}>
                    <Box
                      component="img"
                      src={image}
                      alt={`Preview ${index}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Upload School Logo
          </RobotoTypo>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="logo-input"
            onChange={handleSchoolLogo}
          />
          <label htmlFor="logo-input">
            <Button
              component="span"
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
            >
              Select Image
            </Button>
            {fileError != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {fileError}
              </RobotoTypo>
            )}
          </label>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "14px",
              color: "rgb(23, 125, 132)",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Image dimensions should be or below 250 x 100 pixels.
          </RobotoTypo>
          <Grid container spacing={2}>
            {schoolLogoUrl ? (
              <Grid item xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_FILE_URL}${schoolLogoUrl}`}
                    alt={`Preview ${schoolLogoUrl}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => setSchoolLogoUrl("")}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ) : (
              schoolLogo?.map((image, index) => (
                <Grid item key={index} xs={6} md={4}>
                  <Box style={{ position: "relative" }}>
                    <Box
                      component="img"
                      src={image}
                      alt={`Preview ${index}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleRemoveLogo(index)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Box mb={1}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",

              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Allot School Types
          </RobotoTypo>
          <Select
            name="school_types"
            value={schoolTypes}
            multiple
            onChange={handleSelect}
            renderValue={(selected) => {
              const selectedLabels = schoolOptions
                .filter((option) => selected.includes(option.value))
                .map((option) => option.label);
              return selectedLabels.join(", ");
            }}
            sx={{ width: "300px", textTransform: "capitalize" }}
          >
            {schoolOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={schoolTypes.indexOf(option.value) > -1} />

                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>

          {dataError?.school_types && (
            <Typography sx={{ color: "red" }}>
              {dataError?.school_types}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                my: 1,
              }}
            >
              Description (English)
            </RobotoTypo>
            <TextField
              name="description_en"
              value={data.description_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.description_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                description (Chinese)
              </RobotoTypo>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                my: 1,
              }}
            >
              Description (Chinese)
            </RobotoTypo>
            <TextField
              name="description_ch"
              value={data.description_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.description_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                description
              </RobotoTypo>
            )}
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              my: 1,
            }}
          >
            Description (Simplified Chinese)
          </RobotoTypo>
          <TextField
            name="description_sch"
            value={data.description_sch}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={handleInputChange}
          />
          {dataError.description_sch != "" && (
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 14, sm: 18 },
                color: "red",
                fontWeight: 500,
                my: 1,
              }}
            >
              description (Simplified Chinese)
            </RobotoTypo>
          )}
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              my: 1,
            }}
          >
            Youtube URL
          </RobotoTypo>
          <TextField
            name="youtube_url"
            value={data.youtube_url}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={handleInputChange}
          />
          {dataError.youtube_url != "" && (
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 14, sm: 18 },
                color: "red",
                fontWeight: 500,
                my: 1,
              }}
            >
              url
            </RobotoTypo>
          )}
        </Box>
        <Box sx={{ my: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Benefits and Advantages(English)
          </RobotoTypo>
          <ReactQuill
            value={data?.benefits_en}
            name="benefits_en"
            onChange={(e) => handleInputChange(e, "benefits_en")}
            modules={module}
            theme="snow"
            style={{ height: "150px" }}
            placeholder=""
          />
        </Box>

        <Box sx={{ my: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Benefits and Advantages(Chinese)
          </RobotoTypo>
          <ReactQuill
            value={data?.benefits_ch}
            name="benefits_ch"
            onChange={(e) => handleInputChange(e, "benefits_ch")}
            modules={module}
            theme="snow"
            style={{ height: "150px" }}
            placeholder=""
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Benefits and Advantages(Simplified Chinese)
          </RobotoTypo>
          <ReactQuill
            value={data?.benefits_sch}
            name="benefits_sch"
            onChange={(e) => handleInputChange(e, "benefits_sch")}
            modules={module}
            theme="snow"
            style={{ height: "150px" }}
            placeholder=""
          />
        </Box>

        <Box sx={{ my: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Upload School Pictures
          </RobotoTypo>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="school-pictures"
            multiple
            onChange={handleSchoolPictures}
          />
          <label htmlFor="school-pictures">
            <Button
              component="span"
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
            >
              Select Images
            </Button>
            {fileError != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  mt: 1,
                }}
              >
                {fileError}
              </RobotoTypo>
            )}
          </label>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "14px",
              color: "rgb(23, 125, 132)",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Image dimensions should be 500 X 500 pixels or have a 4:4 aspect
            ratio to fit optimizely.
          </RobotoTypo>
          <Grid container spacing={2}>
            {schoolPituresUrl?.length != 0 &&
              schoolPituresUrl?.map((image, index) => (
                <Grid item xs={6} md={4}>
                  <Box style={{ position: "relative" }}>
                    <Box
                      component="img"
                      src={`${process.env.REACT_APP_FILE_URL}${image}`}
                      alt={`Preview ${image}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleRemoveSchoolPicturesUrl(image)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Grid>
              ))}

            {schoolPictures?.map((image, index) => (
              <Grid item key={index} xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={image.url}
                    alt={`Preview ${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() =>
                      handleRemoveSchoolPictures(image.name, index)
                    }
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ my: 2 }}>
          <Box>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 22 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Major/Courses
            </RobotoTypo>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
              onClick={handleAddField}
            >
              Add
            </Button>
            {fields?.map((field, index) => (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                key={index}
              >
                <Box
                  sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}
                >
                  {/* <Box sx={{display:'flex', gap:'20px',width:'100%'}}> */}
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Courses Title(English)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={field.title_en}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleChange(index, "title_en", e.target.value)
                      }
                    />
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Courses Title(Chinese)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={field.title_ch}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleChange(index, "title_ch", e.target.value)
                      }
                    />
                  </Box>
                  {/* </Box> */}
                </Box>
                <Box sx={{ width: "50%" }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Courses Title(Simplified Chinese)
                  </RobotoTypo>
                  <TextField
                    variant="outlined"
                    value={field.title_sch}
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      handleChange(index, "title_sch", e.target.value)
                    }
                  />
                </Box>

                <Box sx={{ my: 2 }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Description(English)
                  </RobotoTypo>
                  <ReactQuill
                    value={field?.description_en}
                    onChange={(e) => handleChange(index, "description_en", e)}
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Description(Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={field?.description_ch}
                    onChange={(e) => handleChange(index, "description_ch", e)}
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Description(Simplified Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={field?.description_sch}
                    onChange={(e) => handleChange(index, "description_sch", e)}
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                </Box>
                <Box sx={{ mt: 4 }}>
                  {/* <IconButton > */}
                  {/* <Delete /> */}
                  <Button
                    component="span"
                    onClick={() => handleDeleteField(index)}
                    aria-label="delete"
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      mt: 2,
                      width: "fit-content",
                      backgroundColor: "#f50057",
                      "&:hover": { backgroundColor: "#f50057" },
                    }}
                  >
                    Delete
                  </Button>
                  {/* </IconButton> */}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Patnership universities */}

        <Box sx={{ my: 2 }}>
          <Box>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 22 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Partner Universities
            </RobotoTypo>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
              onClick={handleAddPartnerUniversitiesField}
            >
              Add
            </Button>
            {partnerUniversities?.map((field, index) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                key={index}
              >
                {console.log("field ", field)}
                <Box
                  sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}
                >
                  {/* <Box sx={{display:'flex', gap:'20px',width:'100%'}}> */}
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      University Title(English)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={field.title_en}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleUniversityChange(
                          index,
                          "title_en",
                          e.target.value
                        )
                      }
                    />
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      University Title(Chinese)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={field.title_ch}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleUniversityChange(
                          index,
                          "title_ch",
                          e.target.value
                        )
                      }
                    />
                  </Box>

                  {/* </Box> */}
                </Box>
                <Box sx={{ width: "50%" }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    University Title(Simplified Chinese)
                  </RobotoTypo>
                  <TextField
                    variant="outlined"
                    value={field.title_sch}
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      handleUniversityChange(index, "title_sch", e.target.value)
                    }
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Description(English)
                  </RobotoTypo>
                  <ReactQuill
                    value={field?.description_en}
                    onChange={(e) =>
                      handleUniversityChange(index, "description_en", e)
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Description(Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={field?.description_ch}
                    onChange={(e) =>
                      handleUniversityChange(index, "description_ch", e)
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Description(Simplified Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={field?.description_sch}
                    onChange={(e) =>
                      handleUniversityChange(index, "description_sch", e)
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                </Box>

                <Box sx={{ mb: 2, mt: 5 }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Upload University Logo
                  </RobotoTypo>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id={`logo-inputs${index}`}
                    onChange={(e) => handlePartnerUniversityLogo(e, index)}
                  />
                  <label htmlFor={`logo-inputs${index}`}>
                    <Button
                      component="span"
                      variant="contained"
                      sx={{
                        mb: 2,
                        backgroundColor: "#177D84",
                        "&:hover": { backgroundColor: "#177D84" },
                      }}
                    >
                      Select Images
                    </Button>
                    {fileError != "" && (
                      <RobotoTypo
                        sx={{
                          textAlign: "center",
                          width: "fit-content",
                          fontSize: { xs: 14, sm: 18 },
                          color: "red",
                          fontWeight: 500,
                          my: 1,
                        }}
                      >
                        {fileError}
                      </RobotoTypo>
                    )}
                  </label>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: "14px",
                      color: "rgb(23, 125, 132)",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Image dimensions should be or below 250 x 100 pixels.
                  </RobotoTypo>
                  <Grid container spacing={2}>
                    {field.logo_url ? (
                      <Grid item xs={6} md={4}>
                        <Box style={{ position: "relative" }}>
                          <Box
                            component="img"
                            src={`${process.env.REACT_APP_FILE_URL}${field.logo_url}`}
                            alt={`Preview ${field.logo_url}`}
                            style={{ width: "100%", height: "auto" }}
                          />
                          <IconButton
                            style={{ position: "absolute", top: 0, right: 0 }}
                            onClick={() =>
                              handleRemovePartnerUniversityLogoUrl(index)
                            }
                          >
                            <Clear />
                          </IconButton>
                        </Box>
                      </Grid>
                    ) : (
                      field.logo?.map((image, logoIndex) => (
                        <Grid item key={index} xs={6} md={4}>
                          <Box style={{ position: "relative" }}>
                            <Box
                              component="img"
                              src={image}
                              alt={`Preview ${index}`}
                              style={{ width: "100%", height: "auto" }}
                            />
                            <IconButton
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                              onClick={() =>
                                handleRemovePartnerUniversityLogo(
                                  index,
                                  logoIndex
                                )
                              }
                            >
                              <Clear />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Box>

                <Box sx={{ mt: 4 }}>
                  {/* <IconButton > */}
                  {/* <Delete /> */}
                  <Button
                    component="span"
                    onClick={() => handleDeletePartnerUniversities(index)}
                    aria-label="delete"
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      mt: 2,
                      width: "fit-content",
                      backgroundColor: "#f50057",
                      "&:hover": { backgroundColor: "#f50057" },
                    }}
                  >
                    Delete
                  </Button>
                  {/* </IconButton> */}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ my: 2 }}>
          <Box>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 22 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Admission Info
            </RobotoTypo>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
              onClick={handleAdmissionInfoAddField}
            >
              Add
            </Button>
            {admissionInfo?.map((info, index) => (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                key={index}
              >
                <Box
                  sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}
                >
                  {/* <Box sx={{display:'flex', gap:'20px',width:'100%'}}> */}
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Admission Information Title(English)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={info.admissionTitle_en}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleAdmissionInfoChange(
                          index,
                          "admissionTitle_en",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Admission Information Title(Chinese)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={info.admissionTitle_ch}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleAdmissionInfoChange(
                          index,
                          "admissionTitle_ch",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  {/* </Box> */}
                </Box>
                <Box sx={{ width: "50%" }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Admission Information Title(Simplified Chinese)
                  </RobotoTypo>
                  <TextField
                    variant="outlined"
                    value={info.admissionTitle_sch}
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      handleAdmissionInfoChange(
                        index,
                        "admissionTitle_sch",
                        e.target.value
                      )
                    }
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Admission Information Description(English)
                  </RobotoTypo>
                  <ReactQuill
                    value={info?.admissionDescription_en}
                    onChange={(e) =>
                      handleAdmissionInfoChange(
                        index,
                        "admissionDescription_en",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Admission Information Description(Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={info?.admissionDescription_ch}
                    onChange={(e) =>
                      handleAdmissionInfoChange(
                        index,
                        "admissionDescription_ch",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Admission Information Description (Simplified Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={info?.admissionDescription_sch}
                    onChange={(e) =>
                      handleAdmissionInfoChange(
                        index,
                        "admissionDescription_sch",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                </Box>
                <Box sx={{ mt: 4 }}>
                  {/* <IconButton > */}
                  {/* <Delete /> */}
                  <Button
                    component="span"
                    onClick={() => handleDeleteAdmissionInfo(index)}
                    aria-label="delete"
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      mt: 2,
                      width: "fit-content",
                      backgroundColor: "#f50057",
                      "&:hover": { backgroundColor: "#f50057" },
                    }}
                  >
                    Delete
                  </Button>
                  {/* </IconButton> */}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ my: 2 }}>
          <Box>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 22 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Entry Requirements
            </RobotoTypo>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
              onClick={handleRequirementAddField}
            >
              Add
            </Button>
            {entryRequirement?.map((requirement, index) => (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                key={index}
              >
                <Box
                  sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}
                >
                  {/* <Box sx={{display:'flex', gap:'20px',width:'100%'}}> */}
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Entry Requirement Title(English)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={requirement.requirementTitle_en}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleEntryRequirementChange(
                          index,
                          "requirementTitle_en",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Entry Requirement Title(Chinese)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={requirement.requirementTitle_ch}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleEntryRequirementChange(
                          index,
                          "requirementTitle_ch",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  {/* </Box> */}
                </Box>
                <Box sx={{ width: "50%" }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Entry Requirement Title(Simplified Chinese)
                  </RobotoTypo>
                  <TextField
                    variant="outlined"
                    value={requirement.requirementTitle_sch}
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      handleEntryRequirementChange(
                        index,
                        "requirementTitle_sch",
                        e.target.value
                      )
                    }
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Entry Requirement (English)
                  </RobotoTypo>
                  <ReactQuill
                    value={requirement.requirementDescription_en}
                    onChange={(e) =>
                      handleEntryRequirementChange(
                        index,
                        "requirementDescription_en",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Entry Requirement(Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={requirement.requirementDescription_ch}
                    onChange={(e) =>
                      handleEntryRequirementChange(
                        index,
                        "requirementDescription_ch",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />

                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Entry Requirement (Simplified Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={requirement.requirementDescription_sch}
                    onChange={(e) =>
                      handleEntryRequirementChange(
                        index,
                        "requirementDescription_sch",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                </Box>

                <Box sx={{ mt: 4 }}>
                  {/* <IconButton > */}
                  {/* <Delete /> */}
                  <Button
                    component="span"
                    onClick={() => handleDeleteEntryRequirement(index)}
                    aria-label="delete"
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      mt: 2,
                      width: "fit-content",
                      backgroundColor: "#f50057",
                      "&:hover": { backgroundColor: "#f50057" },
                    }}
                  >
                    Delete
                  </Button>
                  {/* </IconButton> */}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {/* <Box sx={{width:'100%',overflow:'auto'}}>
            <Button onClick={addRow}>Add Row</Button>
                <Button onClick={addColumn}>Add Column</Button>
                <TableContainer component={Paper}>
                    <Table>
                    <TableHead>
                        <TableRow>
                        {rows[0].map((cell, index) => (
                            <TableCell key={index}>
                            <Button onClick={() => deleteColumn(index)}>Delete</Button>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {row.map((cell, columnIndex) => (
                            <TableCell key={columnIndex}>
                                <input
                                type="text"
                                value={cell}
                                onChange={(e) => {
                                    const updatedRows = [...rows];
                                    updatedRows[rowIndex][columnIndex] = e.target.value;
                                    setRows(updatedRows);
                                }}
                                />
                            </TableCell>
                            ))}
                            <TableCell>
                            <Button onClick={() => deleteRow(rowIndex)}>Delete</Button>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Box> */}
        {/* {value && <Slate editor={editor} value={value ? value : ''} onChange={newValue => setValue(newValue)}>
      <Editable />
    </Slate>} */}
        <Box sx={{ my: 2 }}>
          {console.log("rich ", rich)}
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "18px",
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Tuition Fees Introduction(Engish)
          </RobotoTypo>
          <CKEditor
            editor={ClassicEditor}
            data={
              rich?.fees_info_en && rich?.fees_info_en == ""
                ? ""
                : rich?.fees_info_en
            }
            onChange={handleChangeFeesEn}
          />
          {
            <p
              dangerouslySetInnerHTML={{
                __html:
                  rich?.fees_info_en && rich?.fees_info_en == ""
                    ? ""
                    : rich?.fees_info_en,
              }}
            />
          }
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "18px",
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Tuition Fees Introduction(Chinese)
          </RobotoTypo>
          <CKEditor
            editor={ClassicEditor}
            data={
              rich?.fees_info_ch && rich?.fees_info_ch == ""
                ? ""
                : rich?.fees_info_ch
            }
            onChange={handleChangeFeesCh}
          />
          {
            <p
              dangerouslySetInnerHTML={{
                __html:
                  rich?.fees_info_ch && rich?.fees_info_ch == ""
                    ? ""
                    : rich?.fees_info_ch,
              }}
            />
          }

          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "18px",
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Tuition Fees Introduction(Simplified Chinese)
          </RobotoTypo>
          <CKEditor
            editor={ClassicEditor}
            data={
              rich?.fees_info_sch && rich?.fees_info_sch == ""
                ? ""
                : rich?.fees_info_sch
            }
            onChange={handleChangeFeesSch}
          />
          {
            <p
              dangerouslySetInnerHTML={{
                __html:
                  rich?.fees_info_sch && rich?.fees_info_sch == ""
                    ? ""
                    : rich?.fees_info_sch,
              }}
            />
          }
        </Box>
        <Box sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Latitude
            </RobotoTypo>
            <TextField
              name="lat"
              value={data.lat}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Longtitude
            </RobotoTypo>
            <TextField
              name="lon"
              value={data.lon}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box mb={1}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",

              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Category
          </RobotoTypo>
          <Select
            name="category_id"
            value={multiValue}
            multiple
            onChange={(e) => {
              setMultiValue(e.target.value);
            }}
            sx={{ width: "300px" }}
          >
            <MenuItem value={1}>psychology</MenuItem>
            <MenuItem value={2}>mass-media-communication</MenuItem>
            <MenuItem value={3}>information-engineer</MenuItem>
            <MenuItem value={4}>art-design</MenuItem>
            <MenuItem value={5}>business-marketing</MenuItem>
            <MenuItem value={6}>medicine-biology</MenuItem>
          </Select>
        </Box>
        <Select sx={{ width: "300px", textTransform: "capitalize" }}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
        {/* Fees Structure */}
        <Divider />
        <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 2}}>
        <Box width="45%">
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Application Fee
          </RobotoTypo>
          <TextField
            value={mandatoryApplicationFee}
            variant="outlined"
            sx={{
              width: "100%",
              // borderColor: formErrors.fees?.[index]?.amount_en
              //   ? "red"
              //   : undefined, // Highlight in red if there's an error
            }}
            error={feeError} // Apply error styling
            // helperText={
            //   feeError ? "Application fee should not be empty" : ""
            // }
            // onChange={(e) => handleApplicationFee(index, "amount_en", e)}

            onChange={(e) => handleApplicationFeeChange(e)}
          />
        </Box>
        
        <Box width="45%">
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Consultancy Fee
          </RobotoTypo>
          <TextField
            value={mandatoryConsultancyFee}
            type='number'
            variant="outlined"
            sx={{
              width: "100%",
              // borderColor: formErrors.fees?.[index]?.amount_en
              //   ? "red"
              //   : undefined, // Highlight in red if there's an error
              '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '& input[type=number]': {
                '-moz-appearance': 'textfield', // Firefox
              },
            }}
            // error={feeError} // Apply error styling
            // helperText={
            //   feeError ? "Consultancy fee must be greater than 0" : ""
            // }
            // onChange={(e) => handleApplicationFee(index, "amount_en", e)}
            inputProps={{
              onWheel: (e) => e.target.blur(), // Prevent scroll wheel from changing value
            }}
            onChange={(e) => handleConsultancyFeeChange(e)}
          />
        </Box>
        </Box>

        <Box
          sx={{ border: "1px solid #E0E0E0" }}
          display={"flex"}
          my="20px"
          px="20px"
          py="20px"
          borderRadius="20px"
          flexDirection={"column"}
          gap="30px"
        >
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
            }}
          >
            Application Services
          </RobotoTypo>

          {application_fees.map((fee, index) => (
            <Stack
              sx={{ borderTop: "2px solid #E0E0E0" }}
              key={index}
              pt="20px"
              gap="20px"
            >
              <Box display="flex" justifyContent="space-between">
                <Box width="45%">
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 20 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Service Title - English
                  </RobotoTypo>
                  <TextField
                    value={fee.title_en}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(e) => handleApplicationFee(index, "title_en", e)}
                    required={fee.mandatory}
                    error={formErrors.fees?.[index]?.title_en} // Check if error exists for this field
                    helperText={formErrors.fees?.[index]?.title_en || ""} // Display error message
                  />
                </Box>
                <Box width="45%">
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 20 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Service Title - Chinese
                  </RobotoTypo>
                  <TextField
                    value={fee.title_ch}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(e) => handleApplicationFee(index, "title_ch", e)}
                    required={fee.mandatory}
                    error={formErrors.fees?.[index]?.title_ch} // Check if error exists for this field
                    helperText={formErrors.fees?.[index]?.title_ch || ""} // Display error message
                  />
                </Box>
              </Box>
              <Box width="45%">
                <RobotoTypo
                  sx={{
                    textAlign: "center",
                    width: "fit-content",
                    fontSize: { xs: 18, sm: 20 },
                    color: "#003B5C",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Service Title - Simplified Chinese
                </RobotoTypo>
                <TextField
                  value={fee.title_sch}
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={(e) => handleApplicationFee(index, "title_sch", e)}
                  required={fee.mandatory}
                  error={formErrors.fees?.[index]?.title_sch} // Check if error exists for this field
                  helperText={formErrors.fees?.[index]?.title_sch || ""} // Display error message
                />
              </Box>
              <Box width="45%">
                <RobotoTypo
                  sx={{
                    textAlign: "center",
                    width: "fit-content",
                    fontSize: { xs: 18, sm: 20 },
                    color: "#003B5C",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Service Fee
                </RobotoTypo>
                <TextField
                  value={fee.amount_en}
                  variant="outlined"
                  sx={{
                    width: "100%",
                    borderColor: formErrors.fees?.[index]?.amount_en
                      ? "red"
                      : undefined, // Highlight in red if there's an error
                  }}
                  onChange={(e) => handleApplicationFee(index, "amount_en", e)}
                  required={fee.mandatory}
                  error={formErrors.fees?.[index]?.amount_en} // Check if error exists for this field
                  helperText={formErrors.fees?.[index]?.amount_en || ""} // Display error message
                />
              </Box>

              <Box
                width="45%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Checkbox
                  checked={fee.mandatory}
                  onChange={() => toggleMandatory(index)}
                />
                <RobotoTypo
                  sx={{
                    textAlign: "center",
                    width: "fit-content",
                    fontSize: { xs: 18, sm: 20 },
                    color: "#003B5C",
                    fontWeight: 700,
                  }}
                >
                  Make This Fees Details Mandatory?
                </RobotoTypo>
              </Box>

              <Box width="45%">
                <RobotoTypo
                  sx={{
                    textAlign: "center",
                    width: "fit-content",
                    fontSize: { xs: 18, sm: 20 },
                    color: "#003B5C",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  URL
                </RobotoTypo>
                <TextField
                  value={fee.url}
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={(e) => handleApplicationFee(index, "url", e)}
                  required={fee.mandatory}
                  error={formErrors.fees?.[index]?.url} // Check if error exists for this field
                  helperText={formErrors.fees?.[index]?.url || ""} // Display error message
                />
              </Box>

              {index > 0 && (
                <Button
                  component="span"
                  onClick={() => handleDeleteApplicationFee(index)}
                  aria-label="delete"
                  variant="contained"
                  sx={{
                    mt: 2,
                    width: "fit-content",
                    backgroundColor: "#f50057",
                    "&:hover": { backgroundColor: "#f50057" },
                  }}
                >
                  Delete
                </Button>
              )}
            </Stack>
          ))}

          <Box
            display={"flex"}
            width="100%"
            sx={{ borderTop: "2px solid #E0E0E0", pt: "20px" }}
            justifyContent={"flex-end"}
          >
            <Button
              sx={{
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
              onClick={() => {
                setApplication_fees((prevFees) => [
                  ...prevFees,
                  {
                    title_en: "",
                    title_ch: "",
                    amount_en: "",
                    amount_ch: "",
                    mandatory: false,
                    url: "",
                  },
                ]);
              }}
              variant="contained"
            >
              Add
            </Button>
          </Box>
        </Box>

        <Button
          component="span"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 2,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          {schoolId ? "UPDATE" : "Add"}
        </Button>
      </Box>
    </Admin>
  );
}
