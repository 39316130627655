import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import SellIcon from "@mui/icons-material/Sell";
import LaunchIcon from "@mui/icons-material/Launch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RobotoTypo } from "../../../utils/Typographies";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PaymentDialog = ({
  payLater,
  paymentData,
  payNow,
  open,
  onClose,
  total,
  setTotal,
  payNowLoading,
  payLaterLoading,
  setPaymentData,
  selectedSchools,
  moreApplicationPrice,
  setSelectedSchools,
  totalPrice,
  setTotalPrice,
  discountAmount,
  setDiscountAmount,
  discountPercentage,
  setDiscountPercentage,
  totalAmount,
  setTotalAmount,
  finalAmount,
  setFinalAmount,
  selectedServices,
  setSelectedServices,
  serviceTotalPrice,
  setServiceTotalPrice
}) => {
  
  const [next, setNext] = useState(false);
  const discountDetails = {
    discountAmount,
    discountPercentage,
    totalAmount,
    finalAmount,
  }

  const location = useLocation()
  const {t} = useTranslation()
  const language = useSelector((state) => state.language.language)

  console.log(discountDetails)
  
  const handleCheckboxChange = (index) => {
    const newPaymentData = paymentData.map((row, i) => {
      if (i === index) {
        return { ...row, isChecked: !row.isChecked };
      }
      return row;
    });
    setPaymentData(newPaymentData);
  };

  useEffect(() => {
      // setTotalPrice(parseFloat(total) + parseFloat(moreApplicationPrice))
      if(moreApplicationPrice > -1 || serviceTotalPrice > -1){
        const value = (parseFloat(moreApplicationPrice) + parseFloat(serviceTotalPrice)).toFixed(2)
        setTotalPrice(parseFloat(value))
      }
  }, [selectedSchools, total, moreApplicationPrice, serviceTotalPrice, selectedServices])

  
  const handleCheckboxChanged = (dat) => {
    setSelectedSchools((prevSelected) => {
      const existingItem = prevSelected.find((data) => data._id === dat._id);
  
      if (existingItem) {
        // If the item is mandatory, do not remove it
        if (existingItem.mandatory) {
          return prevSelected;
        }
        return prevSelected.filter((data) => data._id !== dat._id);
      } else {
        const parsedServices =
          Array.isArray(dat.services) && typeof dat.services[0] === "string"
            ? JSON.parse(dat.services[0])
            : dat.services;
  
        return [...prevSelected, { ...dat, services: parsedServices }];
      }
    });
  };
  
  const handleCheckboxChangeService = (dat) => {
    setSelectedServices((prevSelected) => {
      const existingItem = prevSelected.find((data) => data._id === dat._id);
  
      if (existingItem) {
        
        if (existingItem.mandatory) {
          return prevSelected;
        }
        return prevSelected.filter((data) => data._id !== dat._id);
      } else {
  
        return [...prevSelected, dat];
      }
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return; // Prevent closing on backdrop click
          }
          onClose(); // Allow other close actions
        }}
      >
        {!next ? (
          <Box sx={{ width: "100%", overflowX: "hidden" }}>
            <DialogTitle sx={{ borderBottom: "2px solid #eceef3" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                  {t("checkout")}
                </Typography>
                <Close
                  onClick={() => {
                    onClose();
                    setNext(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </DialogTitle>
            <Stack px={"20px"} gap="20px" py="20px">
              
              {/* <Typography style={{ fontSize: "18px", fontWeight: 400 }}>
                Selected Services
              </Typography>
              <Box>
                {/* Table for rows with isReadable = true 
                <Table
                  style={{
                    borderCollapse: "separate",
                    width: "100%",
                    backgroundColor: "#F1FEFF",
                    color: "#177D84",
                    border: "2px solid #68C4CA",
                    borderRadius: "8px",
                    marginBottom: 4,
                    paddingInline: "20px",
                  }}
                >
                  <TableBody>
                    {paymentData
                      .filter((row) => row?.isReadable)
                      .map((row, index) => (
                        <TableRow key={`readable-${index}`}>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            <Checkbox
                              checked={row.isChecked}
                              disabled={row.isReadable}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#A4D0D3", // Add this line to set the checked color
                                },
                              }}
                              onChange={() => handleCheckboxChange(row?.id)}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            {row?.url ? (
                              <a
                                href={row?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontFamily: "Roboto, sans-serif",
                                  color: "#177D84",
                                  textDecoration: "none",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                }}
                              >
                                <LaunchIcon
                                  style={{ marginRight: 8, fontSize: 18 }}
                                />{" "}
                                {row.label}
                              </a>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  color: "#177D84",
                                  fontWeight: "400",
                                }}
                              >
                                {row.label}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "16px", color: "#177D84" }}
                            >
                              S$ {isNaN(row.charge) ? 0 : row.charge} /-
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box> */}

              {/* Table for rows with isReadable = false */}
              {/* <Box>
                <Table
                  sx={{
                    backgroundColor: "#FFFFFF", // Default background for non-readable rows
                    color: "#242424",
                    border: "2px solid #BABABA",
                    borderRadius: "8px",

                    borderCollapse: "separate",

                    paddingInline: "20px",
                  }}
                >
                  <TableBody>
                    {paymentData
                      .filter((row) => !row?.isReadable)
                      .map((row, index) => (
                        <TableRow key={`non-readable-${index}`}>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            <Checkbox
                              checked={row.isChecked}
                              disabled={row.isReadable}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#242424", // Add this line to set the checked color
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "#242424", // Border color when not checked
                                },
                                "&.Mui-checked .MuiSvgIcon-root": {
                                  color: "#242424", // Border color when checked
                                },
                              }}
                              onChange={() => handleCheckboxChange(row?.id)}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            {row?.url ? (
                              <a
                                href={row?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontFamily: "Roboto, sans-serif",
                                  color: "#242424",
                                  textDecoration: "none",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                }}
                              >
                                <LaunchIcon
                                  style={{ marginRight: 8, fontSize: 18 }}
                                />{" "}
                                {row.label}
                              </a>
                            ) : (
                              <Typography sx={{ fontSize: "16px" }}>
                                {row.label}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            <Typography sx={{ fontSize: "16px" }}>
                              S$ {isNaN(row.charge) ? 0 : row.charge}/-
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box> */}
              
              {selectedSchools?.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 2, boxShadow : '0px 4px 36px 0px rgba(0, 0, 0, 0.15)' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("selected")}</b></TableCell>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("school_name")}</b></TableCell>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("application_fee")}</b></TableCell>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("consultation_fee")}</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedSchools?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{fontSize : '1.05rem'}}>
                        <Checkbox
                          checked={selectedSchools?.some((data) => data._id === data?._id)}
                         disabled={data?.isReadable}
                         sx={{
                           "&.Mui-checked": {
                             color: data?.mandatory ? "#A4D0D3" : '#19c2d8', // Add this line to set the checked color
                           },
                         }}
                        onChange={() => {handleCheckboxChanged(data)}}
                       />
                        </TableCell>
                        <TableCell sx={{fontSize : '1.05rem'}}>{data?.[language]?.school_name}</TableCell>
                        <TableCell sx={{fontSize : '1.05rem'}}>S$ {data?.application_fee_amount}</TableCell>
                        <TableCell sx={{fontSize : '1.05rem'}}>S$ {data?.consultation_fee_amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              )}

                {selectedServices?.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 2, boxShadow : '0px 4px 36px 0px rgba(0, 0, 0, 0.15)' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("selected")}</b></TableCell>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("service_name")}</b></TableCell>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("fees")}</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedServices?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{fontSize : '1.05rem'}}>
                        <Checkbox
                          checked={selectedServices?.some((data) => data._id === data?._id)}
                         disabled={data?.isReadable}
                         sx={{
                           "&.Mui-checked": {
                             color: data?.mandatory ? "#A4D0D3" : '#19c2d8', // Add this line to set the checked color
                           },
                         }}
                        onChange={() => {handleCheckboxChangeService(data)}}
                       />
                        </TableCell>
                        <TableCell sx={{fontSize : '1.05rem'}}>{data?.multi_language_names?.[language]?.name}</TableCell>
                        <TableCell sx={{fontSize : '1.05rem'}}>S$ {data?.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              )}
              


              <Box display="flex" justifyContent={"space-between"}>
                <RobotoTypo
                  sx={{ fontWeight: "700", color: "#177D84", fontSize: "20px" }}
                >
                  {t("total_amount")}
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "700", color: "#177D84", fontSize: "20px" }}
                >
                  S$ {totalPrice} /-
                </RobotoTypo>
              </Box>
            </Stack>

            <Box
              display="flex"
              justifyContent={"flex-end"}
              sx={{ padding: "16px" }}
            >
              <Button
                variant="contained"
                disabled={totalPrice === 0 || selectedSchools?.length === 0}
                onClick={() => {
                  if(totalPrice > 0 && selectedSchools?.length > 0){
                    setNext(true);
                  }
                }}
                sx={{
                  background: "#177D84",
                  borderRadius: "12px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  "&:hover": {
                    background: "#145E63", // Slightly darker shade for hover
                  },
                }}
              >
                {t("confirm_payment")}
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <DialogTitle sx={{ borderBottom: "2px solid #eceef3" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                  {" "}
                  {t("confirm_application")}
                </Typography>
                <Close
                  onClick={() => {
                    onClose();
                    setNext(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </DialogTitle>
            <DialogContent style={{ padding: "30px 24px" }}>
              <Typography>
                {t("please_Confirm_the_Application_Using_Pay_Now_or_Pay_Later")}
              </Typography>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 24px",
                borderTop: "2px solid #eceef3",
              }}
            >
              <Button
                onClick={() => {
                  payLater(paymentData, total, selectedSchools, selectedServices);
                  onClose();
                  setNext(false);
                }}
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#B7CFDA",
                  padding: "8px 14px",
                  color: "#0E5E84",
                }}
                disabled={payLaterLoading}
              >
                {t("pay_later")}
              </Button>
              <Button
                onClick={() => {
                  payNow(paymentData, total, selectedSchools, discountDetails, selectedServices);
                  onClose();
                  setNext(false);
                }}
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  padding: "8px 14px",
                  color: "#FFFFFF",
                }}
                disabled={payNowLoading}
              >
                {t("pay_now")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default PaymentDialog;
