import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SchoolHeader from "../components/StudyingAboard/SchoolInfo/SchoolHeader";
import SchoolIntro from "../components/StudyingAboard/SchoolInfo/SchoolIntro";
import StudentApplicationProcess from "../components/Service/StudentPassApplication/StudentApplicationProcess";
import StudentPassServiceIntro from "../components/Service/StudentPassApplication/StudentPassServiceIntro";
import Share from "../components/Share";
import SideOverlap from "./sideOverlap";
import axios from "axios";
import { useSelector } from "react-redux";
import SchoolBenefit from "../components/StudyingAboard/SchoolInfo/SchoolBenefit";
import PartnerUniversity from "../components/StudyingAboard/SchoolInfo/PartnerUniversity";
import SchoolPictures from "../components/StudyingAboard/SchoolInfo/SchoolPictures";
import SchoolAccordion from "../components/StudyingAboard/SchoolInfo/SchoolAccorion";
import SchoolAdmissionInfo from "../components/StudyingAboard/SchoolInfo/SchoolAdmissionInfo";
import SchoolFeesInfo from "../components/StudyingAboard/SchoolInfo/SchoolFeesInfo";
import { useParams, useNavigate } from "react-router-dom";
import SchoolMap from "../components/StudyingAboard/SchoolInfo/SchoolMap";
import ApplicationProcess from "../components/LandingPage/ApplicationProcess";

export default function SchoolInfo() {
  const [bannerData, setBannerData] = useState([]);
  const [data, setData] = useState([]);
  const language = useSelector((state) => state.language.language);
  let { schoolId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSchool = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getSchool/` + schoolId
      );

      if (response.status == 200) {
        console.log("rs ", response.data);
        setBannerData(response.data);
        getData(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSchool();
  }, []);

  const getData = (data) => {
    console.log("lang data ", data);
    //  const languageData = data?.map((item) => {
    let languageData;
    if (language == "en") {
      languageData = data.en;
    } else if (language == "ch") {
      languageData = data.ch;
    } else {
      languageData = data?.sch || data?.en;
    }
    // })
    setData(languageData);
  };

  useEffect(() => {
    if (bannerData != [] && bannerData?.length != 0) {
      console.log("bannerData ", bannerData);
      getData(bannerData);
    }
  }, [language]);
  console.log("zdata ", data);
  return (
    <>
      <Header />
      {data != "" && (
        <SchoolHeader
          bannerData={
            language == "ch" || language == "sch"
              ? bannerData.en.school_name
              : ""
          }
          data={data}
        />
      )}
     <ApplicationProcess />
      {data?.description != "" && <SchoolIntro data={data} />}
      
      {data?.benefits != "" && <SchoolBenefit data={data} />}
      {data?.school_pitures?.length != 0 && <SchoolPictures data={data} />}
      {data?.partnerUniversity?.length != 0 && (
        <PartnerUniversity data={data} />
      )}
      {data?.courses_detail?.length != 0 && <SchoolAccordion data={data} />}
      {data?.admission_info?.length != 0 && <SchoolAdmissionInfo data={data} />}
      {data != "" && <SchoolFeesInfo data={data} schoolId={schoolId} />}
      {data != "" && <SchoolMap data={data} />}
      <Share />
      <Footer />
      {window.location.pathname != "/admin/login" &&
        window.location.pathname != "/admin/admindashboard" && <SideOverlap />}
    </>
  );
}
