import {
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RobotoTypo } from "../../../utils/Typographies";

const TermsAndConditions = ({
  setTermsChecked,
  termsChecked,
  termsError,
  setTermsError,
}) => {
  const {t} = useTranslation()
  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
    setTermsError(false); // Clear error when the checkbox is checked
  };

  return (
    <div style={{ padding: "20px" }}>
      <Stack
        sx={{
          padding: "18px 30px",
          backgroundColor: "#e1e1e1",
          mb: "30px",
        }}
      >
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          {t("terms_and_conditions")}
        </RobotoTypo>
      </Stack>
      <Stack flexDirection={"row"} px={"20px"} alignItems={"center"} gap="2px">
        <Checkbox
          color="primary"
          checked={termsChecked}
          onChange={handleCheckboxChange}
        />

        <Typography>
          {t("i_agree_to_the")}{" "}
          <Link
            href="/terms"
            target="_blank"
            sx={{ cursor: "pointer" }}
            rel="noopener"
          >
            {t("terms_and_conditions")}
          </Link>{" "}
          {t("and")}{" "}
          <Link
            href="/privacyNotice"
            target="_blank"
            sx={{ cursor: "pointer" }}
            rel="noopener"
          >
            {t("privacy_notice")}
          </Link>
        </Typography>
      </Stack>
      {termsError && (
        <Typography px={"28px"} color="error" variant="body2">
          {t("please_accept_the_terms_and_conditions_to_proceed")}
        </Typography>
      )}
    </div>
  );
};

export default TermsAndConditions;
