import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Typography,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Stack,
  Pagination,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SuccessIcon from "../../../assets/payment/Vector.svg";
import Admin from "../Dashboard";

export default function StudentList() {
  const navigate = useNavigate();
  const [StudentsList, setStudentsList] = useState([]);
  const [filters, setFilters] = useState({
    studentName: "",
    category: "",
  });

  // Pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getCarousel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getAllStudent?student_name=${filters?.studentName}&category=${filters?.category}`
      );
      console.log("response get ", response);
      if (response.status === 200) {
        setStudentsList(response.data);
      } else if (response.status === 403) {
        navigate("/admin/login");
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    getCarousel();
  }, [filters]);

  const handleEdit = (id) => {
    navigate(`/admin/studentDetails/${id}`);
  };

  const paymentIcon = (status) => {
    switch (status) {
      case "successful":
        return (
          <img
            style={{ width: "20px", height: "20px" }}
            src={SuccessIcon}
            alt="payment-indicator"
          />
        );
      case "pending":
        return <PendingActionsIcon sx={{ color: "#FF7900", fontSize: 20 }} />;
      default:
        return <SmsFailedIcon sx={{ color: "red", fontSize: 20 }} />;
    }
  };

  const categories = ["paid", "unpaid"];

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <Admin>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Box display="flex" gap="20px" my="30px" alignItems={"center"}>
            <FormControl sx={{ width: "30%" }}>
              <Typography>Search by Student Name</Typography>
              <TextField
                value={filters?.studentName}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    studentName: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl sx={{ width: "30%" }}>
              <Typography>Category</Typography>
              <Select
                value={filters?.category}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    category: e.target.value,
                  }))
                }
                size="small"
              >
                {categories.map((val) => (
                  <MenuItem
                    key={val}
                    value={val}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={() => {
                setFilters({
                  studentName: "",
                  category: "",
                });
                setPage(1);
              }}
              sx={{ alignSelf: "flex-end" }}
            >
              Reset
            </Button>
          </Box>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Sno</TableCell>
                <TableCell align="center">Student Name</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {StudentsList.slice(
                Math.max(0, (page - 1) * rowsPerPage),
                page * rowsPerPage
              ).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {Math.max(0, (page - 1) * rowsPerPage) + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {row?.StudentDetails?.personalDetails?.name || row?.StudentDetails?.personalDetails?.student_name||"-"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.StudentDetails?.payment_details ? "Paid" : "Unpaid"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.StudentDetails?.payment_details ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        {paymentIcon(
                          row?.StudentDetails?.payment_details?.status
                        )}
                        <span>
                          {row?.StudentDetails?.payment_details?.status}
                        </span>
                      </div>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => handleEdit(row._id)}
                      aria-label="edit"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack spacing={2} alignItems="center" mt={2}>
          <Pagination
            count={Math.ceil(StudentsList.length / rowsPerPage)} // Total pages
            page={page}
            onChange={handlePageChange}
            size="small"
          />
        </Stack>
      </Box>
    </Admin>
  );
}
