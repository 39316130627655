import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
  Drawer,
} from "@mui/material";
import { Box } from "@mui/system";
import { Outlet, useParams } from "react-router-dom";
import { BaskerTypo, RobotoTypo } from "../../utils/Typographies";
import { useNavigate } from "react-router-dom";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/slice";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../utils/Logo";

function StudentDashboard() {
  const userData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";
  const userToken = localStorage.getItem("student_token");
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const studentToken = localStorage.getItem("student_token");

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language.language);
  const triggerOmise = useSelector((state) => state.language.triggerOmise);
  console.log(triggerOmise);
  const [lang, setLang] = useState(language);

  console.log("userData ", userData);
  const techHead = userData?.name?.charAt(0);
  const boxStyle = {
    "& #nav-circle": {
      display: "none",
      textAlign: "center",
      transition: "display 0.5s",
    },
    "&:hover #nav-circle": {
      display: "block",
      position: "absolute",
      left: "25px",
    },
  };

  const langs = [
    {
      lang: "ch",
      title: "繁",
    },
    {
      lang: "sch",
      title: "简",
    },
    {
      lang: "en",
      title: "EN",
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [SideBar, setSideBar] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    if (item.lang == "ch" || item.lang == "en" || item.lang == "sch") {
      i18n.changeLanguage(item.lang);
      setLang(item.lang);
      dispatch(actions.toggleLanguage(item.lang));
      setAnchorEl(null);
    }
    setAnchorEl(null);
  };
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://cdn.omise.co/omise.js";
  //   script.async = true;
  //   script.onload = () => {
  //     if (window.OmiseCard) {
  //       window.OmiseCard.configure({
  //         publicKey: "pkey_test_60fen2ih2w9ofin05qf",
  //       });
  //     }
  //   };
  //   document.body.appendChild(script);
  // }, []);

  useEffect(() => {}, [triggerOmise]);

  const navItems = [
    {
      name: `${t("studentDashboard.profile")}`,
      route: `/student/profile`,
      icon: <PermIdentityOutlinedIcon />,
    },
    {
      name: `${t("studentDashboard.applications")}`,
      route: `/student/applications`,
      icon: <AssignmentOutlinedIcon />,
    },
  ];
  const [currentPath, setCurrentPath] = useState(
    () => localStorage.getItem("currentPath") || `/student/profile`
  );

  useEffect(() => {
    navigate(currentPath);
  }, []);

  //   useEffect(() => {
  //     if (localStorage.getItem("token") == null) {
  //       navigate("/login");
  //     }
  //   }, []);

  useEffect(() => {
    if (userToken) {
      localStorage.setItem("currentPath", currentPath);
    } else {
      navigate("/");
    }
  }, [currentPath, userToken]);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Drawer anchor="right" open={SideBar} onClose={() => setSideBar(false)}>
        <Box
          sx={{
            borderRight: "1px solid #e5e5e5",
            height: "100%",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {navItems.map((data, i) => (
            <Box
              key={i}
              sx={{
                alignItems: "center",
                display: "flex",
                transition: "all 300ms ease-in-out",
                borderLeft:
                  currentPath === data.route ? "2px solid #167d84" : "",
                background: currentPath === data.route ? "#f2f2f2" : "",
                color: "",
                p: 1.5,
                ":hover": { cursor: "pointer" },
              }}
              onClick={() => {
                navigate(data.route);
                setCurrentPath(data.route);
              }}
            >
              <RobotoTypo
                sx={{
                  width: "20%",
                  color: currentPath === data.route ? "" : "#656565",
                }}
              >
                {data.icon}
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  color: currentPath === data.route ? "black" : "#656565",
                }}
              >
                {data.name}
              </RobotoTypo>
            </Box>
          ))}
        </Box>
      </Drawer>
      <Box
        sx={{
          backgroundColor: "#167d84",
          width: "100%",
          p: 0,
          m: 0,
          borderBottom: "2px solid #66c0b7",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <Logo />
          <BaskerTypo
            sx={{
              px: 2,
              pb: 2,
              lineHeight: 1,
              color: "white",
              display: { xs: "none", md: "block" },
            }}
          >
            新加坡留學規劃管家
          </BaskerTypo>
        </Box>
        <Box display="flex" justifyContent={"flex-end"}>
          {studentToken && (
            <Box sx={{ flexGrow: 1 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "white",
                    color: "#003b5c",
                  }}
                >
                  {techHead}
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={() => logout()}>
                  {" "}
                  <RobotoTypo textAlign="center">Logout</RobotoTypo>{" "}
                </MenuItem>
              </Menu>
            </Box>
          )}
          <Box sx={{ mx: 2 }}>
            <div>
              <Avatar
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  bgcolor: "#FFC424",
                  cursor: "pointer",
                  textTransform: "uppercase",
                }}
              >
                {langs.filter((data) => data.lang == lang)[0].title}
              </Avatar>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {langs.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleClose(item)}
                    sx={{ textTransform: "uppercase" }}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Box>
          <IconButton
            onClick={() => setSideBar(true)}
            sx={{ display: { xs: "block", lg: "none" } }}
          >
            <MenuIcon
              style={{
                color: "#FFC424",
                fontSize: "30px",
              }}
            />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ width: "100%", display: "flex", p: 0, m: 0 }}>
        <Box
          sx={{
            borderRight: "1px solid #e5e5e5",
            height: "100%",
            width: "17%",

            overflowY: "auto",
          }}
          display={{ xs: "none", sm: "initial" }}
        >
          {navItems.map((data, i) => (
            <Box
              key={i}
              sx={{
                alignItems: "center",
                display: "flex",
                transition: "all 300ms ease-in-out",
                borderLeft:
                  currentPath === data.route ? "2px solid #167d84" : "",
                background: currentPath === data.route ? "#f2f2f2" : "",
                color: "",
                p: 1.5,
                ":hover": { cursor: "pointer" },
              }}
              onClick={() => {
                navigate(data.route);
                setCurrentPath(data.route);
              }}
            >
              <RobotoTypo
                sx={{
                  width: "20%",
                  color: currentPath === data.route ? "" : "#656565",
                }}
              >
                {data.icon}
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  color: currentPath === data.route ? "black" : "#656565",
                }}
              >
                {data.name}
              </RobotoTypo>
            </Box>
          ))}
        </Box>

        <Box sx={{ p: { xs: 0, sm: 2 }, width: { xs: "100%", sm: "83%" } }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default StudentDashboard;
