import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "../redux/slice";
import PaymentSuccess from "./PaymentSuccess";

const PaymentVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const payment_id = localStorage.getItem("payment_id");
  console.log(payment_id)
  const [status, setStatus] = useState(null);
  const [data, setData] = useState(null);

  const GetPaymentStatus = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getPaymentDetailsByID`,
        {
          payment_id,
        },

        {
          headers: {
            Authorization: localStorage.getItem("student_token"),
          },
        }
      );
      console.log("response", response?.data?.details?.payment?.status);
      if (response.status == 200) {
        console.log("paymentStatus", response);
        localStorage.removeItem("payment_id");
        setData(response?.data);
        if (
          response?.data?.details?.payment?.paid !== undefined &&
          response?.data?.details?.payment?.paid !== "" &&
          response?.data?.details?.payment?.paid !== null &&
          response?.data?.details?.payment?.status !== undefined &&
          response?.data?.details?.payment?.status !== "" &&
          response?.data?.details?.payment?.status !== null
        ) {
          if (
            response?.data?.details?.payment?.paid &&
            response?.data?.details?.payment?.status === "successful"
          ) {
            setStatus("successful");
            return;
          }
          if (
            !response?.data?.details?.payment?.paid &&
            response?.data?.details?.payment?.status === "pending"
          ) {
            setStatus("pending");
            return;
          } else {
            setStatus("failed");
            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if(payment_id?.length > 0){
      GetPaymentStatus();
    }
    
  }, []);

  useEffect(() => {}, [status]);
  if (status != null && data != null) {
    return <PaymentSuccess status={status} data={data} />;
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh" // Full viewport height
    >
      <CircularProgress />
    </Box>
  );
};

export default PaymentVerification;
