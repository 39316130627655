import React,{useState, useEffect} from 'react'
import Header from '../../../Header'
import Footer from '../../../Footer'
import HighSchoolHeader from './HighSchoolHeader'
import HighSchoolBody from './HighSchoolBody'
import Share from '../../../Share'
import HighSchoolRecommendation from './HighSchoolRecommendation'
import axios from 'axios';
import { useSelector } from 'react-redux';
import SideOverlap from '../../../../pages/sideOverlap'
import LanguageCourseRecommendation from '../../LanguageCourse/LanguageCourseRecommendation'

export default function HighSchool() {
  const [schoolList, setSchoolList] = useState([])
  const [data, setData] = useState([])
  const language = useSelector((state) => state.language.language);

  const getSecondarySchools = async() => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getSecondarySchools`);
          console.log('getSecondarySchools response ',response)
          if(response.status == 200) {
            setSchoolList(response?.data)
            getData(Array.isArray(response?.data) ? response?.data : [response.data])
          }
    } catch(error) {

    }
  }

  const getData = (data) => {
    console.log('lang data ',data)
      //  const languageData = data?.map((item) => {
        let languageData
        if(language == 'en') {
           languageData = data?.length !=0 && data.map((item) => {
            return {
            id: item._id,
            data: item.en ,
            isShow: item?.isShow
            }
           })
        } else {
            languageData = data?.length !=0 && data.map((item) => {
                return {
                id: item._id,
                data_en: item.en,
                data: item.ch ,
                isShow: item?.isShow,
                }
               })
        }
      // })
      setData(languageData)
  }

  useEffect(() => {
    getSecondarySchools()
  },[])

  useEffect(() => {
    if(schoolList?.length !=0 ) {
        console.log('secondary daad ',schoolList)
      getData(schoolList)
    }
  },[language])

  console.log('datas ',data)

  return (
    <>
    <Header />
      <HighSchoolHeader />
      <HighSchoolBody />
      <Share />
      {schoolList?.length > 0 && (
        <LanguageCourseRecommendation data={schoolList} language={language}/>
      )}
      <Footer />
      <SideOverlap />
    </>
  )
}
