// import React, { useState, useEffect } from "react";
// import {
//   Typography,
//   Box,
//   Divider,
//   Button,
//   TableContainer,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Paper,
//   Stack,
//   IconButton,
//   TablePagination
// } from "@mui/material";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import moment from "moment/moment";

// export default function Applications() {
// const { id } = useParams()
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [totalItems, setTotalItems] = useState(0);
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();

//   const [studentData, setStudentData] = useState([]);

//   const getStudentApplications = async () => {
//     setLoading(true)
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/getStudentDetails/${id}?page=${
//           page + 1
//         }&limit=${rowsPerPage}`
//       );
//       console.log(response?.data);
//       setTotalItems(response.data.total || 0)
//       setStudentData(response?.data?.data || []);
//       setLoading(false)
//     } catch (error) {
//       console.log(error);
//       setLoading(false)
//     }
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   useEffect(() => {
//     getStudentApplications();
//   }, [id, page, rowsPerPage]);

//   return (
//     <>

//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { RobotoTypo } from "../../utils/Typographies";
import { Box, Stack } from "@mui/system";
import { Button, MenuItem, Pagination, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slice";
import { useTranslation } from "react-i18next";

const Applications = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [totalItems, setTotalItems] = useState(0); // Total count of items
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language)

  const [prevStudentDataLength, setPrevStudentDataLength] = useState(0);

  const dispatch = useDispatch();
  const userData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";

  const getStudentApplications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getStudentData/${userData._id}`
      );
      console.log("h", response.data);
      setTotalItems(response.data.total || 0);
      setStudentData((response?.data || []).sort((a, b) => (a < b ? 1 : -1)));

      dispatch(actions.triggerOmise(response.data.data));
    } catch (error) {
      console.error("Error fetching student applications:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStudentApplications();
  }, [userData._id, page, rowsPerPage]);
  useEffect(() => {
    // Check if the length of studentData has changed
    if (studentData.length !== prevStudentDataLength) {
      // If the data length has changed, reload the page
      // window.location.reload();
    }

    // Update the previous data length state
    setPrevStudentDataLength(studentData.length);
  }, [studentData]);

  // Pagination logic
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = (data) => {
    if (data?.StudentDetails.type_id == "school") {
      navigate(`/student/applications/school/${data?._id}`);
    } else {
      navigate(`/student/applications/university/${data?._id}`);
    }
  };

  const handleEdit = (data) => {
    if (data.StudentDetails.type_id == "school") {
      navigate(`/student/edit/school/${data?._id}`);
    } else {
      navigate(`/student/edit/university/${data?._id}`);
    }
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : studentData.length === 0 ? (
        <p>No data found</p>
      ) : (
        <div
          style={{
            overflowX: "auto",
            width: "100%",
          }}
        >
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              overflowX: "hidden",
              boxShadow: "0px 0px 30px 5px #f4f4f4",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid #ccc", padding: "18px" }}>
                  <RobotoTypo sx={{ fontWeight: 700 }}>
                    {t("studentDashboard.institute")}
                  </RobotoTypo>
                </th>
                <th style={{ border: "1px solid #ccc", padding: "18px" }}>
                  <RobotoTypo sx={{ fontWeight: 700 }}>
                    {t("studentDashboard.application_status")}
                  </RobotoTypo>
                </th>
                <th style={{ border: "1px solid #ccc", padding: "18px" }}>
                  <RobotoTypo sx={{ fontWeight: 700 }}>
                    {t("studentDashboard.submission_time")}
                  </RobotoTypo>
                </th>
                <th style={{ border: "1px solid #ccc", padding: "18px" }}>
                  <RobotoTypo sx={{ fontWeight: 700 }}>
                    {t("studentDashboard.application_form")}
                  </RobotoTypo>
                </th>
              </tr>
            </thead>
            <tbody>
              {studentData
                .slice(
                  Math.max(0, (page - 1) * rowsPerPage),
                  page * rowsPerPage
                )
                .map((item, index) => (
                  <tr
                    key={item._id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white",
                    }}
                  >
                    <td style={{ border: "1px solid #ccc", padding: "15px" }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <RobotoTypo>
                          {item?.StudentDetails?.selected_schools?.length > 0
                            ? `${item.StudentDetails.selected_schools
                                .map((data) => data?.[language]?.school_name)
                                .join(", ")
                                .slice(0, 30)}...`
                            : item?.StudentDetails?.personalDetails?.school_name}
                        </RobotoTypo>
                      </Box>
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "15px" }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <RobotoTypo>
                          {item?.StudentDetails?.payment_details
                            ? t("paid")
                            : t("unpaid")}
                        </RobotoTypo>
                      </Box>
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "15px" }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <RobotoTypo>
                        {item?.StudentDetails?.createdAt
                        ? moment.utc(item.StudentDetails.createdAt).local().format("MMMM DD, YYYY h:mm:ss A")
                        : "-"}
                        </RobotoTypo>
                      </Box>
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "15px" }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          style={{ p: 2, textTransform: "none" }}
                          onClick={() => {
                            handleNavigate(item);
                          }}
                        >
                          {t("studentDashboard.view")}
                        </Button>
                        <Button
                          style={{ p: 2, textTransform: "none" }}
                          onClick={() => {
                            handleEdit(item);
                          }}
                        >
                          {t("studentDashboard.update")}
                        </Button>
                      </Box>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <Stack spacing={2} alignItems="center" mt={2}>
        <Pagination
          count={Math.ceil(studentData.length / rowsPerPage)} // Total pages
          page={page}
          onChange={handlePageChange}
          size="small"
        />
      </Stack>
      {/* Pagination Controls */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box style={{ marginTop: "20px", display: "flex" }}>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#167d84",
              color: "white",
              mx: 2,
              ":hover": { backgroundColor: "#167d84", color: "white" },
            }}
            disabled={page === 0}
            onClick={() => handleChangePage(page - 1)}
          >
            Previous
          </Button>
          
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#167d84",
              color: "white",
              ":hover": { backgroundColor: "#167d84", color: "white" },
            }}
            disabled={page >= totalItems - 1}
            onClick={() => handleChangePage(page + 1)}
          >
            Next
          </Button>
         
        </Box>
      </Box> */}
    </div>
  );
};

export default Applications;
