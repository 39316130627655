import { Box, Checkbox, TextField, Typography, Button, IconButton, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Admin from "../Dashboard";
import { Delete } from '@mui/icons-material';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const languageOptions = [
  { code: 'en', label: 'English' },
  { code: 'ch', label: 'Chinese' },
  { code: 'sch', label: 'Simplified Chinese' },
//   { code: 'es', label: 'Spanish' },
//   { code: 'fr', label: 'French' }
];

const institutes = [
  {
    value : 'school', name : 'School'
  },
  {
    value : 'university', name : 'University'
  }
]

function AddService({ editServiceData , isEdit = false}) {
  console.log(isEdit)
  const navigate = useNavigate()
  const [languageNames, setLanguageNames] = useState({});
  const [amount, setAmount] = useState('')
  const [mandatory, setMandatory] = useState(false)
  const [instituteType, setInstituteType] = useState('')

  useEffect(() => {
    console.log(editServiceData)
    if(isEdit && editServiceData?._id){
      setLanguageNames(editServiceData?.multi_language_names)
      setAmount(editServiceData?.amount)
      setMandatory(editServiceData?.mandatory)
      setInstituteType(editServiceData?.institute_type)
    }
  }, [isEdit, editServiceData])

  const handleAddField = () => {
    // Find the first language that hasn't been added
    const availableLanguage = languageOptions.find(lang => !languageNames[lang.code]);
  
    if (!availableLanguage) return; // Stop if all languages are already added
  
    setLanguageNames({
      ...languageNames,
      [availableLanguage.code]: { name: '' } // Add the new language with an empty name field
    });
  };

  const handleChange = (key, event) => {
    setLanguageNames({
      ...languageNames,
      [key]: {
        ...languageNames[key],
        [event.target.name]: event.target.value
      }
    });
  };

  const handleRemoveField = (key) => {
    const updatedNames = { ...languageNames };
    delete updatedNames[key];
    setLanguageNames(updatedNames);
  };

  const handleLanguageChange = (code) => {
    if (!languageNames[code]) {
      setLanguageNames({
        ...languageNames,
        [code]: { name: '' }
      });
    }
  };

  const handleSubmit = async () => {
      if(isEdit){
        try {
          const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/updateCommonServiceById/${editServiceData?._id}`, {
            amount,
            mandatory,
            institute_type : instituteType,
            multi_language_names : languageNames
          })
          console.log(response)
          if(response.status === 200){
            navigate('/admin/services')
          }
        } catch (error) {
          console.log(error)
        }
      }else{
        try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/createCommonService`, {
            amount,
            mandatory,
            institute_type : instituteType,
            multi_language_names : languageNames
          })
          if(response.status === 201){
            navigate('/admin/services')
            console.log(response)
          }
        } catch (error) {
          console.log(error)
        }
      } 
  }

  
  return (
    <Admin>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'center' }}>
        <Box sx={{display : 'flex', flexDirection : 'column', width: '45%'}}>
        <InputLabel>Enter Amount</InputLabel>
        <TextField 
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        />
        </Box>
        
        <Box sx={{ width: '45%', display: 'flex', gap: 2, alignItems: 'center' }}>
          <Checkbox 
          checked={mandatory}
          onChange={(e) => setMandatory(e.target.checked)}
          />
          <Typography>Click if this amount is mandatory</Typography>
        </Box>
      </Box>

      <Box sx={{width : '45%', display : 'flex', flexDirection : 'column', mt : 2}}>
        <InputLabel>Select Institute type</InputLabel>
        <Select
        value={instituteType}
        >
          {institutes.map((item, i) => (
            <MenuItem key={i} onClick={() => setInstituteType(item?.name)} value={item.name}>{item.name}</MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{ mt: 4 }}>
        {Object.entries(languageNames).map(([key, field]) => (
          <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <FormControl sx={{ flex: 1 }}>
              <InputLabel>Language</InputLabel>
              <Select
                name="language"
                value={key}
                onChange={(e) => handleLanguageChange(e.target.value)} // Changes the language code
              >
                {languageOptions.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              placeholder="Enter service name"
              name="name"
              value={field.name}
              onChange={(e) => handleChange(key, e)}
              sx={{ flex: 2 }}
            />
            <IconButton onClick={() => handleRemoveField(key)} color="error">
              <Delete />
            </IconButton>
          </Box>
        ))}
        
        <Button sx={{textTransform : 'none'}} variant="contained" onClick={handleAddField}>
          Add Language
        </Button>
      </Box>
      
      <Box sx={{mt : 4, display : 'flex', justifyContent : 'center'}}>
      <Button disabled={Object.keys(languageNames).length == 0} onClick={() => handleSubmit()} sx={{textTransform : 'none'}} variant='contained'>{isEdit ? 'Update Service' : 'Create Service'}</Button>
      </Box>
    </Admin>
  );
}

export default AddService;
