import React,{useState, useEffect} from 'react'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Share from '../../../Share'
import InternationalSchoolRecommendation from './InternationalSchoolRecommendation'
import InternationalSchoolHeader from './InternationalSchoolHeader'
import InternationalSchoolBody from './InternationalSchoolBody'
import axios from 'axios';
import { useSelector } from 'react-redux';
import SideOverlap from '../../../../pages/sideOverlap'
import LanguageCourseRecommendation from '../../LanguageCourse/LanguageCourseRecommendation'


export default function InternationalSchool() {
    const [schoolList, setSchoolList] = useState([])
    const [data, setData] = useState([])
    const language = useSelector((state) => state.language.language);

    const getInternationalSchools = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getInternationalSchools`);
              console.log('getInternationalSchools response ',response)
              if(response.status == 200) {
                setSchoolList(response?.data)
                getData(Array.isArray(response?.data) ? response?.data : [response.data])
              }
        } catch(error) {
    
        }
      }

      const getData = (data) => {
        console.log('lang data ',data)
          //  const languageData = data?.map((item) => {
            let languageData
            if(language == 'en') {
               languageData = data?.length !=0 && data.map((item) => {
                return {
                id: item._id,
                data: item.en ,
                isShow: item?.isShow
                }
               })
            } else {
                languageData = data?.length !=0 && data.map((item) => {
                    return {
                    id: item._id,
                    data_en: item.en,
                    data: item.ch ,
                    isShow: item?.isShow,
                    }
                   })
            }
          // })
          setData(languageData)
      }

      useEffect(() => {
        getInternationalSchools()
      },[])

      useEffect(() => {
        if(schoolList?.length !=0 ) {
            console.log('getInternationalSchools daad ',schoolList)
          getData(schoolList)
        }
      },[language])


    return (
        <>
            <Header />
            <InternationalSchoolHeader />
            <InternationalSchoolBody />
            {schoolList?.length > 0 && (
              <LanguageCourseRecommendation data={schoolList} language={language}/>
            )}
            <Share />
            <Footer />
            <SideOverlap />
        </>
    )
}
