import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Admin from "../Dashboard";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  FormHelperText,
} from "@mui/material";
import { RobotoTypo } from "../../../utils/Typographies";

const Discount = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [discountData, setDiscountData] = useState({
    noOfApplication: "",
    discountPercentage: "",
    errors: { noOfApplication: false, discountPercentage: false },
  });

  // Fetch existing data if ID is present
  useEffect(() => {
    if (id) {
      fetchDiscountData(id);
    }
  }, [id]);

  const fetchDiscountData = async (discountId) => {
    try {
      // Replace this with actual API call
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/discounts/${discountId}`
      );
      const data = await response.json();
      console.log("data", data);
      setDiscountData({
        noOfApplication: data?.discount_details?.form_count || "",
        discountPercentage: data?.discount_details?.discount_percentage || "",
        errors: { noOfApplication: false, discountPercentage: false },
      });
    } catch (error) {
      console.error("Error fetching discount data:", error);
    }
  };
  console.log("disc", discountData);
  // Handle Select Change
  const handleSelect = (event) => {
    const value = event.target.value;
    setDiscountData((prev) => ({
      ...prev,
      noOfApplication: value,
      errors: { ...prev.errors, noOfApplication: false },
    }));
  };

  // Handle TextField Change
  const handleDiscountChange = (event) => {
    const value = event.target.value;
    setDiscountData((prev) => ({
      ...prev,
      discountPercentage: value,
      errors: { ...prev.errors, discountPercentage: false },
    }));
  };

  // Validation Function
  const validateFields = () => {
    let isValid = true;
    const errors = {
      noOfApplication: !discountData.noOfApplication,
      discountPercentage:
        !discountData.discountPercentage ||
        isNaN(discountData.discountPercentage),
    };

    if (errors.noOfApplication || errors.discountPercentage) isValid = false;

    setDiscountData((prev) => ({ ...prev, errors }));
    return isValid;
  };

  // Handle Form Submission
  const handleSave = async () => {
    if (!validateFields()) return;

    try {
      const method = id ? "PUT" : "POST";
      const url = id
        ? `${process.env.REACT_APP_BASE_URL}/discounts/${id}`
        : `${process.env.REACT_APP_BASE_URL}/discounts`;

      await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          discount_details: {
            form_count: discountData.noOfApplication,
            discount_percentage: discountData.discountPercentage,
          },
        }),
      });

      navigate("/admin/discountList");
    } catch (error) {
      console.error("Error saving discount:", error);
    }
  };

  return (
    <Admin>
      <Box>
        <Stack
          sx={{ border: "1px solid #E0E0E0" }}
          py="20px"
          gap="20px"
          mx="20px"
        >
          <Box display="flex" justifyContent="space-around">
            {/* Select Field */}
            <Box width="45%">
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  fontSize: { xs: 18, sm: 18 },
                  color: "#003B5C",
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                Number Of Application
              </RobotoTypo>
              <Select
                value={discountData.noOfApplication}
                onChange={handleSelect}
                sx={{ textTransform: "capitalize", width: "100%" }}
                error={discountData.errors.noOfApplication}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select an option
                </MenuItem>
                {Array.from({ length: 100 }, (_, i) => i + 1).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {discountData.errors.noOfApplication && (
                <FormHelperText sx={{ color: "red" }}>Required</FormHelperText>
              )}
            </Box>

            {/* Text Field */}
            <Box width="45%">
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  fontSize: { xs: 18, sm: 18 },
                  color: "#003B5C",
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                Discount Percentage
              </RobotoTypo>
              <TextField
                value={discountData.discountPercentage}
                onChange={handleDiscountChange}
                sx={{ width: "100%" }}
                error={discountData.errors.discountPercentage}
                helperText={
                  discountData.errors.discountPercentage
                    ? "Enter a valid number"
                    : ""
                }
              />
            </Box>
          </Box>
        </Stack>

        {/* Save Button */}
        <Box
          display="flex"
          width="100%"
          sx={{ my: "20px" }}
          justifyContent="flex-end"
        >
          <Button
            sx={{
              backgroundColor: "#177D84",
              "&:hover": { backgroundColor: "#177D84" },
            }}
            onClick={handleSave}
            variant="contained"
          >
            {id ? "Update" : "Save"}
          </Button>
        </Box>
      </Box>
    </Admin>
  );
};

export default Discount;
