import { Typography, Button, Box, IconButton, TextField, InputLabel } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { RobotoTypo } from "../../utils/Typographies";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import EditIcon from '@mui/icons-material/Edit';

function Profile() {
  const { t, i18n } = useTranslation();
  const userData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";

    const [loading, setLoading] = useState(false)
    const [studentData, setStudentData] = useState({});

    const [formData, setFormData] = useState({
      name: studentData?.name || '',
      username : studentData?.username || '',
      contactNumber: studentData?.contact_number || '',
    });

 
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const fetchStudentData = async () => {
    setLoading(true)
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/student-credentials/${userData._id}`
      )
      .then((response) => {
        console.log(response.data);
        setStudentData(response.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  };

  useEffect(() => {
    if (userData._id) {
      fetchStudentData();
    }
  }, [userData._id]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handlePhoneChange = (value, name) => {
      setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
      setFormData({
        name: studentData?.name,
        contactNumber: studentData?.contact_number,
        username : studentData?.username
      })
    }, [studentData, open])
    
    

    const handleSubmit = async () => {
      const { name, username, contactNumber } = formData;
      if (name && username && contactNumber) {
        try {
          setLoading(true)
          const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/update-student-credentials/${userData._id}`, {
            name : formData?.name,
            username : formData?.username,
            contact_number : formData?.contactNumber 
          });
          console.log(response.data)
          fetchStudentData()
          setOpen(false)
          setLoading(false)
        } catch (error) {
          console.error('API Error:', error);
          setLoading(false)
          alert('Failed to update details.');
        }
      } else {
        alert('Please fill in all required fields.');
      }
    };


  return (
    <>
    {loading ? (
      <>
      <Typography>Loading...</Typography>
      </>
    ) : (
<Box>
      <Box sx={{mb : 2, display : 'flex', justifyContent : 'flex-end'}}>
        <Button startIcon={<EditIcon />} sx={{backgroundColor : '#167d84', ':hover' : {backgroundColor : '#167d84'} }} onClick={() => handleClickOpen()} variant='contained'>{t("edit")}</Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2, sm: 6 },
        }}
      >
         <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "45%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
          {t("username_passport_name")}
          </RobotoTypo>
          <RobotoTypo sx={{ fontSize: "1.1rem", fontWeight: 700 }}>
            {studentData?.username}
          </RobotoTypo>
        </Box>
        
        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "45%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
            {t("studentDashboard.name")}
          </RobotoTypo>
          <RobotoTypo sx={{ fontSize: "1.1rem", fontWeight: 700 }}>
            {studentData?.name}
          </RobotoTypo>
        </Box>

       
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          mt: 3,
          gap: { xs: 2, sm: 6},
        }}
      >
        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "45%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
          {t("studentDashboard.email")}
          </RobotoTypo>
          <RobotoTypo sx={{ fontSize: "1.1rem", fontWeight: 700 }}>
            {studentData?.email}
          </RobotoTypo>
        </Box>

        {/* <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "45%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
          {t("studentDashboard.phone")}
          </RobotoTypo>
          <RobotoTypo
            sx={{ fontSize: "1.1rem", fontWeight: 700 }}
          >{`${studentData?.country_code} ${studentData?.phone}`}</RobotoTypo>
        </Box> */}

        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "45%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
          {t("studentDashboard.contact")}
          </RobotoTypo>
          <RobotoTypo sx={{ fontSize: "1.1rem", fontWeight: 700 }}>
            +{studentData?.contact_number}
          </RobotoTypo>
        </Box>
      </Box>


      <Dialog
     
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title" sx={{display : 'flex', justifyContent : 'space-between', alignItems : 'center'}}>
            <span>Edit Information</span>
            <IconButton onClick={() => handleClose()} aria-label="close">
            <CloseIcon />
          </IconButton>
            
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          <InputLabel>{t("username_passport_name")}</InputLabel>
          <TextField
        type="text"
        name="username"
        value={formData.username}
        onChange={handleChange}
        margin="normal"
        sx={{width : '350px'}}
      />

          <InputLabel>{t("studentDashboard.name")}</InputLabel>
          <TextField
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        margin="normal"
        sx={{width : '350px'}}
      />

        <InputLabel>{t("studentDashboard.contact")}</InputLabel>
      <PhoneInput
        country={'sg'}
        value={formData.contactNumber}
        onChange={(value) => handlePhoneChange(value, 'contactNumber')}
        buttonStyle={{ border: "none" }}
        inputStyle={{
          height: "54px",
          width: "100%",
          marginTop: "10px",
          borderRadius: "5px",
          backgroundColor: "white",
          boxShadow: "2px 2px 4px 0px #00000040 inset",
          "& fieldset": { border: "none" },
          "& .MuiInputBase-input": {
            padding: "14px 14px",
          },
          // "&>.react-tel-input .flag-dropdown ": {
          //     backgroundColor: 'white',
          // }
        }}
        inputProps={{
          name: 'contactNumber',
          required: true,
        }}
      />

          </DialogContentText>
          
        </DialogContent>
        <DialogActions sx={{display : 'flex', justifyContent : 'center', mb : 2}}>
          <Button disabled={loading} onClick={() => handleSubmit()} variant="contained" sx={{width : '90%', backgroundColor : '#167d84', ':hover' : {backgroundColor : '#167d84'}}}>{t("studentDashboard.update")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
    )}
    
    </>
  );
}

export default Profile;
