import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, Typography } from '@mui/material';
import { Box } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Admin from "../Dashboard";

function ServiceList() {
    const navigate = useNavigate()
    const [services, setServices] = useState([])
    const language = useSelector((state) => state.language.language)
    const [open, setOpen] = useState(false)
    const [deleteId, setDeleteId] = useState('')

    const fetchServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAllCommonService`)
            if(response.status === 200){
                setServices(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchServices()
    }, [])

    const serviceDelete = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/deleteCommonServiceById/${deleteId}`)
            if(response.status === 200){
                fetchServices()
                setOpen(false)
                setDeleteId('')
            }
        } catch (error) {
            console.log(error)
        }
    }


  return (
        <Admin>
            <Box>
                <Box sx={{display : 'flex', justifyContent : 'flex-end'}}>
                    <Button variant='contained' onClick={() => navigate('/admin/addServices')}>Add</Button>
                </Box>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell sx={{fontSize : '1.1rem'}}>Name</TableCell>
                            <TableCell sx={{fontSize : '1.1rem'}}>Institute</TableCell>
                            <TableCell sx={{fontSize : '1.1rem'}}>Amount</TableCell>
                            <TableCell sx={{fontSize : '1.1rem'}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {services.map((data) => (
                        <TableRow>
                            <TableCell>{data?.multi_language_names?.en?.name}</TableCell>
                            <TableCell>{data?.institute_type}</TableCell>
                            <TableCell>S$ {data.amount}</TableCell>
                            <TableCell sx={{display : 'flex', gap : 2}}>
                                <Button sx={{textTransform : 'none'}} onClick={() => navigate(`/admin/editServices/${data?._id}`)} variant="outlined">Edit</Button>
                                <Button sx={{textTransform : 'none'}} onClick={() => {
                                    setOpen(true)
                                    setDeleteId(data?._id)
                                    }} variant="outlined" color="error">Delete</Button>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

        <Dialog open={open}>
                <Box sx={{p : 2.9}}>
                    <Typography>Are you sure want to delete the service?</Typography>
                    <Box sx={{width : '100%', mt : 2, display : 'flex', justifyContent : 'flex-end', alignItems : 'center', gap : 2}}>
                        <Button sx={{textTransform : 'none', backgroundColor : 'black', ":hover" : {backgroundColor : 'black'}}} variant='contained' onClick={() => setOpen(false)}>Close</Button>
                        <Button onClick={() => serviceDelete()} sx={{textTransform : 'none', backgroundColor : 'red', ':hover' : {backgroundColor : 'red'}}} variant='contained'>Delete</Button>
                    </Box>
                </Box>
        </Dialog>
        </Admin>
  )
}

export default ServiceList