import React, { useEffect, useState } from "react";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import { Box, Divider, ListItem, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import theme from "../../../utils/theme";
import moment from "moment";
import axios from "axios";

const UniversityViewDetail = ({ data }) => {
  const generateKey = (key) => {
    if (key === "have_you_studied") {
      return "Have you studied in Singapore before?";
    } else if (key === "birth_certificateNo") {
      return "Birth Certificate Number";
    } else if (key === "addressAndPostal") {
      return "Home Address and Postal Code";
    } else if (key === "natirelationshiponality") {
      return "Relationship";
    } else if (key === "marraige_Regno") {
      return "Marraige Register Number";
    } else if (key === "finished_condition") {
      return "Finished Condition: Application/Incomplete/Completed";
    } else if (key === "course_nature") {
      return "Course Nature: Full-Time/Part-Time";
    } else if (key === "academic_certificateNo") {
      return "Academic Certificate Number";
    } else if (key === "course_nature") {
      return "Course Nature: Full-Time/Part-Time";
    } else if (key === "academic_certificateNo") {
      return "Academic Certificate Number";
    } else if (key === "course_nature") {
      return "Course Nature: Full-Time/Part-Time";
    } else if (key === "academic_certificateNo") {
      return "Academic Certificate Number";
    } else if (key === "refused_country") {
      return "Have you ever been refused entry to or deported from any country? ";
    } else if (key === "convicted_crime") {
      return "Have you ever been convicted of a crime by a court in any country/region? ";
    } else if (key === "banned_entry") {
      return "Have you ever been banned from entering Singapore? ";
    } else if (key === "other_passport") {
      return "Have you ever entered Singapore using any other passport or name? ";
    } else if (key === "reason_other_passport") {
      return "If yes, please provide ";
    } else if (key === "score") {
      return "If yes, please provide ";
    }

    return key.replace(/_/g, " ");
  };

  const [discountAmount, setDiscountAmount] = useState(0)
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [applicationFee, setApplicationFee] = useState(0)
  const [consultancyFee, setConsultancyFee] = useState(0)
  const [serviceFee, setServiceFee] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)
  const [discountData, setDiscountData] = useState([])
  const [payAmount, setPayAmount] = useState(9.99)

  const PayLaterDetails = {
    PaymentMethod: "Pay later",
    Payment_Fees: data?.StudentDetails?.application_fees,
  };
  const StripedListItem = styled(ListItem)(({ theme, index }) => ({
    backgroundColor:
      index % 2 === 0 ? theme.palette.action.hover : "transparent",
    padding: "20px",
    display: "flex",
    alignContent: "center",

    gap: "10px",
  }));
  console.log("data", data.StudentDetails);
  const personalDetails = {
    student_name: data?.StudentDetails?.personalDetails?.student_name,
    email: data?.StudentDetails?.personalDetails?.email,
    student_title: data?.StudentDetails?.personalDetails?.student_title,
    dob: data?.StudentDetails?.personalDetails?.dob,
    nationality: data?.StudentDetails?.personalDetails?.nationality,
    documents_held: data?.StudentDetails?.personalDetails?.documents_held,
    passport_number: data?.StudentDetails?.personalDetails?.passport_number,
    have_you_studied: data?.StudentDetails?.personalDetails?.have_you_studied,
    selected_school: data?.StudentDetails?.selected_schools
    ?.map((school) => school?.en?.school_name)
    ?.join("\n"),
    academic_level: data?.StudentDetails?.personalDetails?.academic_level,
    course_nature: data?.StudentDetails?.personalDetails?.course_nature,
    courses_enrolled: data?.StudentDetails?.personalDetails?.courses_enrolled,
    course_start_date: data?.StudentDetails?.personalDetails?.course_start_date,
    mobile_number: data?.StudentDetails?.personalDetails?.mobile_number,
    birth_certificateNo:
      data?.StudentDetails?.personalDetails?.birth_certificateNo,
    marraige_status: data?.StudentDetails?.personalDetails?.marraige_status,
    home_address: data?.StudentDetails?.personalDetails?.home_address,
    singapore_address: data?.StudentDetails?.personalDetails?.singapore_address,
  };
  const emergency_contact = {
    name: data?.StudentDetails?.personalDetails?.emergency_contact?.name,
    relation:
      data?.StudentDetails?.personalDetails?.emergency_contact?.relation,
    addressAndPostal:
      data?.StudentDetails?.personalDetails?.emergency_contact
        ?.addressAndPostal,
    phoneNumber:
      data?.StudentDetails?.personalDetails?.emergency_contact?.phoneNumber,
  };

  const father_details = {
    father_name: data?.StudentDetails?.familyDetails?.fatherDetails?.name,
    father_nationality:
      data?.StudentDetails?.familyDetails?.fatherDetails?.nationality,
    father_Dob: data?.StudentDetails?.familyDetails?.fatherDetails?.dob,
    occupation: data?.StudentDetails?.familyDetails?.fatherDetails?.occupation,
    father_documents_held:
      data?.StudentDetails?.familyDetails?.fatherDetails?.documents_held,
  };

  const mother_details = {
    mother_name: data?.StudentDetails?.familyDetails?.motherDetails?.name,
    mother_nationality:
      data?.StudentDetails?.familyDetails?.motherDetails?.nationality,
    mother_dob: data?.StudentDetails?.familyDetails?.motherDetails?.dob,
    mother_occupation:
      data?.StudentDetails?.familyDetails?.motherDetails?.occupation,
    mother_documents_held:
      data?.StudentDetails?.familyDetails?.motherDetails?.documents_held,
  };

  const spouse_details = {
    spouse_name: data?.StudentDetails?.familyDetails?.spouseDetils?.name,
    marraige_date:
      data?.StudentDetails?.familyDetails?.spouseDetils?.marraige_date,
    marraige_Regno:
      data?.StudentDetails?.familyDetails?.spouseDetils?.marraige_Regno,
    divorce_date:
      data?.StudentDetails?.familyDetails?.spouseDetils?.divorce_date,
    divorce_number:
      data?.StudentDetails?.familyDetails?.spouseDetils?.divorce_number,
  };

  const sibiling_detials = {
    sibilings_name: data?.StudentDetails?.familyDetails?.sibilingDetails?.name,
    natirelationshiponality:
      data?.StudentDetails?.familyDetails?.sibilingDetails?.relationship,
    sibilings_dob: data?.StudentDetails?.familyDetails?.sibilingDetails?.dob,
    sibilings_nationality:
      data?.StudentDetails?.familyDetails?.sibilingDetails?.nationality,
    sibilings_occupation:
      data?.StudentDetails?.familyDetails?.sibilingDetails?.occupation,
    sibilings_documents_held:
      data?.StudentDetails?.familyDetails?.sibilingDetails?.documents_held,
  };

  const fund_details = {
    applicant_income:
      data?.StudentDetails?.familyDetails?.fundingDetails?.applicant_income,
    applicant_deposits:
      data?.StudentDetails?.familyDetails?.fundingDetails?.applicant_deposits,
    father_income:
      data?.StudentDetails?.familyDetails?.fundingDetails?.father_income,
    father_deposits:
      data?.StudentDetails?.familyDetails?.fundingDetails?.father_deposits,
    mother_income:
      data?.StudentDetails?.familyDetails?.fundingDetails?.mother_income,
    mother_deposits:
      data?.StudentDetails?.familyDetails?.fundingDetails?.mother_deposits,

    spouse_income:
      data?.StudentDetails?.familyDetails?.fundingDetails?.spouse_income,
    spouse_deposits:
      data?.StudentDetails?.familyDetails?.fundingDetails?.spouse_deposits,
  };
  const Relavant_details = {
    gpa_score: data?.StudentDetails?.otherDetails?.gpa_score,
    provide_grade: data?.StudentDetails?.otherDetails?.provide_grade,
    ielts_test: data?.StudentDetails?.otherDetails?.ielts_test,
    score: data?.StudentDetails?.otherDetails?.score,
    singapore_address: data?.StudentDetails?.otherDetails?.singapore_address,
    residential_address:
      data?.StudentDetails?.otherDetails?.residential_address,
    checkin_time: data?.StudentDetails?.otherDetails?.checkin_time,
    departure_time: data?.StudentDetails?.otherDetails?.departure_time,
    refused_country: data?.StudentDetails?.otherDetails?.refused_country,
    convicted_crime: data?.StudentDetails?.otherDetails?.convicted_crime,
    banned_entry: data?.StudentDetails?.otherDetails?.banned_entry,
    other_passport: data?.StudentDetails?.otherDetails?.other_passport,
    reason_other_passport:
      data?.StudentDetails?.otherDetails?.reason_other_passport,
  };

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/discounts`);
        if (response && response.data) {
          setDiscountData(response.data)
          console.log(response.data)
        } else {
          console.log('No data received');
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    };
    
    fetchDiscounts()
  }, []);


  const calculateTotalAmount = (selectedSchools) => {
    return selectedSchools.reduce((total, school) => {
      // const mandatoryServicesTotal = school.services
      //   .filter((service) => service.mandatory) // Only mandatory services
      //   .reduce((sum, service) => sum + Number(service.amount_en), 0); // Sum service amounts
      const amount = parseFloat(school?.application_fee_amount)
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
  };

  const calculateConsultationAmount = (selectedSchools) => {
    return selectedSchools.reduce((total, school) => {
      // const mandatoryServicesTotal = school.services
      //   .filter((service) => service.mandatory) // Only mandatory services
      //   .reduce((sum, service) => sum + Number(service.amount_en), 0); // Sum service amounts
      const amount = parseFloat(school?.consultation_fee_amount)
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
  };

  const calculateServiceAmount = (selectedService) => {
    if(selectedService === undefined || selectedService === null){
      return 0
    }
    return selectedService.reduce((total, school) => {
      return total + parseFloat(school?.amount);
    }, 0);
  };

  useEffect(() => {
    if (data?.StudentDetails?.selected_schools?.length >= 0) {
      const totalAmount = calculateTotalAmount(data?.StudentDetails?.selected_schools).toFixed(2)
      const consultAmount = calculateConsultationAmount(data?.StudentDetails?.selected_schools).toFixed(2)
      const serviceAmount = calculateServiceAmount(data?.StudentDetails?.selected_service).toFixed(2)
      setTotalAmount(parseFloat(totalAmount) + parseFloat(consultAmount) + parseFloat(serviceAmount))
      setServiceFee(parseFloat(serviceAmount))
      setApplicationFee(parseFloat(totalAmount))
      setConsultancyFee(parseFloat(consultAmount))
      // Find matching discount based on form_count
      const discountItem = discountData.filter((discount) => discount.discount_details.form_count <= data?.StudentDetails?.selected_schools?.length);
  
      if (discountItem.length > 0) {
        const discountPercentage =  discountItem[discountItem?.length - 1]?.discount_details?.discount_percentage
        // const discountedAmount = (totalAmount - (totalAmount * discountPercentage) / 100).toFixed(2);
        const discountConsultAmount = (consultAmount - (consultAmount * discountPercentage) / 100).toFixed(2);

        console.log("Discount Applied:", discountPercentage, "%");
        console.log("Total After Discount:", discountConsultAmount);
        setDiscountAmount((consultAmount - discountConsultAmount).toFixed(2))
        const final_amount = ((parseFloat(totalAmount) + parseFloat(discountConsultAmount) + parseFloat(serviceAmount))).toFixed(2)
        setPayAmount(final_amount)
        setDiscountPercentage(discountPercentage)
        setFinalAmount(final_amount)
      } else {
        console.log("No discount applicable");
        setPayAmount(parseFloat(totalAmount) + parseFloat(consultAmount) + parseFloat(serviceAmount))
        setFinalAmount(parseFloat(totalAmount) + parseFloat(consultAmount) + parseFloat(serviceAmount))
        setDiscountAmount(0)
        setDiscountPercentage(0)
      }
    }
  }, [data?.StudentDetails?.selected_schools, data?.StudentDetails?.selected_service ,discountData]);

  console.log(personalDetails?.selected_school)
  console.log(data, 'stude1')
  return (
    <Admin>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Personal Details
        </RobotoTypo>
        <Stack
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(personalDetails).map(([key, value], index) => (
                <StripedListItem index={index} key={key}>
                  <RobotoTypo
                    sx={{
                      fontWeight: "500",
                      color: "#000000",
                      fontSize: "16px",
                      textTransform: "capitalize",
                    }}
                  >
                    {generateKey(key)} :
                  </RobotoTypo>
                  <RobotoTypo
                    sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                  >
                    {Array.isArray(value) ? value.join(", ") : value?.split("\n").map((line, idx) => (
                      <span key={idx}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </RobotoTypo>
                </StripedListItem>
              ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Emergency Contact
        </RobotoTypo>
        <Stack
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(emergency_contact).map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Father Details
        </RobotoTypo>
        <Stack
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(father_details).map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Mother Details
        </RobotoTypo>
        <Stack
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(mother_details).map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Sibiling Details
        </RobotoTypo>
        <Stack
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(sibiling_detials).map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Spouse Details
        </RobotoTypo>
        <Stack
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(spouse_details).map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Funding Information
        </RobotoTypo>
        <Stack
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(fund_details).map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <RobotoTypo
        sx={{
          fontWeight: "600",
          color: "#003B5C",
          fontSize: "20px",
          textAlign: "left",
          fontStyle: "italic",
        }}
      >
        Educational Details
      </RobotoTypo>
      {data?.StudentDetails?.educationalDetails?.map((details, index) => (
        <Stack gap="12px">
          {index > 0 && (
            <Divider sx={{ height: "2px", background: "#D3D3D3" }} />
          )}

          <Stack
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "16px",
            }}
          >
            {Object.entries(details).map(([key, value], index) => (
              <StripedListItem index={index} key={key}>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  {generateKey(key)} :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {value}
                </RobotoTypo>
              </StripedListItem>
            ))}
          </Stack>
        </Stack>
      ))}
      <RobotoTypo
        sx={{
          fontWeight: "600",
          color: "#003B5C",
          fontSize: "20px",
          textAlign: "left",
          fontStyle: "italic",
        }}
      >
        Academic Details
      </RobotoTypo>
      {data?.StudentDetails?.academicDetails?.map((details, index) => (
        <Stack gap="12px">
          {index > 0 && (
            <Divider sx={{ height: "2px", background: "#D3D3D3" }} />
          )}

          <Stack
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "16px",
            }}
          >
            {Object.entries(details).map(([key, value], index) => (
              <StripedListItem index={index} key={key}>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  {generateKey(key)} :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {value}
                </RobotoTypo>
              </StripedListItem>
            ))}
          </Stack>
        </Stack>
      ))}
      <RobotoTypo
        sx={{
          fontWeight: "600",
          color: "#003B5C",
          fontSize: "20px",
          textAlign: "left",
          fontStyle: "italic",
        }}
      >
        Work Experience
      </RobotoTypo>
      {data?.StudentDetails?.workExperince?.map((details, index) => (
        <Stack gap="12px">
          {index > 0 && (
            <Divider sx={{ height: "2px", background: "#D3D3D3" }} />
          )}

          <Stack
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "16px",
            }}
          >
            {Object.entries(details).map(([key, value], index) => (
              <StripedListItem index={index} key={key}>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  {generateKey(key)} :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {value}
                </RobotoTypo>
              </StripedListItem>
            ))}
          </Stack>
        </Stack>
      ))}
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Relavant details
        </RobotoTypo>
        <Stack
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(Relavant_details).map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      {!data?.StudentDetails?.payment_details ? (
        <Stack gap="12px">
          <RobotoTypo
            sx={{
              fontWeight: "600",
              color: "#003B5C",
              fontSize: "20px",
              textAlign: "left",
              fontStyle: "italic",
            }}
          >
            Payment Details
          </RobotoTypo>
          <Stack
            gap="12px"
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "16px",
            }}
          >
            <Box>
                 <Typography sx={{fontWeight : 600, fontSize : '1.1rem'}}>Applicant has been choosed Pay later (Application_fees)</Typography>
               </Box>

               {data?.StudentDetails?.selected_schools?.length > 0 && (
                <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: '12px', width: { sm: '100%', lg: '100%' } }}>
                  <Typography sx={{ fontWeight: 600, mb: 2 }}>Applied for Selected Schools</Typography>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>School Name</TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>Application Fee (S$)</TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>Consultation Fee (S$)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.StudentDetails?.selected_schools.map((school, i) => (
                        <TableRow key={i}>
                          <TableCell>{school?.en?.school_name}</TableCell>
                          <TableCell>S$ {school?.application_fee_amount}</TableCell>
                          <TableCell>S$ {school?.consultation_fee_amount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}


              {data?.StudentDetails?.selected_services?.length > 0 && (
               <Box sx={{backgroundColor : 'white', p : 2, mt : 2, borderRadius : '12px', width : {sm : '100%', lg : '50%'}}}>
                 <Typography sx={{fontWeight : 600}}>Applied for Selected Services</Typography>
                  {data?.StudentDetails?.selected_services.map((data, i) => (
                     <Box sx={{display : 'flex', justifyContent : 'space-between'}}>
                    <Typography>{data?.multi_language_names?.en?.name}</Typography>
                    <Typography>S$ {data?.amount}</Typography>
                    </Box>
                  ))}
               </Box>
               )}


             <StripedListItem>
               
              {data?.StudentDetails?.selected_schools?.length > 0 && (
              <Box sx={{mr : 1, display : 'flex', justifyContent : 'center', flexDirection : 'row', mt : 0}}>
            <Box>
              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>Application Amount</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {applicationFee}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>Consultancy Amount</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {consultancyFee}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>Service Amount</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {serviceFee}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>Total Amount</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {totalAmount}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>Discount Amount</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {discountAmount}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 600, fontSize : '1.1rem'}}>Final Amount</Typography>
              <Typography sx={{fontWeight : 600, fontSize : '1.1rem'}}>S$ {finalAmount}</Typography>
              </Box>
              </Box>
          </Box>
            )}
              </StripedListItem>
          </Stack>
        </Stack>
      ) : (
        <Stack gap="12px">
          <RobotoTypo
            sx={{
              fontWeight: "600",
              color: "#003B5C",
              fontSize: "20px",
              textAlign: "left",
              fontStyle: "italic",
            }}
          >
            Payment Details
          </RobotoTypo>
          <Stack
            gap="12px"
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "16px",
            }}
          >
            <StripedListItem
              sx={{
                backgroundColor: theme.palette.action.hover,
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                Paid Applicant Name :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {data?.StudentDetails?.payment_details?.card?.name}
              </RobotoTypo>
            </StripedListItem>
            <StripedListItem>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                Paid Amount :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                S$ {data?.StudentDetails?.payment_details?.amount / 100}
              </RobotoTypo>
            </StripedListItem>
            <StripedListItem
              sx={{
                backgroundColor: theme.palette.action.hover,
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                Payment Status:
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {data?.StudentDetails?.payment_details?.status}
              </RobotoTypo>
            </StripedListItem>
            <StripedListItem>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                Bank Details:
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {data?.StudentDetails?.payment_details?.card?.bank}
              </RobotoTypo>
            </StripedListItem>
            <StripedListItem
              sx={{
                backgroundColor: theme.palette.action.hover,
              }}
            >
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                Card Type:
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {data?.StudentDetails?.payment_details?.card?.brand}
              </RobotoTypo>
            </StripedListItem>
            <StripedListItem>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                Paid Time:
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {moment(data?.StudentDetails?.payment?.card?.paid_at).format(
                  "DD MMM YYYY, HH:mm A"
                )}
              </RobotoTypo>
            </StripedListItem>
          </Stack>
        </Stack>
      )}
    </Admin>
  );
};

export default UniversityViewDetail;
