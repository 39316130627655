import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Stack, Stepper, Step, StepLabel } from '@mui/material';
import { Check, Settings as SettingsIcon, GroupAdd as GroupAddIcon, Reviews as ReviewsIcon, Person as PersonIcon, School as SchoolIcon } from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#177D84',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#177D84',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#177D84',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#177D84',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className, icon } = props;

    const icons = {
        1: <PersonIcon />,
        2: <GroupAddIcon />,
        3: <SchoolIcon />,
        4: <ReviewsIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};



export default function UniversityApplicationForm({ formPage, setFormPage, isFormValid }) {
    
    const {t} = useTranslation()

    const steps = [t("personal_details"), t("family_details"), t("educational_details"), t("review"),];

    const handleStepClick = (step) => {
        
        setFormPage(step+1);
    };
    const isStepDisabled = (index) => {
        if(index == 1 && !isFormValid.formOne) {
            return true
        } else if(index == 2 && !isFormValid.formTwo) {
            return true
        } else if(index == 3 && !isFormValid.formThree) {
            return true
        } 
        return false
      };
    
      const handleStepClickInternal = (index) => {
        if (isStepDisabled(index)) {
          return;
        }
        handleStepClick(index);
      };


    return (
        <>

            <Stack sx={{ backgroundColor: "#F7F7F7", margin: { md: '0 8%', xs: '0 2%' }, padding: '3%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} spacing={4}>
                <Stepper sx={{ cursor: 'pointer' }} alternativeLabel activeStep={formPage-1} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label} onClick={() => handleStepClickInternal(index)}  >
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Stack>


        </>
    );
}