import React, { useEffect, useState } from 'react'
import { Badge, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemText, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { RobotoTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import LaunchIcon from "@mui/icons-material/Launch";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import discountPng from '../../../assets/png/discount.png'
import tickPng from '../../../assets/png/tick.png'
import offerPng from '../../../assets/png/offer.png'
import EastIcon from '@mui/icons-material/East';




function ApplyMoreUniv({ 
  selectedSchools, setSelectedSchools, 
  moreApplicationPrice, setMoreApplicationPrice, 
  discountData, setDiscountData,
  discountAmount, setDiscountAmount,
  discountPercentage, setDiscountPercentage,
  totalAmount, setTotalAmount,
  finalAmount, setFinalAmount,
  language
}) {
    console.log(selectedSchools)
    const { t } = useTranslation();
    const [schoolTypes, setSchoolTypes] = useState([]);
    const [openModal, setOpenModal] = useState(false)
    const [applicationFee, setApplicationFee] = useState(0)
    const [consultationFee, setConsultancyFee]= useState(0)
    const schoolOptions = [
      { value: "university", label: t("university") },
    ];
   

    console.log(schoolTypes)
    
    const [schools, setSchools] = useState([])
    const [dataError, setDataError] = useState({
        schoolName_en: "",
        schoolName_sch: "",
        description_en: "",
        benefits_en: "",
        benefits_sch: "",
        schoolName_ch: "",
        description_ch: "",
        description_sch: "",
        youtube_url: "",
        benefits_ch: "",
      });
    
      const handleModal = () => {
        setOpenModal(!openModal)
      }

      const handleSelect = (event) => {
        const {
          target: { value },
        } = event;
      
        let updatedValues = typeof value === "string" ? value.split(",") : value;
      
        // If "All" is selected, select all options except "All"
        if (updatedValues.includes("all")) {
          if (schoolTypes.includes("all")) {
            // If "All" was already selected and clicked again, deselect all
            setSchoolTypes([]);
          } else {
            // Select all individual options except "All"
            setSchoolTypes(schoolOptions.filter(option => option.value !== "all").map(option => option.value));
          }
        } else {
          // Ensure "All" is checked if all options are selected manually
          const allOptionsSelected = schoolOptions
            .filter(option => option.value !== "all")
            .every(option => updatedValues.includes(option.value));
      
          if (allOptionsSelected) {
            updatedValues = ["all", ...updatedValues];
          }
      
          setSchoolTypes(updatedValues);
        }
      
        // Reset error message when schoolTypes is updated
        if (updatedValues.length > 0) {
          setDataError((prev) => ({
            ...prev,
            school_types: "",
          }));
        }
      };


      useEffect(() => {
        const fetchSchoolDatas = async () => {
          try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/getSchoolByType`, { schools: schoolTypes });
            if (response && response.data) {
              setSchools(response.data);
              console.log(JSON.parse(response.data[0]?.services[0]))
            } else {
              console.log('No data received');
            }
          } catch (error) {
            console.error('Error fetching school data:', error);
          }
        };
        
        if(schoolTypes.length > 0){
            fetchSchoolDatas();
        }
      }, [schoolTypes]);

      useEffect(() => {
        setSchoolTypes([schoolOptions[0]?.value])
    }, [])

      useEffect(() => {
        const fetchDiscounts = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/discounts`);
            if (response && response.data) {
              setDiscountData(response.data)
              console.log(response.data)
            } else {
              console.log('No data received');
            }
          } catch (error) {
            console.error('Error fetching school data:', error);
          }
        };
        
        fetchDiscounts()
      }, []);

      const handleCheckboxChange = (dat) => {
        setSelectedSchools((prevSelected) => {
          const existingItem = prevSelected.find((data) => data._id === dat._id);
      
          if (existingItem) {
            
            if (existingItem.mandatory) {
              return prevSelected;
            }
            return prevSelected.filter((data) => data._id !== dat._id);
          } else {
            const parsedServices =
              Array.isArray(dat.services) && typeof dat.services[0] === "string"
                ? JSON.parse(dat.services[0])
                : dat.services;
      
            return [...prevSelected, { ...dat, services: parsedServices }];
          }
        });
      };


      const calculateTotalAmount = (selectedSchools) => {
        return selectedSchools.reduce((total, school) => {
          // const mandatoryServicesTotal = school.services
          //   .filter((service) => service.mandatory) // Only mandatory services
          //   .reduce((sum, service) => sum + Number(service.amount_en), 0); // Sum service amounts
          const amount = parseFloat(school?.application_fee_amount)
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);
      };

      const calculateConsultationAmount = (selectedSchools) => {
        return selectedSchools.reduce((total, school) => {
          // const mandatoryServicesTotal = school.services
          //   .filter((service) => service.mandatory) // Only mandatory services
          //   .reduce((sum, service) => sum + Number(service.amount_en), 0); // Sum service amounts
          const amount = parseFloat(school?.consultation_fee_amount)
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);
      };
      

      useEffect(() => {
        if (selectedSchools?.length >= 0) {
          const totalAmount = calculateTotalAmount(selectedSchools).toFixed(2)
          const consultAmount = calculateConsultationAmount(selectedSchools).toFixed(2)
          setApplicationFee(parseFloat(totalAmount))
          setConsultancyFee(parseFloat(consultAmount))
          setTotalAmount(parseFloat(totalAmount) + parseFloat(consultAmount))
         
      
          // Find matching discount based on form_count
          const discountItem = discountData.filter((data) => data.discount_details.form_count <= selectedSchools?.length);
      
          if (discountItem?.length > 0) {
            const discountPercentage = discountItem[discountItem?.length - 1]?.discount_details?.discount_percentage;
            // const discountedAmount = (totalAmount - (totalAmount * discountPercentage) / 100).toFixed(2);
            const discountConsultAmount = (consultAmount - (consultAmount * discountPercentage) / 100).toFixed(2);

            console.log("Discount Applied:", discountPercentage, "%");
            console.log("Total After Discount:", discountConsultAmount);
            setDiscountAmount((consultAmount - discountConsultAmount).toFixed(2))
            setDiscountPercentage(discountPercentage)
            const final_amount = (parseFloat(totalAmount) + parseFloat(discountConsultAmount)).toFixed(2)
            setFinalAmount(parseFloat(final_amount))
            setMoreApplicationPrice(parseFloat(totalAmount) + parseFloat(discountConsultAmount)); // Store the final amount in state if needed
          } else {
            console.log("No discount applicable");
            setMoreApplicationPrice(parseFloat(totalAmount) + parseFloat(consultAmount));
            setFinalAmount(parseFloat(totalAmount) + parseFloat(consultAmount))
            setDiscountAmount(0)
            setDiscountPercentage(0)
          }
        }
      }, [selectedSchools, discountData]);


      const handleCheckboxChanged = (dat) => {
        setSelectedSchools((prevSelected) => {
          const existingItem = prevSelected.find((data) => data._id === dat._id);
      
          if (existingItem) {
            // If the item is mandatory, do not remove it
            if (existingItem.mandatory) {
              return prevSelected;
            }
            return prevSelected.filter((data) => data._id !== dat._id);
          } else {
            const parsedServices =
              Array.isArray(dat.services) && typeof dat.services[0] === "string"
                ? JSON.parse(dat.services[0])
                : dat.services;
      
            return [...prevSelected, { ...dat, services: parsedServices }];
          }
        });
      };
      

      
      
    return (
      <>
    <Stack
        sx={{
          padding: "18px 30px",
          backgroundColor: "#e1e1e1",
          mb: "30px",
          overflow : 'hidden' ,
        }}
      >
        <Box sx={{display : 'flex', alignItems : 'center', gap : 2}}>
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            
          }}
        >
           {t("All_the_Latest_News")}
        </RobotoTypo>
        <EastIcon sx={{color : '#003B5C'}}/>
        </Box>
      </Stack>
      
      {discountData?.length > 0 && (
        <Box sx={{mb : 4, position : 'relative',ml : 6, mr : 6, p:'30px',borderRadius : '20px', boxShadow : '0px 4px 36px 0px rgba(0, 0, 0, 0.15)'}}>
      <Box>
        <RobotoTypo sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "22px",
            textAlign: "left",

          }}>{t("Apply_for_more_school_to_get_Discount")}</RobotoTypo>
      </Box>
      <Box sx={{mt : 2.5, display : 'flex', flexDirection : 'column', gap : 2.9, width : '92%'}}>
        {discountData?.map((data, index) => (
          <>
          <Box key={index} sx={{display : 'flex', justifyContent : 'space-between', alignItems : 'center', ml : 1}}>
          <Box sx={{display : 'flex', gap : 1.5}}>
            <Box component='img' src={tickPng} width={25} height={23}/>
            <RobotoTypo sx={{fontWeight : 600}}>{t("Apply_for")} {data?.discount_details?.form_count} {t("university")}</RobotoTypo>
          </Box>
          <Box>
              <RobotoTypo sx={{ color: "rgba(23, 125, 132, 1)", fontWeight : 600}}>{t("get")} {data?.discount_details?.discount_percentage}% {t("off")}</RobotoTypo>
          </Box>
          </Box>
          </>
        ))}
      </Box>
      <Box sx={{position : 'absolute', top : -9, right : -9}} width={160} component='img' src={offerPng} />
      </Box>
      )}
      

      <Box sx={{paddingLeft : 6}}>
      <Typography sx={{fontSize : '1.1rem'}}>{t("select_school_types")}</Typography>
      <Box sx={{display : 'flex', gap : 2, alignItems : 'center'}}>
      <Select
            name="school_types"
            value={schoolTypes}
            multiple
            onChange={handleSelect}
            renderValue={(selected) => {
              const selectedLabels = schoolOptions
                .filter((option) => selected.includes(option.value))
                .map((option) => option.label);
              return selectedLabels.join(", ");
            }}
            sx={{ width: "300px", textTransform: "capitalize" }}
          >
            {schoolOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={schoolTypes.indexOf(option.value) > -1} />

                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
          <Badge sx={{}} badgeContent={selectedSchools?.length} color="success" invisible={selectedSchools?.length === 0}>
          <Button variant='contained' disabled={selectedSchools?.length === 0} onClick={() => handleModal()} sx={{ textTransform : 'none', height : '51px', mt : 1 }}>{t("view_selected_schools")}</Button>
          </Badge>
          </Box>

          <Box sx={{mt : 4, mr : 6}}>
          {/* Table for rows with isReadable = true */}
          {(schoolTypes.length > 0 && schools.length > 0) ? (
              <>
          {/* <Table
            style={{
              borderCollapse: "separate",
              width: "100%",
              color: "#177D84",
              borderRadius: "8px",
              marginBottom: 4,
            
            }}
          >
            <TableBody>
              {schools
                .map((row, index) => (
                  
                  <Box sx={{display : 'flex', alignItems : 'center', gap : 2, mb : 1, borderBottom : '1px solid #e5e5e5'}}>
                        <Checkbox
                          checked={selectedSchools.some((data) => data._id === row._id)}
                         disabled={row.isReadable}
                         sx={{
                           "&.Mui-checked": {
                             color: "#A4D0D3", // Add this line to set the checked color
                           },
                         }}
                        onChange={() => (handleCheckboxChange(row))}
                       />
                      <Box sx={{display : 'flex', justifyContent : 'space-between', width : '90%'}}>
                      <Box sx={{display : 'flex',alignItems : 'center', gap : 2, width : '60%'}}>
                      <LaunchIcon sx={{cursor : 'pointer'}} onClick={() => navigate(`/school/${row._id}`)} title='view school'/>
                      <Typography sx={{color : 'black', fontWeight : 500}} component="span">{row?.en?.school_name}</Typography>
                      
                      </Box>
                      <Typography sx={{width : '22%', color : 'black'}}>Application Fee - S$ {row.application_fee_amount}</Typography>
                      </Box>
                    {/* {row.services.length > 0 && typeof row.services[0] === "string" ? (
                        JSON.parse(row.services[0])?.map((data, index) => (
                          <Box sx={{display : 'flex', alignItems :'center', gap : 1}}>
                          <Checkbox 
                          
                          sx={{
                            "&.Mui-checked": { color: "#A4D0D3" },
                          }}
                          />
                          <Typography key={index}>
                            {data?.title_en} - {data?.amount_en}
                          </Typography>
                          </Box>
                        ))
                      ) : null}
                      
                    
                  </Box>
                ))}
            </TableBody>
          </Table> */}
          <Table
  style={{
   
    width: "100%",
    color: "#177D84",
   
    marginBottom: 4,
  }}
>
  {/* Table Header */}
  <TableHead>
    <TableRow>
      <TableCell></TableCell> {/* Checkbox Column */}
      <TableCell sx={{fontSize : '1.05rem', fontWeigtht : 500}}><b>{t("school_name")}</b></TableCell>
      <TableCell sx={{fontSize : '1.05rem', fontWeigtht : 500}}><b>{t("application_fee")}</b></TableCell>
      <TableCell sx={{fontSize : '1.05rem', fontWeigtht : 500}}><b>{t("consultation_fee")}</b></TableCell>
      <TableCell sx={{fontSize : '1.05rem', fontWeigtht : 500}}><b>{t("view_school")}</b></TableCell>
    </TableRow>
  </TableHead>

  {/* Table Body */}
  <TableBody>
    {schools.map((row) => (
      <TableRow
        key={row._id}
        sx={{
          borderBottom: "1px solid #e5e5e5",
        }}
      >
        {/* Checkbox Column */}
        <TableCell>
          <Checkbox
            checked={selectedSchools.some((data) => data._id === row._id)}
            disabled={row.isReadable}
            sx={{
              "&.Mui-checked": {
                color: selectedSchools.find((data) => data._id === row._id)?.mandatory ? "#A4D0D3" : "#19c2d8",
              },
            }}
            onChange={() => handleCheckboxChange(row)}
          />
        </TableCell>

        {/* School Name */}
        <TableCell sx={{ color: "black", fontWeight: 500 }}>
          {row?.[language]?.school_name}
        </TableCell>

        {/* Application Fee */}
        <TableCell sx={{ color: "black" }}>
          S$ {row?.application_fee_amount}
        </TableCell>

        <TableCell sx={{ color: "black" }}>
          S$ {row?.consultation_fee_amount}
        </TableCell>

        {/* Actions Column */}
        <TableCell>
          <LaunchIcon
            sx={{ cursor: "pointer" }}
            onClick={() => window.open(`/school/${row._id}`, "_blank")}
            title="View School"
          />
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>
          
          {/* <Box sx={{display : 'flex'}}>
            {selectedSchools.map((data) => (
              <Typography>{data?.en?.school_name}, {" "}</Typography>
            ))}
          </Box> */}
          
          </>
          ) : (
            <>
          
              <Typography>{t("Please_select_any_school_types_to_apply_more_schools_and_get_discounts")}</Typography> 
              </>
          )}

            {selectedSchools?.length > 0 && (
              <>
              <Box sx={{mr : 5, display : 'flex', justifyContent : 'flex-end', flexDirection : 'row', mt : 2}}>
            <Box>
            <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>{t("total_application_amount")}</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {applicationFee}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>{t("total_consultation_amount")}</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {consultationFee}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>{t("total_amount")}</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {totalAmount}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>{t("discount_amount")}</Typography>
              <Typography sx={{fontWeight : 400, fontSize : '1.1rem'}}>S$ {discountAmount}</Typography>
              </Box>

              <Box sx={{display : 'flex', justifyContent : 'space-between', gap : 4}}>
              <Typography sx={{fontWeight : 600, fontSize : '1.1rem'}}>{t("final_amount")}</Typography>
              <Typography sx={{fontWeight : 600, fontSize : '1.1rem'}}>S$ {finalAmount}</Typography>
              </Box>
              </Box>
          </Box>
          <Box>
          <Typography sx={{color : '#ff5722', fontSize : '14px'}}>{t("note_discount")}</Typography>
        </Box>
        </>
            )}
        </Box>


      </Box>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
        {selectedSchools?.length > 0 ? (
                <TableContainer component={Paper} sx={{ width : '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("selected")}</b></TableCell>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("school_name")}</b></TableCell>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("application_fee")}</b></TableCell>
                      <TableCell sx={{fontSize : '1.1rem'}}><b>{t("consultation_fee")}</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedSchools.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{fontSize : '1.05rem'}}>
                        <Checkbox
                          checked={selectedSchools.some((data) => data._id === data?._id)}
                         disabled={data?.isReadable}
                         sx={{
                           "&.Mui-checked": {
                             color: data?.mandatory ? "#A4D0D3" : '#19c2d8', // Add this line to set the checked color
                           },
                         }}
                        onChange={() => {handleCheckboxChanged(data)}}
                       />
                        </TableCell>
                        <TableCell sx={{fontSize : '1.05rem'}}>{data?.[language]?.school_name || '-'}</TableCell>
                        <TableCell sx={{fontSize : '1.05rem'}}>S$ {data?.application_fee_amount || '-'}</TableCell>
                        <TableCell sx={{fontSize : '1.05rem'}}>S$ {data?.consultation_fee_amount || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              ) : (
                <Box sx={{ width : '300px', height : '50px', display : 'flex', justifyContent : 'center', alignItems : 'center' }}>
                <Typography>{t("Please_select_any_school_types_to_apply_more_schools_and_get_discounts")}</Typography>
                </Box>
              )}
           
      </Dialog>
      </>
  )
}

export default ApplyMoreUniv